import {useNavigate} from "react-router-dom";
import React, {useContext, useState} from "react";
import {Box, Button, CircularProgress, Container, Stack, Typography} from "@mui/material";
import {
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  HandDetailPagePayload,
  HandSelectionStepPayload,
  StepPayload,
  STEPS,
} from "../../contexts/HandSelectionContext";
import useNotification from "../../../../utils/notificationUtil";
import {findFingertip, findHand} from "../../../../utils/awsAmplifyUtil";
import HandListItem from "../lists/HandListItem";
import {HAND_SELECTION_URLS} from "../../../../constants/constants";
import SearchCriteriaTable from "../tables/SearchCriteriaTable";
import {convertToFingertipImage} from "../../../../utils/handUtil";
import {FingertipDocument} from "../../../equipmentdb/types/pj1dbApiFingertip";

export interface HandSelectionStepProps {
  hands: HandSelectionStepPayload['handList'];
  isError: boolean;
  isLoading: boolean;
  onMoveToHandDetail: (hand: HandSelectionStepPayload['handList'][number]) => void;
  onMoveToFingertipDetail: (hand: OnMoveToFingertipDetailProps) => void;
  onSelectedHand: (hand: HandSelectionStepPayload['handList'][number]) => void;
}

type OnMoveToFingertipDetailProps = HandSelectionStepPayload['handList'][number] & {
  fingertip: FingertipDocument
}

export default function HandSelectionStep(props: HandSelectionStepProps) {
  const {state, dispatch} = useContext(DataStoreContext);
  const navigate = useNavigate();

  return (
    <Container
      sx={{px: 0, py: 2, display: "flex", flexDirection: "row", flexWrap: "wrap"}}
      maxWidth={false}
    >
      <Stack width='100%' gap={4}>
        <Box width='100%'>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h6' component='h2'>計算条件</Typography>
            <Button
              sx={{py: 0}}
              onClick={() => {
                dispatch({
                  type: CONTEXT_ACTION_TYPE.STEP,
                  payload: STEPS.SEARCH_CRITERIA_ADDITIONAL satisfies StepPayload,
                })
                navigate(HAND_SELECTION_URLS.BEGINNING_WORK.URL);
              }}
              variant='text'
              color='inherit'
            >計算条件の変更</Button>
          </Box>

          <SearchCriteriaTable
            criteria={state.searchCriteriaAdditionalStep.searchCriteriaList}
            handPowerSources={state.searchCriteriaAdditionalStep.handPowerSourceInputs.handPowerSource}
          />
        </Box>

        <Stack width='100%' gap={2}>
          <Typography variant='h6' component='h2'>計算結果一覧</Typography>

          {/* タブ切り替えで再ロードされるので、ロード中でもすでにデータがあればローディングにしない */}
          {props.isLoading && props.hands.length <= 0 && (
            <Box display='flex' mt={2}>
              <CircularProgress/>
            </Box>
          )}

          {/* タブ切り替えで再ロードされるので、ロード中でもすでにデータがあれば表示するようにしている */}
          {props.hands.length > 0 && (
            <Box display='flex' flexWrap='wrap' gap={2}>
              {state.handSelectionStep.handList.map((hand) => (
                <HandListItem
                  key={hand.hand.attr.handId}
                  info={hand}
                  onThumbnailClick={(info) => props.onMoveToHandDetail(info)}
                  onClickHandName={(info) => props.onMoveToHandDetail(info)}
                  onClickFingertipName={(info) => info.fingertip && props.onMoveToFingertipDetail(info as OnMoveToFingertipDetailProps)}
                  onSelectedHand={(info) => props.onSelectedHand(info)}
                />
              ))}
            </Box>
          )}

          {props.hands.length === 0 && !props.isLoading && !props.isError && (
            <Box width='100%' textAlign='center'>
              <Typography variant="subtitle1" sx={{fontWeight: "bold"}}
                          dangerouslySetInnerHTML={{__html: '該当データがありません。'}}/>
            </Box>
          )}
          {props.isError && (
            <Box width='100%' textAlign='center'>
              <Typography
                variant="subtitle1"
                sx={{fontWeight: "bold"}}
                dangerouslySetInnerHTML={{__html: '検索処理が失敗しました。'}}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    </Container>
  );
}

export function useHandSelectionStepHelpers() {
  const navigate = useNavigate();
  const {state, dispatch} = useContext(DataStoreContext)
  const {errorMsg} = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const forceMinList = state.searchCriteriaAdditionalStep.searchCriteriaList.map((c) => parseFloat(c.forceMin));
  const forceMin = forceMinList.length > 0 ? Math.min(...forceMinList) : 0;
  const strokeList = state.searchCriteriaAdditionalStep.searchCriteriaList.map((c) => parseFloat(c.stroke));
  const stroke = strokeList.length > 0 ? Math.max(...strokeList) : 0;

  const toHandDetailPage = (hand: HandSelectionStepPayload['handList'][number]) => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.HAND_DETAIL,
      payload: {
        ...state.handDetailPage,
        hand
      } satisfies HandDetailPagePayload
    });
    navigate(HAND_SELECTION_URLS.DETAIL.URL);
  }

  const toFingertipDetailPage = (hand: HandSelectionStepPayload['handList'][number] & {
    fingertip: FingertipDocument
  }) => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.HAND_DETAIL,
      payload: {
        ...state.handDetailPage,
        hand,
        selectedFingertipImageKey: convertToFingertipImage(hand.fingertip).key,
        isShowFingertipDetail: true,
      } satisfies HandDetailPagePayload
    });
    navigate(HAND_SELECTION_URLS.DETAIL.URL);
  }

  const selectHand = (hand: HandSelectionStepPayload['handList'][number]) => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.HAND_SELECTION_STEP,
      payload: {
        ...state.handSelectionStep,
        selectedHand: hand,
        step: STEPS.HARDWARE_SELECTION,
      } satisfies HandSelectionStepPayload
    });
  }

  const findHandProc = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const [handResult, fingertipResult] = await Promise.all([
        findHand({
          "query": {
            "handId": {"$in": state.calculatedHand?.results.map((r) => r.handID) ?? []}
          }
        }),
        findFingertip({
          "query": {
            "fingertipId": {
              "$in": state
                .calculatedHand
                ?.results
                .flatMap((r) => 'fingertipID' in r ? [r.fingertipID] : []) ?? []
            }
          }
        })
      ]);
      if (!handResult.isSuccess || !fingertipResult.isSuccess) {
        errorMsg("ハンド・爪情報の取得に失敗しました。");
        setIsLoading(false);
        setIsError(true);
        return;
      }
      const handList = handResult.data && Object.entries(handResult.data).length > 0 ? handResult.data : {};
      const fingertipList = fingertipResult.data && Object.entries(fingertipResult.data).length > 0 ? fingertipResult.data : {};
      console.log(handList, fingertipList);

      const handListData: HandSelectionStepPayload['handList'] = state.calculatedHand?.results.map((r) => {
        const hand = handList[r.handID];
        const fingertip = 'fingertipID' in r
          ? fingertipList[r.fingertipID]
          : undefined;

        return {
          result: r,
          hand,
          fingertip,
        } satisfies HandSelectionStepPayload['handList'][number];
      }) ?? [];

      dispatch({
        type: CONTEXT_ACTION_TYPE.HAND_SELECTION_STEP,
        payload: {
          ...state.handSelectionStep,
          handList: handListData,
        } satisfies HandSelectionStepPayload
      });
    } catch (e) {
      errorMsg("ハンド・爪情報の取得に失敗しました。");
      setIsError(true);
      console.log(e);
    }

    setIsLoading(false);
  }

  return {
    isLoading,
    isError,
    setIsLoading,
    toHandDetailPage,
    toFingertipDetailPage,
    selectHand,
    findHandProc,
  }
}
