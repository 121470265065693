import React, {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Container, Stack, Typography} from "@mui/material";
import HandSelectionStep, {useHandSelectionStepHelpers} from "../components/steps/HandSelectionStep";
import {CONTEXT_ACTION_TYPE, DataStoreContext, STEPS} from "../contexts/HandSelectionContext";
import HardwareSelectionStep, {useHardwareSelectionStepHelpers} from "../components/steps/HardwareSelectionStep";
import Pjdb1Button from "../../../components/inputs/Pjdb1Button";
import {HAND_SELECTION_URLS} from "../../../constants/constants";

export default function CalculatedList() {
  const {state, dispatch} = useContext(DataStoreContext);
  const navigate = useNavigate();
  const {
    isError,
    isLoading: isHandLoading,
    toHandDetailPage,
    toFingertipDetailPage,
    selectHand,
    findHandProc
  } = useHandSelectionStepHelpers();
  const {
    isLoading: isHardwareLoading,
    toPrevStep,
    findAttachmentAndArmProc,
  } = useHardwareSelectionStepHelpers()

  useEffect(() => {
    findHandProc().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.handSelectionStep.selectedHand) {
      findAttachmentAndArmProc(state.handSelectionStep.selectedHand.hand).catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.handSelectionStep.selectedHand]);

  if (state.step === STEPS.HAND_SELECTION) {
    return <HandSelectionStep
      hands={state.handSelectionStep.handList}
      isError={isError}
      isLoading={isHandLoading}
      onMoveToHandDetail={toHandDetailPage}
      onMoveToFingertipDetail={toFingertipDetailPage}
      onSelectedHand={selectHand}
    />;
  }

  if (state.step === STEPS.HARDWARE_SELECTION) {
    return <HardwareSelectionStep
      isLoading={isHardwareLoading}
      hand={state.handSelectionStep.selectedHand?.hand}
      fingertip={state.handSelectionStep.selectedHand?.fingertip}
      attachments={state.hardwareSelectionStep.attachments}
      arms={state.hardwareSelectionStep.arms}
      onPrevStep={toPrevStep}
    />;
  }

  return (
    <Container
      sx={{px: 0, py: 2, display: "flex", flexDirection: "row", flexWrap: "wrap"}}
      maxWidth={false}
    >
      <Stack gap={1}>
        <Typography variant='h6' component='h2'>問題が発生しました</Typography>
        <Pjdb1Button
          label='始めからやり直す'
          onClick={() => {
            dispatch({type: CONTEXT_ACTION_TYPE.RESET, payload: null});
            navigate(HAND_SELECTION_URLS.BEGINNING_WORK.URL);
          }}
          variant='contained'
          color='inherit'
        />
      </Stack>
    </Container>
  );
}
