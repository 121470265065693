// feature をまたいで共通利用するコンポーネントは、src/features/common に配置する

import {Card, CardContent, CardMedia, Stack, Typography} from "@mui/material";
import {WorkClassDocument} from "../../../../types/pj1dbApiWork";
import pickImageUrl from "../../../../utils/workUtil";
import {WORK_ATTRS} from "../../../../constants/work";

export interface WorkListItemProps {
  work: WorkClassDocument;
}

export default function WorkClassListItem(props: WorkListItemProps) {
  return (
    <Card sx={{p: 0, m: 0, width: 240, height: '100%'}}>
      <CardMedia
        component="img"
        height="145"
        image={pickImageUrl(props.work)}
        alt={props.work.attr[WORK_ATTRS.nameJp.key]}
      />
      <CardContent sx={{pt: 1, px: 1}}>
        <Stack gap={1}>
          <Typography variant="subtitle2" component='p' sx={{overflowWrap: 'break-word'}}>
            商品/部品名：<br/>
            {props.work.attr[WORK_ATTRS.nameJp.key]}
          </Typography>
          <Typography variant="subtitle2" component='p' sx={{color: "#888"}}>
            JANコード/部品コード：<br/>
            {props.work.attr[WORK_ATTRS.code.key]}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}
