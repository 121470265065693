import {Badge, Box} from "@mui/material";
import {Close} from "@mui/icons-material";
import {DEFAULT_IMAGE_BLACK_URL} from "../../../workdb/utils/pickImageUrl";
import {SearchCriteriaListItem} from "../../contexts/HandSelectionContext";

const IMAGE_HEIGHT = 145;

const IMAGE_WIDTH = 145;

export interface SelectedModelItemProps {
  searchCriteriaList: SearchCriteriaListItem[],
  onDelete: (index: number) => void;
}

export default function SelectedModels(props: SelectedModelItemProps) {
  return (
    <Box display='flex' gap={2} width='100%' bgcolor='white' p={4} borderRadius={2}>
      {props.searchCriteriaList.length <= 0
        ? <Box>検索条件が設定されていません。</Box>
        : props.searchCriteriaList.map((criteria, index) => (
          <Badge
            key={JSON.stringify(criteria)}
            badgeContent={
              <Close
                fontSize='medium'
                onClick={() => props.onDelete(index)}
                sx={{color: 'white', bgcolor: 'gray', borderRadius: 4}
                }/>
            }>
            <Box
              sx={{
                width: IMAGE_WIDTH,
                height: IMAGE_HEIGHT,
                border: 1,
                borderStyle: 'solid',
                borderColor: 'grey.500',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <img src={criteria.direction.thumbnail ?? DEFAULT_IMAGE_BLACK_URL} alt="" width={IMAGE_WIDTH}
                   height={IMAGE_HEIGHT}/>
            </Box>
          </Badge>
        ))}
    </Box>
  );
}
