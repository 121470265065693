/* eslint-disable react/require-default-props */
import { useEffect } from 'react';
import { Outlet, useNavigate } from "react-router-dom"
import Container from '@mui/material/Container';
import SystemFuncTab from '../components/SystemFuncTab';
import { DataStoreContextProvider } from "../contexts/SystemContext";
import { ROLE_KEYS, useUserRole } from '../../../utils/awsConfigure';

export default function EquipmentLayout() {
  const { userRole } = useUserRole();
  const navigate = useNavigate();
  useEffect(()=>{
    if(userRole===ROLE_KEYS.RETAILER){
      navigate("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <DataStoreContextProvider>
      <SystemFuncTab />
      <Container sx={{mx:0, pb:3, pt:0, width:"100%", backgroundColor: 'white', maxWidth:"unset!important"}}>
        <Container sx={{mx:0, pb:3, pt:1, width:"100%", backgroundColor: '#F6F7F8', maxWidth:"unset!important"}}>
          <Outlet />
        </Container>
      </Container>
    </DataStoreContextProvider>
  )
}
