/* eslint-disable react/require-default-props */
import {Outlet} from "react-router-dom"
import Container from '@mui/material/Container';
import HandSelectionFuncTab from '../components/HandSelectionFuncTab';
import {DataStoreContextProvider} from "../contexts/HandSelectionContext";

export default function HandSelectionLayout() {
  return (
    <DataStoreContextProvider>
      <HandSelectionFuncTab/>
      <Container sx={{mx: 0, pb: 3, pt: 0, width: "100%", backgroundColor: 'white', maxWidth: "unset!important"}}>
        <Container sx={{mx: 0, pb: 3, pt: 1, width: "100%", backgroundColor: '#F6F7F8', maxWidth: "unset!important"}}>
          <Outlet/>
        </Container>
      </Container>
    </DataStoreContextProvider>
  )
}
