/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { TextField, TextFieldProps, InputAdornment, IconButton} from "@mui/material";
import Grid from '@mui/material/Grid2';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import React,{ useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from 'react-dom/server';
import Pjdb1ToolTip from "./Pjdb1ToolTip";

interface Props {
  label: string;
  name: string;
  value: string|number|undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  inputProps?:object;
  inputLabelProps?:TextFieldProps['InputLabelProps'];
  xs?:number;
  unit?: string;
  multiline?: boolean;
  rows?: number;
  toolTip?: string|JSX.Element;
  dispDeleteButton?:boolean;
  deleted?: (name:string) => void;
  isClear?: boolean;
  customErrorCheck?:() => void;
  customErrorMsg?:string;
}

const areEqual = (prevProps:Props, nextProps:Props) => {
  // console.log(`[${prevProps.name}]`,'[Pjdb1TextField]:areEqual(prev)',prevProps);
  // console.log(`[${prevProps.name}]`,'[Pjdb1TextField]:areEqual(next)',nextProps);
  let retEqual:boolean = prevProps.value === nextProps.value;
  if (nextProps.toolTip !== undefined && renderToStaticMarkup(prevProps.toolTip) !== renderToStaticMarkup(nextProps.toolTip)) retEqual = false;
  if (nextProps.isClear !== undefined && prevProps.isClear !== nextProps.isClear) retEqual = false;

  if (nextProps.customErrorMsg !== undefined && prevProps.customErrorMsg !== nextProps.customErrorMsg) retEqual = false;
  // console.log(`[${prevProps.name}]`,'[Pjdb1TextField]:areEqual(dif) val:',retEqual);

  return retEqual;
}

/**
 * テキストボックス
 * @author TAW j.sano
 * @param props
 * @returns
 */
export default React.memo((props:Props) => {

  const validRef = useRef<HTMLInputElement>();
  const [err, setErr] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErr(!event?.target.checkValidity());
    if (props.onChange) {
      props.onChange(event);
    }
  };
  const handleFocusOut = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    setErr(!event?.target?.checkValidity());

    if (props.customErrorCheck) {
      props.customErrorCheck();
    }
  };
  const handleInvalid = () =>{
    setErr(true);
  }

  useEffect(()=>{
    if(props.customErrorMsg!==undefined){
      if(props.customErrorMsg) {
        validRef.current?.setCustomValidity(props.customErrorMsg);
        setErr(true);
      }else{
        validRef.current?.setCustomValidity("");
        setErr(false);
      }
    }
  },[props.customErrorMsg]);

  const optionalStyle = {
    '& .MuiOutlinedInput-root': {
      backgroundColor: "#ddd",
    },
  }

  const textAreaStyle = {
    '& .MuiOutlinedInput-root textarea': {
      fieldSizing:"content",
      minHeight:"min-content"
    },
  }

  // isClear = trueになったときはErr状態解除
  useEffect(()=>{
    if(props.isClear) {
      setErr(false);
    }
  },[props.isClear]);

  // console.log(`[${props.name}]`,'[Pjdb1TextField]:rendering!!',props);
  return (
    <Grid container spacing={2} alignItems="center" sx={{flexWrap:"nowrap"}}>
      <Grid
        {...(props.xs !== undefined ? {size:{xs: props.xs}} : {sx:{flexGrow:1}})}
      >
        <TextField
          name={props.name}
          label={props.label}
          value={props.value}
          inputRef={validRef}
          variant="outlined"
          size="small"
          fullWidth
          margin="dense"
          multiline={props.multiline !== undefined ? props.multiline : false}
          rows={props.rows !== undefined ? props.rows : 1}
          required={props.required !== undefined ? props.required : false}
          disabled={props.disabled !== undefined ? props.disabled : false}
          error={err}
          helperText={err && validRef.current?.validationMessage}
          onChange={handleInputChange}
          onBlur={handleFocusOut}
          onInvalid={handleInvalid}
          InputLabelProps={props.inputLabelProps}
          InputProps={
            {
              ...(
                props.unit !== undefined ?
                {endAdornment: <InputAdornment position="end">{props.unit}</InputAdornment>} :{}
              ),
              ...(
                props.inputProps !== undefined ? {inputProps:props.inputProps} :{}
              )
            }
          }
          sx={
            {
              ...(
                props.required === undefined && props.value === "" ? optionalStyle : {}
              ),
              ...(
                props.multiline !== undefined ? textAreaStyle : {}
              ),
            }
          }
        />
      </Grid>
      { props.dispDeleteButton !== undefined &&
      <Grid alignSelf="start" sx={{pl:"5px !important"}}>
        <IconButton color="error" sx={{mt:1}} onClick={()=>{
          if(props.deleted) props.deleted(props.name);
        }} disabled={props.disabled !== undefined ? props.disabled : false}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Grid>
      }
      { props.toolTip !== undefined &&
      <Pjdb1ToolTip toolTip={props.toolTip} />
      }
    </Grid>
  );

},areEqual);
