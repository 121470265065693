import {useCallback, useEffect, useState} from "react";
import * as THREE from "three";
import {Stack} from "@mui/material";
import ThreeControl, {CameraType, LookAtType, MaterialType, OptionsView3D,} from "../../utils/view3dUtil";
import AxisIconBox from "./AxisIconBox";

interface Props {
  three: ThreeState;
  url: string | undefined;
  showPerspectiveControl?: boolean;
  showLookAtControl?: boolean;
  showGridSizeControl?: boolean;
  showCameraInfoView?: boolean;
  showObjectInfoView?: boolean;
  options?: OptionsView3D;
  padding?: number;
  callbacks?: Callbacks;
}

const VIEW_SIZE = 512;

export type Callbacks = {
  onControlPointMove?: (x: number, y: number, z: number) => void;
  onControlLineZMove?: (x: number, y: number) => void;
  onLoad?: (url: string, control: ThreeControl) => void;
  onObjectSelected?: (object: THREE.Object3D | undefined) => void;
};

export type ThreeState = {
  control: ThreeControl | undefined;
  setControl: React.Dispatch<React.SetStateAction<ThreeControl | undefined>>;
  loadCount: number;
  setLoadCount: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useThree = () => {
  const [control, setControl] = useState<ThreeControl | undefined>();
  const [loadCount, setLoadCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  return {
    control,
    setControl,
    loadCount,
    setLoadCount,
    loading,
    setLoading,
  };
};

export type UseThreeReturn = ReturnType<typeof useThree>;

export interface ControlState {
  materialType: MaterialType;
  perspective: CameraType;
  lookAtType: LookAtType;
  gridSize: string;
}

export const initialControlState: ControlState = {
  materialType: "original",
  perspective: 'perspective',
  lookAtType: "modelCenter",
  gridSize: '0.01',
};

export function useControlState() {
  const [controlState, setControlState] = useState<ControlState>(initialControlState);

  return {
    controlState,
    setControlState,
    clearControlState: () => {
      setControlState(initialControlState);
    }
  };
}

export type UseControlStateReturn = ReturnType<typeof useControlState>;

/* domが作成された後に、ThreeControlを作成し、props.three.setControl()でstateにセットします
 */

export default function View3D(props: Props) {
  console.log("View3D render");
  const [cameraPos, setCameraPos] = useState<number[]>([0, 0, 0]);
  const [cameraZoom, setCameraZoom] = useState<number | undefined>();
  const [hoverObjectList, setHoverObjectList] = useState<THREE.Object3D[]>([]);
  const [selectedObject, setSelectedObject] = useState<
    THREE.Object3D | undefined
  >(undefined);

  const onObjectHover = useCallback(
    (objectList: THREE.Object3D[]) => {
      if (objectList.length !== hoverObjectList.length) {
        console.log(
          `View3D hover object changed ${hoverObjectList.length} -> ${objectList.length}`
        );
        setHoverObjectList(objectList);
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const obj of objectList) {
          if (!hoverObjectList.includes(obj)) {
            console.log(`View3D hover object changed ${obj.name}`);
            setHoverObjectList(objectList);
            break;
          }
        }
      }
    },
    [hoverObjectList]
  );

  useEffect(() => {
    const control = new ThreeControl(VIEW_SIZE, "preview", props.options, {
      onOrbitChange: (camPos: number[], zoom: number | undefined) => {
        console.log("View3D onOrbitChange");
        setCameraPos(camPos);
        setCameraZoom(zoom);
      },
      onObjectSelected: (object: THREE.Object3D | undefined) => {
        console.log("View3D onObjectSelected");
        setSelectedObject(object);
        if (props.callbacks?.onObjectSelected)
          props.callbacks.onObjectSelected(object);
      },
      onControlPointMove: (x: number, y: number, z: number) => {
        if (props.callbacks?.onControlPointMove)
          props.callbacks.onControlPointMove(x, y, z);
      },
      onControlLineZMove: (x: number, y: number) => {
        if (props.callbacks?.onControlLineZMove)
          props.callbacks.onControlLineZMove(x, y);
      },
      onObjectHover: (objectList: THREE.Object3D[]) => {
        onObjectHover(objectList);
      },
    });
    props.three.setControl(control);
    return control.getUnmountFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 最新のhoverObjectListがonObjectHoverで使われるようにハンドラを更新します（＝クロージャーが最新の値を参照する）
    const cont = props.three.control;
    if (cont) cont.callbacks.onObjectHover = onObjectHover;
  }, [hoverObjectList, onObjectHover, props.three.control]);

  useEffect(() => {
    console.log("useEffect [props.url] called", props.three.control);
    if (props.url && props.three.control) {
      props.three.setLoading(true);
      props.three.control.unloadModels();
      console.log("ModelViewer call loadModel");
      props.three.control.loadModel(props.url, () => {
        if (props.callbacks?.onLoad && props.url && props.three.control) {
          props.callbacks.onLoad(props.url, props.three.control);
        }
        props.three.setLoading(false);
        props.three.setLoadCount(props.three.loadCount + 1);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.three.control, props.url]);

  return (
    <Stack>
      <div
        id="preview"
        style={{
          position: "relative",
          width: VIEW_SIZE,
          height: VIEW_SIZE,
          backgroundColor: "gray",
        }}
      >
        {props.three.control ? (
          <div>
            <AxisIconBox
              onAxisSelected={(viewpointType) => {
                props.three.control?.setCameraPosition(viewpointType);
              }}
            />
            <span
              style={{
                color: "white",
                position: "absolute",
                left: "5px",
                bottom: "5px",
                overflowWrap: "anywhere",
                lineHeight: 1,
              }}
            >
              {
                // eslint-disable-next-line no-nested-ternary
                !selectedObject
                  ? ""
                  : selectedObject.name
                    ? selectedObject.name
                    : "(no name)"
              }
            </span>
          </div>
        ) : undefined}
      </div>
    </Stack>
  );
}
View3D.defaultProps = {
  showPerspectiveControl: true,
  showLookAtControl: true,
  showGridSizeControl: false,
  showCameraInfoView: false,
  showObjectInfoView: false,
  options: {},
  padding: 5,
  callbacks: undefined,
};
