import React, {useCallback, useEffect} from "react";
import {Stack, Typography} from "@mui/material";
import ThreeControl from "../../../utils/view3dUtil";
import Pjdb1RadioGroup from "../../../../../components/inputs/Pjdb1RadioGroup";

export interface GridSizeFieldProps {
  control: ThreeControl | undefined;
  value: string;
  // eslint-disable-next-line react/require-default-props
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function GridSizeField(props: GridSizeFieldProps) {
  const changeGridSize = useCallback((size: number) => {
    console.log("GridSizeField changeGridSize", size);
    if (props.control) props.control.setGridUnitSize(size);
  }, [props.control]);

  useEffect(() => {
    changeGridSize(Number.parseFloat(props.value));
  }, [changeGridSize, props.value]);

  return (
    <Stack>
      <Typography>GridSize</Typography>
      <Pjdb1RadioGroup
        name="gridSize"
        radios={[
          {btnKey: '0', btnLbl: 'OFF'},
          {btnKey: '0.01', btnLbl: '1cm'},
          {btnKey: '0.1', btnLbl: '10cm'},
          {btnKey: '1', btnLbl: '1m'},
        ]}
        value={props.value}
        onChange={(e) => {
          changeGridSize(Number.parseFloat(e.target.value));
          if (props.onChange) props.onChange(e);
        }}
      />
    </Stack>
  )
}
