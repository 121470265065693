import {Link} from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  Link as MuiLink,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
} from "@mui/material";
import React from "react";
import {HAND_SELECTION_URLS} from "../../../../constants/constants";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import {roundNum} from "../../../../utils/numberUtil";
import {HandListItem as HandListItemInfo} from "../../contexts/HandSelectionContext";
import {SUGGESTION_PATTERN_LABELS, SUGGESTION_PATTERNS} from "../../../../types/pj1dbApiHandling";

export interface HandListItemProps {
  info: HandListItemInfo
  onThumbnailClick: (hand: HandListItemInfo) => void;
  onSelectedHand: (hand: HandListItemInfo) => void;
  onClickHandName: (hand: HandListItemInfo) => void;
  onClickFingertipName: (fingertip: HandListItemInfo) => void;
}

export default function HandListItem(props: HandListItemProps) {
  return (
    <Card key={props.info.hand.attr.handId} sx={{width: 240, height: 'fit-content'}}>
      <Stack gap={2}>
        <Link
          to={HAND_SELECTION_URLS.DETAIL.URL}
          style={{textDecoration: 'none'}}
          onClick={() => props.onThumbnailClick(props.info)}
        >
          <CardMedia
            component="img"
            height="145"
            image={props.info.hand.url?.image[0]}
            alt={props.info.hand.attr.handInfo.name}
          />
        </Link>
        <CardContent sx={{py: 0, px: 1, m: 0}}>
          <Stack gap={3}>
            <TableContainer component={Paper} sx={{maxWidth: "100%"}}>
              <Table size="small">
                <TableHead sx={{bgcolor: "#EEE"}}>
                  <TableRow>
                    <TableCell align='center' sx={{px: 1, py: 0.5}}>指標</TableCell>
                    <TableCell align='center' sx={{px: 1, py: 0.5}}>属性値</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TRow label='評価値' value={`${roundNum(props.info.result.score ?? 0)} 点`}/>
                  <TRow label='ハンド型' value={SUGGESTION_PATTERN_LABELS[props.info.result.suggestionPattern]}/>
                  <TRow label='ハンド製品名' value={(
                    <MuiLink component='button' color='info' onClick={() => props.onThumbnailClick(props.info)}>
                      {props.info.hand.attr.handInfo.name}
                    </MuiLink>
                  )}
                        onClick={() => props.onClickHandName(props.info)}/>
                  <TRow
                    label='爪製品名'
                    value={
                      props.info.fingertip?.attr.fingertipInfo.name
                      && props.info.result.suggestionPattern !== SUGGESTION_PATTERNS.HAND_PLUS_VIRTUAL_FINGERTIP ?
                        (
                          <MuiLink component='button' color='info' onClick={() => props.onThumbnailClick(props.info)}>
                            {props.info.fingertip.attr.fingertipInfo.name}
                          </MuiLink>
                        ) : 'なし'}
                    onClick={() => props.info.fingertip && props.onClickFingertipName(props.info)}
                  />
                </TableBody>
              </Table>
            </TableContainer>
            <Pjdb1Button
              label='使用するハンドを決定' onClick={() => props.onSelectedHand(props.info)} variant='contained'
              color='info'/>
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  )
}

interface TRowProps extends TableRowProps {
  label: string,
  value: React.ReactNode,
}

function TRow({label, value, ...props}: TRowProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TableRow {...props} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
      <TableCell align='center' sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{label}</TableCell>
      <TableCell align='center' sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{value}</TableCell>
    </TableRow>
  )
}
