/* eslint-disable react/require-default-props */
/* eslint-disable no-void  */
/* eslint-disable @typescript-eslint/no-unsafe-argument  */
import { useState, useRef, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom'
import {
  Container,
  Box,
  Typography,
  InputLabel,
  Card,
  CardMedia,
  CardContent,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  FormHelperText,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { ROLE_KEYS, useUserRole } from '../../../utils/awsConfigure';
import useNotification from "../../../utils/notificationUtil";
import useConfirmDialog from '../../../components/ConfirmDialog'
import { SYSTEM_INDUSTRY, SYSTEM_APPLICATION, ARM_STRUCTURE, EQUIPMENT_URLS, WORK_URLS } from '../../../constants/constants'
import { DataStoreContext, CONTEXT_ACTION_TYPE } from "../contexts/SystemContext";

import {
  ApiSystemRegist,
  makeApiSystemRegistFromForm,
  ApiSystemUpdate,
  makeApiSystemUpdateFromForm,
  SystemDocument,
  makeFormSystemFromDocument
} from '../types/pj1dbApiSystem'
import { HandDocument } from '../../equipmentdb/types/pj1dbApiHand';
import { ArmDocument } from '../../equipmentdb/types/pj1dbApiArm';
import {WorkClassDocument} from "../../workdb/types/pj1dbApiWork";
import { FormSystemAttr, systemInitInputs, systemWorkInitInputs, systemArmInitInputs, systemHandInitInputs } from '../types/systemForm'

import {handleInputChange,handleInputChangeNameVal,handleInputChangeFile} from "../../../utils/formUtil"
import {excludeClassesWithChildren} from "../../workdb/utils/workUtil";
import pickImageUrl from "../../workdb/utils/pickImageUrl";

import Pjdb1ImputEx from '../../../components/inputs/Pjdb1ImputEx';
import Pjdb1TextField from '../../../components/inputs/Pjdb1TextField';
import Pjdb1TextFieldList, {TextFieldListDescription} from '../../../components/inputs/Pjdb1TextFieldList';
import Pjdb1CheckBox from '../../../components/inputs/Pjdb1CheckBox';
import Pjdb1SelectCheck from '../../../components/inputs/Pjdb1SelectCheck';
import Pjdb1RadioGroup from '../../../components/inputs/Pjdb1RadioGroup';
import Pjdb1FileSelect  from '../../../components/inputs/Pjdb1FileSelect';
import Pjdb1Button from '../../../components/inputs/Pjdb1Button';
import Pjdb1ToolTip from '../../../components/inputs/Pjdb1ToolTip';

import {ResponseType, fileUploade, registSystem, updateSystem, findSystem, findWork, findArm, findHand} from '../../../utils/awsAmplifyUtil'

let workListAll:WorkClassDocument[]=[];
let armListAll:ArmDocument[]=[];
let handListAll:HandDocument[]=[];
let cardSelKey=0;

export default function SystemRegiste() {
  const { userRole } = useUserRole();
  const { savedMsg, updatedMsg, infoMsg ,errorMsg } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [isClear, setIsClear] = useState(false);

  const {ConfirmDialog, openConfirmDialog} = useConfirmDialog();
  const { state, dispatch } = useContext(DataStoreContext);
  const formRef = useRef<HTMLFormElement>(null);

  const [regMode, setRegMode] = useState(true);
  const [systemInputs, setSystemInputs] = useState<FormSystemAttr>(systemInitInputs);
  const [fileErr,setFileErr] = useState({err:false,errMsg:""});

  // 商品/部品選択関連
  const [workSearchKeyword, setWorkSearchKeyword] = useState("");
  const [workCandidateList, setWorkCandidateList] = useState<WorkClassDocument[]>([]);

  // アーム選択関連
  const [armSearchKeyword, setArmSearchKeyword] = useState("");
  const [armCandidateList, setArmCandidateList] = useState<ArmDocument[]>([]);

  // ハンド選択関連
  const [handSearchKeyword, setHandSearchKeyword] = useState("");
  const [handCandidateList, setHandCandidateList] = useState<HandDocument[]>([]);

  // contextに保存
  useEffect(()=>{
    dispatch({ type: CONTEXT_ACTION_TYPE.REGIST,
      payload:{
        regMode,
        systemPanel: systemInputs
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[regMode, systemInputs]);

  // urlパラメータからモード判定
  const urlParams = useParams<{ mode: string, id: string }>();
  const usrParamMode = urlParams.mode ? String(urlParams.mode) : "";
  const usrParamId = urlParams.id ? String(urlParams.id) : "";
  useEffect(()=>{
    if(usrParamMode==="edit") setRegMode(false);
    if(usrParamMode!==""){
      if(state.registPage.systemPanel.systemId && state.registPage.systemPanel.systemId===usrParamId){
        setSystemInputs(state.registPage.systemPanel);
      }else{
        const dispInfo:SystemDocument =
          state.systemListPage.systemList.filter((item)=>item.attr.systemId === usrParamId)[0];
        if(dispInfo) {
          setSystemInputs(makeFormSystemFromDocument(dispInfo));
        }else{
          setRegMode(true);
        }
      }
    }

    // パラメータなし（ノーマル登録モード）の場合はcontextから復元
    if(usrParamMode===""){
      // console.log("SystemRegiste","restore!!");
      setRegMode(state.registPage.regMode);
      setSystemInputs({...state.registPage.systemPanel});
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // 更新後のcontextデータ反映
  const updateContextData = async () => {
    // 画像差し替え等もあるのでデータ取得
    const cond = { "query":{"systemId":systemInputs.systemId} };
    const result = await findSystem(cond);
    if(!result.isSuccess) return;
    if(result.data && Object.entries(result.data).length > 0){
      const newData:SystemDocument = Object.entries(result.data).map(([,doc])=>(doc))[0];
      dispatch({ type: CONTEXT_ACTION_TYPE.SYSTEM_DETAIL , payload: {info: newData} });
      let newList:SystemDocument[] = state.systemListPage.systemList;
      newList = newList.map(row=>row.attr.systemId===systemInputs.systemId?newData:row);
      dispatch({ type: CONTEXT_ACTION_TYPE.SYSTEM_LIST ,
        payload: {...state.systemListPage, systemList:newList} });
    }
  }

  // 論理エラー input validationではハンドリングできないもの
  const customErrorInit = {
    selectArmMax: "",
  }
  const [customError, setCustomError] = useState(customErrorInit);
  const customErrorCheck = (controlName = ""):boolean => {
    if(controlName===""){
      setCustomError(customErrorInit);
    }
    if(controlName===""||controlName==="selectArm"){
      setCustomError({...customError,...{selectArmMax:""}});
      // アーム選択は最大３つまで
      if(systemInputs.armInfos.length>3){
        setCustomError({...customError,...{selectArmMax:"選択できるアームは３つまでです。"}});
        return false;
      }
    }
    return true;
  }

  // クリア処理
  const clearProc = () => {
    setIsLoading(true);
    setRegMode(true);
    setSystemInputs({...systemInitInputs, ...{workInfos: [], armInfos: [], handInfos: []}});
    setIsClear(true); // 各入力コントロールのerrを初期化
    setFileErr({err:false,errMsg:""});
    setCustomError(customErrorInit);
    setWorkSearchKeyword("");
    setWorkCandidateList([]);
    setArmSearchKeyword("");
    setArmCandidateList([]);
    setHandSearchKeyword("");
    setHandCandidateList([]);
    infoMsg("クリアしました。");
    setIsLoading(false);
  }
  // isClearの戻し処理
  useEffect(()=>{
    if(isClear) setIsClear(false);
  },[isClear]);

  // 登録・更新処理
  const editData = async () => {
    const procName = regMode?'登録':'更新';
    try {
      let inputErr = false;
      // input項目のバリデーション
      if (!formRef.current?.reportValidity()) {
        inputErr = true;
      }
      // 論理エラーチェック
      if(!customErrorCheck()){
        inputErr = true;
      }
      if(inputErr){
        errorMsg("入力内容に誤りがあります。");
        return;
      }

      // ファイルの選択状態
      if (regMode&&!systemInputs.file){
        const msg = "ファイルを選択してください。";
        errorMsg(msg);
        setFileErr({err:true,errMsg:msg});
        return;
      }
      setFileErr({err:false,errMsg:""});

      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      // ローディングボタン
      setIsLoading(true);

      // ファイルアップロード
      let sInputFile = "";
      if (systemInputs.file){
        const resFile:ResponseType<string> = await fileUploade(systemInputs.file);
        if(!resFile.isSuccess){
          errorMsg("ファイルアップロード処理が失敗しました。");
          return;
        }
        setSystemInputs(prev => ({...prev, zipURI: String(resFile.data)}));
        sInputFile = String(resFile.data);
      }

      // DB更新
      if(regMode){
        const regData:ApiSystemRegist = makeApiSystemRegistFromForm(systemInputs, sInputFile);
        const resReg:ResponseType<string[]> = await registSystem(regData);
        if(!resReg.isSuccess){
          errorMsg(`${procName}処理が失敗しました。`);
          return;
        }
        savedMsg();
      }else{
        const regData:ApiSystemUpdate = makeApiSystemUpdateFromForm(systemInputs, sInputFile);
        const resReg:ResponseType<string[]> = await updateSystem(regData);
        if(!resReg.isSuccess){
          errorMsg("更新処理が失敗しました。");
          return;
        }
        updatedMsg();
        void updateContextData();
      }
    } catch (error) {
      console.log("registData Error : ", error);
      errorMsg(`${procName}処理が失敗しました。`);
    } finally {
      setIsLoading(false);
    }
  }

  // ワーク検索（全件）
  const getWorkImagePath = (workListData:WorkClassDocument[], classId:string):string => {
    let retImagePath = "";
    const tempRow = workListData.find(data=>data.attr['class-id']===classId);
    if(tempRow!==undefined){
      retImagePath = pickImageUrl(tempRow);
    }
    return retImagePath;
  }
  const findWorkProc = async () => {
    const params = {
      version: "work-user",
      queryAttr: {},
    };
    const result = await findWork('class', params);
    if(!result.isSuccess) return;
    workListAll =result.data ? Object.values(result.data.items) : [];
    if(workListAll.length > 0){
      workListAll = excludeClassesWithChildren(workListAll).sort((a,b) => a.attr.nameJp.localeCompare(b.attr.nameJp));
      // 選択済みリストにイメージ追加
      setSystemInputs(prev => ({...prev, ...{
        workInfos: prev.workInfos.map(row=>{
          cardSelKey +=1;
          return {
            ...row,
            image : getWorkImagePath(workListAll, row.classId),
            selKey : cardSelKey
          }
        })
      }}));
    }
  }

  // アーム検索（全件）
  const getArmImagePath = (armListData:ArmDocument[], armId:string):string => {
    let retImagePath = "";
    const tempRow = armListData.find(data=>data.attr.armId===armId);
    if(tempRow!==undefined && tempRow.url?.image[0]){
      retImagePath = tempRow.url?.image[0];
    }
    return retImagePath;
  }
  const findArmProc = async () => {
    const cond = {};
    const result = await findArm(cond);
    if(!result.isSuccess) return;
    // console.log(result);
    if(result.data && Object.entries(result.data).length > 0){
      armListAll = Object.entries(result.data).map(([,armDoc])=>(armDoc));
      armListAll = armListAll.sort((a,b) => a.attr.armInfo.name.localeCompare(b.attr.armInfo.name));
      // 選択済みリストにイメージ追加
      setSystemInputs(prev => ({...prev, ...{
        armInfos: prev.armInfos.map(row=>{
          cardSelKey +=1;
          return {
            ...row,
            image : getArmImagePath(armListAll, row.armId),
            selKey : cardSelKey
          }
        })
      }}));
    }else{
      armListAll=[];
    }
  }
  // ハンド検索（全件）
  const getHandImagePath = (handListData:HandDocument[], handId:string):string => {
    let retImagePath = "";
    const tempRow = handListData.find(data=>data.attr.handId===handId);
    if(tempRow!==undefined && tempRow.url?.image[0]){
      retImagePath = tempRow.url?.image[0];
    }
    return retImagePath;
  }
  const findHandProc = async () => {
    const cond = {};
    const result = await findHand(cond);
    if(!result.isSuccess) return;
    // console.log(result);
    if(result.data && Object.entries(result.data).length > 0){
      handListAll = Object.entries(result.data).map(([,handDoc])=>(handDoc));
      handListAll = handListAll.sort((a,b) => a.attr.handInfo.name.localeCompare(b.attr.handInfo.name));
      // 選択済みリストにイメージ追加
      setSystemInputs(prev => ({...prev, ...{
        handInfos: prev.handInfos.map(row=>{
          cardSelKey +=1;
          return {
            ...row,
            image : getHandImagePath(handListAll, row.handId),
            selKey : cardSelKey
          }
        })
      }}));
    }else{
      handListAll=[];
    }
  }

  useEffect(()=>{
    // 初回のワーク・ハンド・アーム情報検索
    void findWorkProc();
    void findHandProc();
    void findArmProc();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const SELMODE = {
    WORK: "work",
    ARM: "arm",
    HAND: "hand",
  };

  // キーワード変更
  const changeAutoComp = (mode:string, event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>|null) => {
    let sKey = "";
    if(event!==null) sKey = event.target.value;
    if(mode===SELMODE.WORK){
      if(event!==null){
        setWorkSearchKeyword(sKey);
      }else{
        sKey = workSearchKeyword;
      }
      let tempCandidateList:WorkClassDocument[] = []
      if(sKey!==""){
        tempCandidateList =
          workListAll.filter((item)=>
            item.attr.nameJp.toUpperCase().includes(String(sKey).toUpperCase()) ||
            (
              item.attr.nameEn &&
              item.attr.nameEn.toUpperCase().includes(String(sKey).toUpperCase())
            ) ||
            (
              item.attr.code &&
              item.attr.code.toUpperCase().includes(String(sKey).toUpperCase())
            )
          );
      }else{
        tempCandidateList = [];
      }
      setWorkCandidateList(tempCandidateList);

    }else if(mode===SELMODE.ARM){
      if(event!==null){
        setArmSearchKeyword(sKey);
      }else{
        sKey = armSearchKeyword;
      }
      let tempCandidateList:ArmDocument[] = []
      if(sKey!==""){
        tempCandidateList =
          armListAll.filter((item)=>
            item.attr.armInfo.name.toUpperCase().includes(String(sKey).toUpperCase()) ||
            item.attr.armInfo.manufacturer.toUpperCase().includes(String(sKey).toUpperCase()) ||
            (
              item.attr.armInfo.number &&
              item.attr.armInfo.number.toUpperCase().includes(String(sKey).toUpperCase())
            ) ||
            (
              item.attr.productInfo.keyword &&
              item.attr.productInfo.keyword.filter((kwd)=>(kwd.toUpperCase().includes(String(sKey).toUpperCase()))).length > 0
            ) ||
            (
              item.attr.productInfo.description &&
              item.attr.productInfo.description.toUpperCase().includes(String(sKey).toUpperCase())
            )
          );
      }else{
        tempCandidateList = [];
      }
      setArmCandidateList(tempCandidateList);

    }else if(mode===SELMODE.HAND){
      if(event!==null){
        setHandSearchKeyword(sKey);
      }else{
        sKey = armSearchKeyword;
      }
      let tempCandidateList:HandDocument[] = []
      if(sKey!==""){
        tempCandidateList =
          handListAll.filter((item)=>
            item.attr.handInfo.name.toUpperCase().includes(String(sKey).toUpperCase()) ||
            item.attr.handInfo.producer.toUpperCase().includes(String(sKey).toUpperCase()) ||
            (
              item.attr.handInfo.number &&
              item.attr.handInfo.number.toUpperCase().includes(String(sKey).toUpperCase())
            ) ||
            (
              item.attr.productInfo.keyword &&
              item.attr.productInfo.keyword.filter((kwd)=>(kwd.toUpperCase().includes(String(sKey).toUpperCase()))).length > 0
            ) ||
            (
              item.attr.productInfo.description &&
              item.attr.productInfo.description.toUpperCase().includes(String(sKey).toUpperCase())
            )
          );
      }else{
        tempCandidateList = [];
      }
      setHandCandidateList(tempCandidateList);
    }
  }
  // 新規作成
  const addClick = (mode:string)=>{
    if(mode===SELMODE.WORK){
      setSystemInputs((prev)=>{
        const newList = prev.workInfos;
        cardSelKey +=1;
        newList.push({...systemWorkInitInputs, selKey:cardSelKey});
        return {...prev, ...{workInfos: newList}}
      });

    }else if(mode===SELMODE.ARM){
      setSystemInputs((prev)=>{
        const newList = prev.armInfos;
        cardSelKey +=1;
        newList.push({...systemArmInitInputs, selKey:cardSelKey});
        return {...prev, ...{armInfos: newList}}
      });

    }else if(mode===SELMODE.HAND){
      setSystemInputs((prev)=>{
        const newList = prev.handInfos;
        cardSelKey +=1;
        newList.push({...systemHandInitInputs, selKey:cardSelKey});
        return {...prev, ...{handInfos: newList}}
      });
    }
  }
  // 選択スイッチ変更
  const changeSwitch = (mode:string, id:string|null, selKey:number|null, checked: boolean)=>{
    console.log(mode, id, selKey, checked);
    if(mode===SELMODE.WORK){
      if(id){
        if(checked){
          // 検索結果から選択済みへ追加
          const addRow = workCandidateList.find(row=>row.attr['class-id']===id);
          if(addRow){
            setSystemInputs((prev)=>{
              const newList = prev.workInfos;
              cardSelKey +=1;
              newList.push({
                ...systemWorkInitInputs,
                classId: addRow.attr['class-id'],
                nameJp: addRow.attr.nameJp,
                number: addRow.attr.nameEn ? addRow.attr.nameEn : "",
                image: pickImageUrl(addRow),
                selKey: cardSelKey
              });
              return {...prev, ...{workInfos: newList}}
            });
          }
        }else{
          // 検索結果上で選択済みからの削除
          setSystemInputs(prev=>({
            ...prev,
            workInfos: prev.workInfos.filter(row=>row.classId!==id)
          }));
        }
      }else{
        // 選択済みからの削除
        setSystemInputs(prev=>({
          ...prev,
          workInfos: prev.workInfos.filter(row=>row.selKey!==selKey)
        }));
      }

    }else if(mode===SELMODE.ARM){
      if(id){
        if(checked){
          // 検索結果から選択済みへ追加
          const addRow = armCandidateList.find(row=>row.attr.armId===id);
          if(addRow){
            setSystemInputs((prev)=>{
              const newList = prev.armInfos;
              cardSelKey +=1;
              newList.push({
                ...systemArmInitInputs,
                armId: addRow.attr.armId,
                name: addRow.attr.armInfo.name,
                manufacturer: addRow.attr.armInfo.manufacturer,
                number: "",
                description: [""],
                structure: "",
                cobot: addRow.attr.armInfo.cobot,
                numberOfAxes: String(addRow.attr.armInfo.numberOfAxes),
                payload: String(addRow.attr.armPerformance.payload),
                maximumReach: String(addRow.attr.armPerformance.reach.maximumReach),
                image: addRow.url?.image[0],
                selKey: cardSelKey
              });
              return {...prev, ...{armInfos: newList}}
            });
          }
        }else{
          // 検索結果上で選択済みからの削除
          setSystemInputs(prev=>({
            ...prev,
            armInfos: prev.armInfos.filter(row=>row.armId!==id)
          }));
        }
      }else{
        // 選択済みからの削除
        setSystemInputs(prev=>({
          ...prev,
          armInfos: prev.armInfos.filter(row=>row.selKey!==selKey)
        }));
      }

    }else if(mode===SELMODE.HAND){
      if(id){
        if(checked){
          // 検索結果から選択済みへ追加
          const addRow = handCandidateList.find(row=>row.attr.handId===id);
          if(addRow){
            setSystemInputs((prev)=>{
              const newList = prev.handInfos;
              cardSelKey +=1;
              newList.push({
                ...systemHandInitInputs,
                handId: addRow.attr.handId,
                name: addRow.attr.handInfo.name,
                producer: addRow.attr.handInfo.producer,
                number: "",
                description: [""],
                image: addRow.url?.image[0],
                selKey: cardSelKey
              });
              return {...prev, ...{handInfos: newList}}
            });
          }
        }else{
          // 検索結果上で選択済みからの削除
          setSystemInputs(prev=>({
            ...prev,
            handInfos: prev.handInfos.filter(row=>row.handId!==id)
          }));
        }
      }else{
        // 選択済みからの削除
        setSystemInputs(prev=>({
          ...prev,
          handInfos: prev.handInfos.filter(row=>row.selKey!==selKey)
        }));
      }
    }
  }

  // カード内コントール内容変更
  const handleChangeCard = (mode:string, selKey:number|undefined, controlName:string,
      event: React.ChangeEvent<HTMLInputElement>|null, controlValues:string[] = []) => {
    if(selKey===undefined) return;
    let newValue:string|boolean|string[] = "";
    if(event){
      if (event.target instanceof HTMLInputElement) {
        newValue = event.target.type === "checkbox" ? event.target.checked : event.target.value;
      }
    }else if (controlValues.length>0){
      newValue = controlValues;
    }
    // console.log(mode, rowIdx, controlName, newValue);
    if(mode===SELMODE.WORK){
      setSystemInputs(prev=>{
        const newList = prev.workInfos;
        const rowIdx = newList.findIndex(row=>row.selKey===selKey);
        newList[rowIdx] = {...newList[rowIdx], [controlName]: newValue};
        return {...prev, workInfos: newList}
      });

    }else if(mode===SELMODE.ARM){
      setSystemInputs(prev=>{
        const newList = prev.armInfos;
        const rowIdx = newList.findIndex(row=>row.selKey===selKey);
        newList[rowIdx] = {...newList[rowIdx], [controlName]: newValue};
        return {...prev, armInfos: newList}
      });

    }else if(mode===SELMODE.HAND){
      setSystemInputs(prev=>{
        const newList = prev.handInfos;
        const rowIdx = newList.findIndex(row=>row.selKey===selKey);
        newList[rowIdx] = {...newList[rowIdx], [controlName]: newValue};
        return {...prev, handInfos: newList}
      });
    }
  }

  return (
    <>
      <Grid container spacing={0.5} alignItems="flexStart" sx={{px:2,mt:2}}>
        <Grid>
          <Typography variant="body1" color='error'>※入力すべき値は</Typography>
        </Grid>
        <Grid><HelpOutlineIcon color='secondary'/></Grid>
        <Grid>
          <Typography variant="body1" color='error'>(tips)を参照して定めること。</Typography>
        </Grid>
      </Grid>

      <Container maxWidth="lg" sx={{mt:2}}>
        <Card sx={{p:3}}>

          <Grid container spacing={3} alignItems="flex-end" sx={{mb:2}}>
            <Grid width="180px">
              <Typography variant="h6">システム事例情報</Typography>
            </Grid>
            <Grid sx={{flexGrow: 1}}>
              { !regMode &&
                <Typography variant="body2" sx={{color:"gray"}}>
                  登録日時（登録者）：
                    {`${systemInputs.registrationDate}
                    （${userRole === ROLE_KEYS.ADMIN ? String(systemInputs.registrant) : String(systemInputs.domain)}）`}
                </Typography>
              }
            </Grid>
            <Grid><Pjdb1ImputEx/></Grid>
          </Grid>

          <form ref={formRef}>

            <Grid container spacing={2} alignItems="start" sx={{flexWrap:"nowrap"}}>
              <Grid width="100px" sx={{flexShrink:0}}>
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>基本情報</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1CheckBox
                  label="公開事例"
                  name="public" value={systemInputs.public}
                  onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                  toolTip="事例を公開しても問題ない場合にチェック"
                />

                <Pjdb1TextField
                  label="事例名称"
                  name="title" value={systemInputs.title}
                  onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  required xs={6}
                  toolTip={
                    <span>
                      文字列<br/>
                      見出し。事例を端的に表した文字列。
                    </span>
                  }
                />

                <Pjdb1TextField
                  label="システム製作者(インテグレータ)"
                  name="sier" value={systemInputs.sier}
                  onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  required xs={6}
                  toolTip="文字列"
                />

                <Grid container spacing={2} alignItems="start" sx={{flexWrap:"nowrap"}}>
                  <Grid width="70px" sx={{flexShrink:0}}>
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>産業分野</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1,pt:1}}>
                    <Pjdb1SelectCheck
                      name="industry" value={systemInputs.industry}
                      onChange={(name,value)=>(handleInputChangeNameVal<FormSystemAttr>(name, value, systemInputs, setSystemInputs))}
                      isClear={isClear}
                      required
                      checks={SYSTEM_INDUSTRY.map((row)=>({chkKey:row.key,chkLbl:row.label}))}
                      toolTip="１つ以上チェック"
                      sxOpt={{py:0,pr:0.5}}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="start" sx={{flexWrap:"nowrap"}}>
                  <Grid width="70px" sx={{flexShrink:0}}>
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>用途</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1,pt:1}}>
                    <Pjdb1SelectCheck
                      name="application" value={systemInputs.application}
                      onChange={(name,value)=>(handleInputChangeNameVal<FormSystemAttr>(name, value, systemInputs, setSystemInputs))}
                      isClear={isClear}
                      required
                      checks={SYSTEM_APPLICATION.map((row)=>({chkKey:row.key,chkLbl:row.label}))}
                      toolTip="１つ以上チェック"
                      sxOpt={{py:0,pr:0.5}}
                    />
                  </Grid>
                </Grid>

                <Pjdb1TextField
                  label="概要"
                  name="overview" value={systemInputs.overview}
                  onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  required xs={8} multiline rows={3}
                  toolTip={
                    <span>
                      文字列<br />
                      システムの概要。全体像の簡潔な説明
                    </span>
                  }
                />

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start" sx={{flexWrap:"nowrap"}}>
              <Grid width="100px" sx={{flexShrink:0}}>
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>説明</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1TextField
                  label="背景・目的"
                  name="background" value={systemInputs.background}
                  onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  xs={8} multiline rows={1.5}
                  toolTip={
                    <span>
                      文字列<br />
                      自動化前の状態や自動化の目的(品質安定など)
                    </span>
                  }
                />

                <Pjdb1TextField
                  label="効果"
                  name="result" value={systemInputs.result}
                  onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  xs={8} multiline rows={1.5}
                  toolTip={
                    <span>
                      文字列<br />
                      生産量等、目的に対する効果
                    </span>
                  }
                />

                <Pjdb1TextFieldList
                  label="課題"
                  name="problem" value={systemInputs.problem}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormSystemAttr>(name, value, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  xs={8} multiline rows={1.5}
                  toolTip={
                    <span>
                      文字列<br/>
                      自動化が困難だった理由、試行錯誤した点。<br/>
                      <TextFieldListDescription />
                    </span>
                  }
                />

                <Pjdb1TextFieldList
                  label="解決策"
                  name="solution" value={systemInputs.solution}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormSystemAttr>(name, value, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  xs={8} multiline rows={1.5}
                  toolTip={
                    <span>
                      文字列<br/>
                      課題に対する解決策。<br/>
                      <TextFieldListDescription />
                    </span>
                  }
                />

                <Pjdb1TextFieldList
                  label="工夫点"
                  name="feature" value={systemInputs.feature}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormSystemAttr>(name, value, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  required xs={8} multiline rows={1.5}
                  toolTip={
                    <span>
                      文字列<br/>
                      アピールポイント、工夫点等<br/>
                      <TextFieldListDescription />
                    </span>
                  }
                />

                <Pjdb1TextField
                  label="搬入方法"
                  name="carryIn" value={systemInputs.carryIn}
                  onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  xs={8} multiline rows={1.5}
                  toolTip={
                    <span>
                      文字列<br />
                      システム(安全柵)内へのワーク搬入方法
                    </span>
                  }
                />

                <Pjdb1TextField
                  label="搬出方法"
                  name="carryOut" value={systemInputs.carryOut}
                  onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  xs={8} multiline rows={1.5}
                  toolTip={
                    <span>
                      文字列<br />
                      システム(安全柵)外へのワーク搬出方法
                    </span>
                  }
                />

                <Pjdb1TextField
                  label="判別方法"
                  name="workDetection" value={systemInputs.workDetection}
                  onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  xs={8} multiline rows={1.5}
                  toolTip={
                    <span>
                      文字列<br />
                      ワークの有無、種類、姿勢(表裏、位相)の判別方法
                    </span>
                  }
                />

                <Pjdb1TextFieldList
                  label="動作の流れ"
                  name="sequence" value={systemInputs.sequence}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormSystemAttr>(name, value, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  required xs={8} multiline rows={1.5}
                  toolTip={
                    <span>
                      文字列<br/>
                      ロボットや周辺機器の動作の流れ<br/>
                      <TextFieldListDescription />
                    </span>
                  }
                />

                <Pjdb1TextField
                  label="1サイクル時間"
                  name="cycletime" value={systemInputs.cycletime}
                  onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3} unit="s"
                  toolTip={
                    <span>
                      半角数値<br/>
                      1回の作業サイクルにかかる時間
                    </span>
                  }
                />

                <Pjdb1TextField
                  label="動作条件補足"
                  name="cycletimeRemarks" value={systemInputs.cycletimeRemarks}
                  onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                  isClear={isClear}
                  xs={8} multiline rows={1.5}
                  toolTip={
                    <span>
                      文字列<br />
                      動作条件等の補足
                    </span>
                  }
                />

                <Grid container spacing={2} alignItems="start">
                  <Grid width="120px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>設備全体の寸法</InputLabel>
                  </Grid>
                  <Grid width="170px">
                    <Pjdb1TextField
                      label="設備寸法(幅)"
                      name="layoutWidth" value={systemInputs.layoutWidth}
                      onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                      isClear={isClear}
                      required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                    />
                  </Grid>
                  <Grid width="170px">
                    <Pjdb1TextField
                      label="設備寸法(奥行)"
                      name="layoutDepth" value={systemInputs.layoutDepth}
                      onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                      isClear={isClear}
                      required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                    />
                  </Grid>
                  <Grid width="210px">
                    <Pjdb1TextField
                      label="設備寸法(高さ)"
                      name="layoutHeight" value={systemInputs.layoutHeight}
                      onChange={(e)=>(handleInputChange<FormSystemAttr>(e, systemInputs, setSystemInputs))}
                      isClear={isClear}
                      required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                      toolTip="半角数値"
                    />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start" sx={{mt:2}}>
              <Grid width="100px" sx={{flexShrink:0}}>
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>ファイル</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1FileSelect
                  label="ファイル"
                  name="file" value={systemInputs.file ? [systemInputs.file]:[]}
                  onChange={(name,value)=>(handleInputChangeFile<FormSystemAttr>(name, value, systemInputs, setSystemInputs))}
                  accept={{'application/zip': ['.zip']}} multiple={false}
                  err={fileErr.err} errMsg={fileErr.errMsg}
                  xs={8}
                  toolTip={
                    <span>
                      以下のフォルダ構成のzipファイルのみアップロード可能<br />
                      zip内フォルダ構成<br />
                      ┣&nbsp;image<br />
                      ┃┣&nbsp;main<br />
                      ┃┃┗&nbsp;メイン画像 ※必須、1枚、外観のわかる画像<br />
                      ┃┗&nbsp;sub<br />
                      ┃&nbsp;&nbsp;┗ サブ画像 任意、0枚以上、その他補足画像<br />
                      ┗&nbsp;urdf<br />
                      &nbsp;&nbsp;┗ URDFファイル 任意、1枚<br /><br />
                      ※jpg、png、svgの画像をアップロード可能
                    </span>
                  }
                />

              </Grid>
            </Grid>


            <Box sx={{mt:3.5,borderTop:"1px solid rgba(0, 0, 0, 0.23)"}}>
              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>商品/部品選択</InputLabel>
              <Grid container spacing={2} alignItems="start" sx={{px:3,py:0,mt:1,alignItems:"stretch"}}>
                <Grid size={{xs:6}} sx={{pt:2}}>
                  <Grid container spacing={2} alignItems="start" sx={{p:0,m:0}}>
                    <Grid sx={{p:"0 !important"}}>
                      <InputLabel variant='standard' sx={{pt:0,color: 'text.primary'}}>対象商品/部品検索</InputLabel>
                    </Grid>
                    <Grid sx={{pt:"0 !important"}}>
                      <Pjdb1ToolTip toolTip={
                        <span>
                          下記に製品名を入力して候補を検索<br />
                          製品カードをクリックで商品/部品詳細ページに遷移<br />
                          製品カードの選択をONにして選択済み商品/部品に移動<br />
                          もしくは、新規作成から既存DBに無い情報を登録<br />
                        </span>
                      } sx={{p:0}} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="start" sx={{p:0,m:0}}>
                    <Grid sx={{flexGrow: 1}}>
                      <TextField
                        label="商品/部品名、JAN/部品コード、メーカーなど"
                        name="workSearchKeyword" value={workSearchKeyword}
                        variant="outlined" size="small"
                        margin="dense" fullWidth
                        onChange={(e)=>(changeAutoComp(SELMODE.WORK, e))}
                      />
                    </Grid>
                    <Grid sx={{flexGrow: 0, pt:0.5}}>
                      <Button variant="contained" color="primary" size="small" sx={{mt:1}}
                        onClick={()=>{addClick(SELMODE.WORK)}}>新規作成</Button>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt:0, pr:0 }}>
                    {workCandidateList.map(row =>(
                      <Link to={`${WORK_URLS.WORK_DETAIL.URL}/${row.attr['class-id']}`} key={row.attr['class-id']} style={{ textDecoration:'none' }}>
                        <Card sx={{ display: 'flex', height:100, mt:2}} >
                          <CardMedia
                            component="img"
                            sx={{ width: 180, flexShrink:0 }}
                            image={pickImageUrl(row)}
                            alt={row.attr.nameJp}
                          />
                          <CardContent sx={{p:1}}>
                            <Typography variant="subtitle2" component='p' sx={{mt:0.5,maxHeight:"44px",overflow:"hidden"}}>
                              製品名：{row.attr.nameJp}
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  name={row.attr['class-id']}
                                  checked={systemInputs.workInfos.filter(siRow=>siRow.classId===row.attr['class-id']).length>0}
                                  onChange={(e,chk)=>{changeSwitch(SELMODE.WORK, row.attr['class-id'], null, chk)}}
                                />
                              }
                              label="選択"
                              onClick={(e)=>{ e.stopPropagation(); }}
                            />
                          </CardContent>
                        </Card>
                      </Link>
                    ))}
                  </Box>
                </Grid>
                <Grid size={{xs:6}} sx={{borderLeft:"1px solid rgba(0, 0, 0, 0.23)",pt:2,pl:2}}>
                  <Grid container spacing={2} alignItems="start" sx={{p:0,m:0}}>
                    <Grid sx={{p:"0 !important"}}>
                      <InputLabel variant='standard' sx={{pt:0,color: 'text.primary'}}>選択済み商品/部品</InputLabel>
                    </Grid>
                    <Grid sx={{pt:"0 !important"}}>
                      <Pjdb1ToolTip toolTip={
                        <span>
                          左記より検索、選択もしくは新規作成した製品情報の必要事項を入力。
                        </span>
                      } sx={{p:0}} />
                    </Grid>
                  </Grid>
                  <Box sx={{ mt:"68px", pr:0 }}>
                    {systemInputs.workInfos.map((row,index) =>(
                      <Card sx={{ mt:2 }} key={row.selKey} >
                        { row.classId &&
                          <Link to={`${WORK_URLS.WORK_DETAIL.URL}/${row.classId}`} key={row.selKey} style={{ textDecoration:'none' }}>
                            <Grid container spacing={0} alignItems="start" sx={{p:0,m:0,color:"text.primary", flexWrap:"nowrap"}}>
                              <Grid sx={{flexShrink:0}}>
                                <CardMedia
                                  component="img"
                                  sx={{ width: 180, height:100 }}
                                  image={row.image}
                                  alt={row.nameJp}
                                />
                              </Grid>
                              <Grid>
                                <CardContent sx={{p:1,pb:"8px !important"}}>
                                  <Typography variant="subtitle2" component='p' sx={{mt:0.5,maxHeight:"44px",overflow:"hidden"}}>
                                    製品名：{row.nameJp}
                                  </Typography>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name={String(row.selKey ? row.selKey : "")}
                                        checked
                                        onChange={()=>{changeSwitch(SELMODE.WORK, null, (row.selKey ? row.selKey : null), false)}}
                                      />
                                    }
                                    label="選択"
                                    onClick={(e)=>{ e.stopPropagation(); }}
                                  />
                                </CardContent>
                              </Grid>
                            </Grid>
                          </Link>
                        }
                        <CardContent sx={{p:1,pb:"8px !important"}}>
                          { !row.classId &&
                            <Grid container spacing={0} alignItems="start" sx={{p:0,m:0}}>
                              <Grid sx={{flexGrow:1}}>
                                <Pjdb1TextField
                                  label="製品名"
                                  name={`nameJp_${String(row.selKey)}`} value={row.nameJp}
                                  onChange={(e)=>(handleChangeCard(SELMODE.WORK, row.selKey, "nameJp", e))}
                                  isClear={isClear}
                                  required
                                  toolTip="文字列"
                                />
                              </Grid>
                              <Grid sx={{flexGrow:0}}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      name={String(row.selKey ? row.selKey : "")}
                                      checked
                                      onChange={()=>{changeSwitch(SELMODE.WORK, null, (row.selKey ? row.selKey : null), false)}}
                                    />
                                  }
                                  label="選択" sx={{pl:1,pt:"10px"}}
                                  onClick={(e)=>{ e.stopPropagation(); }}
                                />
                              </Grid>
                            </Grid>
                          }
                          <Pjdb1TextField
                            label="種類数"
                            name={`number_${String(row.selKey)}`} value={row.number}
                            onChange={(e)=>(handleChangeCard(SELMODE.WORK, row.selKey, "number", e))}
                            isClear={isClear}
                            required xs={4} inputProps={{pattern: "[0-9]*" }}
                            toolTip={
                              <span>
                                半角数値<br />
                                品種ごとの種類数
                              </span>
                            }
                          />
                          <Grid container spacing={1} alignItems="start">
                            <Grid width="50px">
                              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>幅</InputLabel>
                            </Grid>
                            <Grid width="150px">
                              <Pjdb1TextField
                                label="最小値"
                                name={`widthMin_${String(row.selKey)}`} value={row.widthMin}
                                onChange={(e)=>(handleChangeCard(SELMODE.WORK, row.selKey, "widthMin", e))}
                                isClear={isClear}
                                required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                              />
                            </Grid>
                            <Grid width="190px">
                              <Pjdb1TextField
                                label="最大値"
                                name={`widthMax_${String(row.selKey)}`} value={row.widthMax}
                                onChange={(e)=>(handleChangeCard(SELMODE.WORK, row.selKey, "widthMax", e))}
                                isClear={isClear}
                                required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                                toolTip="半角数値"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} alignItems="start">
                            <Grid width="50px">
                              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>奥行</InputLabel>
                            </Grid>
                            <Grid width="150px">
                              <Pjdb1TextField
                                label="最小値"
                                name={`depthMin_${String(row.selKey)}`} value={row.depthMin}
                                onChange={(e)=>(handleChangeCard(SELMODE.WORK, row.selKey, "depthMin", e))}
                                isClear={isClear}
                                required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                              />
                            </Grid>
                            <Grid width="190px">
                              <Pjdb1TextField
                                label="最大値"
                                name={`depthMax_${String(row.selKey)}`} value={row.depthMax}
                                onChange={(e)=>(handleChangeCard(SELMODE.WORK, row.selKey, "depthMax", e))}
                                isClear={isClear}
                                required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                                toolTip="半角数値"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} alignItems="start">
                            <Grid width="50px">
                              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>高さ</InputLabel>
                            </Grid>
                            <Grid width="150px">
                              <Pjdb1TextField
                                label="最小値"
                                name={`heightMin_${String(row.selKey)}`} value={row.heightMin}
                                onChange={(e)=>(handleChangeCard(SELMODE.WORK, row.selKey, "heightMin", e))}
                                isClear={isClear}
                                required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                              />
                            </Grid>
                            <Grid width="190px">
                              <Pjdb1TextField
                                label="最大値"
                                name={`heightMax_${String(row.selKey)}`} value={row.heightMax}
                                onChange={(e)=>(handleChangeCard(SELMODE.WORK, row.selKey, "heightMax", e))}
                                isClear={isClear}
                                required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                                toolTip="半角数値"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} alignItems="start">
                            <Grid width="50px">
                              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>質量</InputLabel>
                            </Grid>
                            <Grid width="150px">
                              <Pjdb1TextField
                                label="最小値"
                                name={`massMin_${String(row.selKey)}`} value={row.massMin}
                                onChange={(e)=>(handleChangeCard(SELMODE.WORK, row.selKey, "massMin", e))}
                                isClear={isClear}
                                required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="kg"
                              />
                            </Grid>
                            <Grid width="190px">
                              <Pjdb1TextField
                                label="最大値"
                                name={`massMax_${String(row.selKey)}`} value={row.massMax}
                                onChange={(e)=>(handleChangeCard(SELMODE.WORK, row.selKey, "massMax", e))}
                                isClear={isClear}
                                required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="kg"
                                toolTip="半角数値"
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{mt:3.5,borderTop:"1px solid rgba(0, 0, 0, 0.23)"}}>
              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>アーム選択</InputLabel>
              <Grid container spacing={2} alignItems="start" sx={{px:3,py:0,mt:1,alignItems:"stretch"}}>
                <Grid size={{xs:6}} sx={{pt:2}}>
                  <Grid container spacing={2} alignItems="start" sx={{p:0,m:0}}>
                    <Grid sx={{p:"0 !important"}}>
                      <InputLabel variant='standard' sx={{pt:0,color: 'text.primary'}}>対象アーム検索</InputLabel>
                    </Grid>
                    <Grid sx={{pt:"0 !important"}}>
                      <Pjdb1ToolTip toolTip={
                        <span>
                          下記に製品名を入力して候補を検索<br />
                          製品カードをクリックでアーム詳細ページに遷移<br />
                          製品カードの選択をONにして選択済みアームに移動<br />
                          もしくは、新規作成から既存DBに無い情報を登録<br />
                        </span>
                      } sx={{p:0}} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="start" sx={{p:0,m:0}}>
                    <Grid sx={{flexGrow: 1}}>
                      <TextField
                        label="製品名、製造者、型番、特徴などを入力"
                        name="armSearchKeyword" value={armSearchKeyword}
                        variant="outlined" size="small"
                        margin="dense" fullWidth
                        onChange={(e)=>(changeAutoComp(SELMODE.ARM, e))}
                      />
                    </Grid>
                    <Grid sx={{flexGrow: 0, pt:0.5}}>
                      <Button variant="contained" color="primary" size="small" sx={{mt:1}}
                        onClick={()=>{addClick(SELMODE.ARM)}}>新規作成</Button>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt:0, pr:0 }}>
                    {armCandidateList.map(row =>(
                      <Link to={`${EQUIPMENT_URLS.ARM_DETAIL.URL}/${row.attr.armId}`} key={row.attr.armId} style={{ textDecoration:'none' }}>
                        <Card sx={{ display: 'flex', height:100, mt:2}} >
                          <CardMedia
                            component="img"
                            sx={{ width: 180, flexShrink:0 }}
                            image={row.url?.image[0]}
                            alt={row.attr.armInfo.name}
                          />
                          <CardContent sx={{p:1}}>
                            <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                              製品名：{row.attr.armInfo.name}
                            </Typography>
                            <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5,color:"#888"}}>
                              製造者：{row.attr.armInfo.manufacturer}
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  name={row.attr.armId}
                                  checked={systemInputs.armInfos.filter(siRow=>siRow.armId===row.attr.armId).length>0}
                                  onChange={(e,chk)=>{changeSwitch(SELMODE.ARM, row.attr.armId, null, chk)}}
                                />
                              }
                              label="選択"
                              onClick={(e)=>{ e.stopPropagation(); }}
                            />
                          </CardContent>
                        </Card>
                      </Link>
                    ))}
                  </Box>
                </Grid>
                <Grid size={{xs:6}} sx={{borderLeft:"1px solid rgba(0, 0, 0, 0.23)",pt:2,pl:2}}>
                  <Grid container spacing={2} alignItems="start" sx={{p:0,m:0}}>
                    <Grid sx={{p:"0 !important"}}>
                      <InputLabel variant='standard' sx={{pt:0,color: 'text.primary'}}>選択済みアーム</InputLabel>
                    </Grid>
                    <Grid sx={{pt:"0 !important"}}>
                      <Pjdb1ToolTip toolTip={
                        <span>
                          左記より検索、選択もしくは新規作成した製品情報の必要事項を入力。<br />
                          ※最大３つまで
                        </span>
                      } sx={{p:0}} />
                    </Grid>
                  </Grid>
                  {customError.selectArmMax &&
                    <FormHelperText sx={{color:"error.main"}}>{customError.selectArmMax}</FormHelperText>
                  }
                  <Box sx={{ mt:"68px", pr:0 }}>
                    {systemInputs.armInfos.map((row,index) =>(
                      <Card sx={{ mt:2 }} key={row.selKey} >
                        { row.armId &&
                          <Link to={`${EQUIPMENT_URLS.ARM_DETAIL.URL}/${row.armId}`} key={row.selKey} style={{ textDecoration:'none' }}>
                            <Grid container spacing={0} alignItems="start" sx={{p:0,m:0,color:"text.primary", flexWrap:"nowrap"}}>
                              <Grid sx={{flexShrink:0}}>
                                <CardMedia
                                  component="img"
                                  sx={{ width: 180, height:100 }}
                                  image={row.image}
                                  alt={row.name}
                                />
                              </Grid>
                              <Grid>
                                <CardContent sx={{p:1,pb:"0 !important"}}>
                                  <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                                    製品名：{row.name}
                                  </Typography>
                                  <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5,color:"#888"}}>
                                    製造者：{row.manufacturer}
                                  </Typography>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name={String(row.selKey ? row.selKey : "")}
                                        checked
                                        onChange={()=>{changeSwitch(SELMODE.ARM, null, (row.selKey ? row.selKey : null), false)}}
                                      />
                                    }
                                    label="選択"
                                    onClick={(e)=>{ e.stopPropagation(); }}
                                  />
                                </CardContent>
                              </Grid>
                            </Grid>
                          </Link>
                        }
                        <CardContent sx={{p:1,pb:"8px !important"}}>
                          { !row.armId &&
                            <>
                              <Grid container spacing={0} alignItems="start" sx={{p:0,m:0}}>
                                <Grid sx={{flexGrow:1}}>
                                  <Pjdb1TextField
                                    label="製品名"
                                    name={`name_${String(row.selKey)}`} value={row.name}
                                    onChange={(e)=>(handleChangeCard(SELMODE.ARM, row.selKey, "name", e))}
                                    isClear={isClear}
                                    required
                                    toolTip="文字列"
                                  />
                                </Grid>
                                <Grid sx={{flexGrow:0}}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name={String(row.selKey ? row.selKey : "")}
                                        checked
                                        onChange={()=>{changeSwitch(SELMODE.ARM, null, (row.selKey ? row.selKey : null), false)}}
                                      />
                                    }
                                    label="選択" sx={{pl:1,pt:"10px"}}
                                    onClick={(e)=>{ e.stopPropagation(); }}
                                  />
                                </Grid>
                              </Grid>
                              <Pjdb1TextField
                                label="製造者（メーカー名）など"
                                name={`manufacturer_${String(row.selKey)}`} value={row.manufacturer}
                                onChange={(e)=>(handleChangeCard(SELMODE.ARM, row.selKey, "manufacturer", e))}
                                isClear={isClear}
                                required xs={10}
                                toolTip="文字列"
                              />
                            </>
                          }
                          <Pjdb1TextField
                            label="使用台数"
                            name={`number_${String(row.selKey)}`} value={row.number}
                            onChange={(e)=>(handleChangeCard(SELMODE.ARM, row.selKey, "number", e))}
                            isClear={isClear}
                            required xs={4} inputProps={{pattern: "[0-9]*" }}
                            toolTip={
                              <span>
                                半角数値<br />
                                機種ごとの使用台数
                              </span>
                            }
                          />
                          <Pjdb1TextFieldList
                            label="特記事項"
                            name={`description_${String(row.selKey)}`} value={row.description}
                            onChange={(name,value)=>(handleChangeCard(SELMODE.ARM, row.selKey, "description", null, value))}
                            isClear={isClear}
                            required multiline rows={1.5}
                            toolTip={
                              <span>
                                文字列<br/>
                                力制御、コンベア同期等特記すべき機能、仕様や各マニピュレータの用途、役割<br/>
                                <TextFieldListDescription />
                              </span>
                            }
                          />
                          <Grid container spacing={1} alignItems="start" sx={{flexWrap:"nowrap"}}>
                            <Grid width="90px" sx={{flexShrink:0}}>
                              <InputLabel variant='standard' sx={{mt:0.8,color: !row.armId ? 'text.primary' : 'rgba(0, 0, 0, 0.38)'}}>アーム構造</InputLabel>
                            </Grid>
                            <Grid sx={{flexGrow:1}}>
                              <Pjdb1RadioGroup
                                name={`structure_${String(row.selKey)}`} value={row.structure}
                                onChange={(e)=>(handleChangeCard(SELMODE.ARM, row.selKey, "structure", e))}
                                isClear={isClear}
                                radios={ARM_STRUCTURE.map((lblRow)=>({btnKey:lblRow.key,btnLbl:lblRow.label}))}
                                required={!row.armId}
                                disabled={!!row.armId}
                                sxOpt={{py:0,pr:0.5}}
                              />
                            </Grid>
                          </Grid>
                          <Pjdb1CheckBox
                            label="協働ロボット"
                            name={`cobot_${String(row.selKey)}`} value={row.cobot}
                            onChange={(e)=>(handleChangeCard(SELMODE.ARM, row.selKey, "cobot", e))}
                            toolTip="協働ロボットの場合にチェック"
                            disabled={!!row.armId}
                          />
                          <Grid container spacing={1} alignItems="start" sx={{flexWrap:"nowrap"}}>
                            <Grid>
                              <Pjdb1TextField
                                label="制御軸数"
                                name={`numberOfAxes_${String(row.selKey)}`} value={row.numberOfAxes}
                                onChange={(e)=>(handleChangeCard(SELMODE.ARM, row.selKey, "numberOfAxes", e))}
                                isClear={isClear}
                                required inputProps={{pattern: "[0-9]*" }}
                                toolTip="半角数値"
                                disabled={!!row.armId}
                              />
                            </Grid>
                            <Grid>
                              <Pjdb1TextField
                                label="可搬質量"
                                name={`payload_${String(row.selKey)}`} value={row.payload}
                                onChange={(e)=>(handleChangeCard(SELMODE.ARM, row.selKey, "payload", e))}
                                isClear={isClear}
                                required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="kg"
                                toolTip="半角数値"
                                disabled={!!row.armId}
                              />
                            </Grid>
                          </Grid>
                          <Pjdb1TextField
                            label="最大リーチ"
                            name={`maximumReach_${String(row.selKey)}`} value={row.maximumReach}
                            onChange={(e)=>(handleChangeCard(SELMODE.ARM, row.selKey, "maximumReach", e))}
                            isClear={isClear}
                            required xs={6} inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                            toolTip="半角数値"
                            disabled={!!row.armId}
                          />
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{mt:3.5,borderTop:"1px solid rgba(0, 0, 0, 0.23)"}}>
              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>ハンド選択</InputLabel>
              <Grid container spacing={2} alignItems="start" sx={{px:3,py:0,mt:1,alignItems:"stretch"}}>
                <Grid size={{xs:6}} sx={{pt:2}}>
                  <Grid container spacing={2} alignItems="start" sx={{p:0,m:0}}>
                    <Grid sx={{p:"0 !important"}}>
                      <InputLabel variant='standard' sx={{pt:0,color: 'text.primary'}}>対象ハンド検索</InputLabel>
                    </Grid>
                    <Grid sx={{pt:"0 !important"}}>
                      <Pjdb1ToolTip toolTip={
                        <span>
                          下記に製品名を入力して候補を検索<br />
                          製品カードをクリックでハンド詳細ページに遷移<br />
                          製品カードの選択をONにして選択済みハンドに移動<br />
                          もしくは、新規作成から既存DBに無い情報を登録<br />
                        </span>
                      } sx={{p:0}} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="start" sx={{p:0,m:0}}>
                    <Grid sx={{flexGrow: 1}}>
                      <TextField
                        label="製品名、製造者、型番、特徴などを入力"
                        name="handSearchKeyword" value={handSearchKeyword}
                        variant="outlined" size="small"
                        margin="dense" fullWidth
                        onChange={(e)=>(changeAutoComp(SELMODE.HAND, e))}
                      />
                    </Grid>
                    <Grid sx={{flexGrow: 0, pt:0.5}}>
                      <Button variant="contained" color="primary" size="small" sx={{mt:1}}
                        onClick={()=>{addClick(SELMODE.HAND)}}>新規作成</Button>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt:0, pr:0 }}>
                    {handCandidateList.map(row =>(
                      <Link to={`${EQUIPMENT_URLS.HAND_DETAIL.URL}/${row.attr.handId}`} key={row.attr.handId} style={{ textDecoration:'none' }}>
                        <Card sx={{ display: 'flex', height:100, mt:2}} >
                          <CardMedia
                            component="img"
                            sx={{ width: 180, flexShrink:0 }}
                            image={row.url?.image[0]}
                            alt={row.attr.handInfo.name}
                          />
                          <CardContent sx={{p:1}}>
                            <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                              製品名：{row.attr.handInfo.name}
                            </Typography>
                            <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5,color:"#888"}}>
                              製造者：{row.attr.handInfo.producer}
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  name={row.attr.handId}
                                  checked={systemInputs.handInfos.filter(siRow=>siRow.handId===row.attr.handId).length>0}
                                  onChange={(e,chk)=>{changeSwitch(SELMODE.HAND, row.attr.handId, null, chk)}}
                                />
                              }
                              label="選択"
                              onClick={(e)=>{ e.stopPropagation(); }}
                            />
                          </CardContent>
                        </Card>
                      </Link>
                    ))}
                  </Box>
                </Grid>
                <Grid size={{xs:6}} sx={{borderLeft:"1px solid rgba(0, 0, 0, 0.23)",pt:2,pl:2}}>
                  <Grid container spacing={2} alignItems="start" sx={{p:0,m:0}}>
                    <Grid sx={{p:"0 !important"}}>
                      <InputLabel variant='standard' sx={{pt:0,color: 'text.primary'}}>選択済みハンド</InputLabel>
                    </Grid>
                    <Grid sx={{pt:"0 !important"}}>
                      <Pjdb1ToolTip toolTip={
                        <span>
                          左記より検索、選択もしくは新規作成した製品情報の必要事項を入力。
                        </span>
                      } sx={{p:0}} />
                    </Grid>
                  </Grid>
                  <Box sx={{ mt:"68px", pr:0 }}>
                    {systemInputs.handInfos.map((row,index) =>(
                      <Card sx={{ mt:2 }} key={row.selKey} >
                        { row.handId &&
                          <Link to={`${EQUIPMENT_URLS.HAND_DETAIL.URL}/${row.handId}`} key={row.selKey} style={{ textDecoration:'none' }}>
                            <Grid container spacing={0} alignItems="start" sx={{p:0,m:0,color:"text.primary", flexWrap:"nowrap"}}>
                              <Grid sx={{flexShrink:0}}>
                                <CardMedia
                                  component="img"
                                  sx={{ width: 180, height:100 }}
                                  image={row.image}
                                  alt={row.name}
                                />
                              </Grid>
                              <Grid>
                                <CardContent sx={{p:1,pb:"0 !important"}}>
                                  <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                                    製品名：{row.name}
                                  </Typography>
                                  <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5,color:"#888"}}>
                                    製造者：{row.producer}
                                  </Typography>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name={String(row.selKey ? row.selKey : "")}
                                        checked
                                        onChange={()=>{changeSwitch(SELMODE.HAND, null, (row.selKey ? row.selKey : null), false)}}
                                      />
                                    }
                                    label="選択"
                                    onClick={(e)=>{ e.stopPropagation(); }}
                                  />
                                </CardContent>
                              </Grid>
                            </Grid>
                          </Link>
                        }
                        <CardContent sx={{p:1,pb:"8px !important"}}>
                          { !row.handId &&
                            <>
                              <Grid container spacing={0} alignItems="start" sx={{p:0,m:0}}>
                                <Grid sx={{flexGrow:1}}>
                                  <Pjdb1TextField
                                    label="製品名"
                                    name={`name_${String(row.selKey)}`} value={row.name}
                                    onChange={(e)=>(handleChangeCard(SELMODE.HAND, row.selKey, "name", e))}
                                    isClear={isClear}
                                    required
                                    toolTip="文字列"
                                  />
                                </Grid>
                                <Grid sx={{flexGrow:0}}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name={String(row.selKey ? row.selKey : "")}
                                        checked
                                        onChange={()=>{changeSwitch(SELMODE.HAND, null, (row.selKey ? row.selKey : null), false)}}
                                      />
                                    }
                                    label="選択" sx={{pl:1,pt:"10px"}}
                                    onClick={(e)=>{ e.stopPropagation(); }}
                                  />
                                </Grid>
                              </Grid>
                              <Pjdb1TextField
                                label="製造者（メーカー名）など"
                                name={`producer_${String(row.selKey)}`} value={row.producer}
                                onChange={(e)=>(handleChangeCard(SELMODE.HAND, row.selKey, "producer", e))}
                                isClear={isClear}
                                required xs={10}
                                toolTip="文字列"
                              />
                            </>
                          }
                          <Pjdb1TextField
                            label="使用台数"
                            name={`number_${String(row.selKey)}`} value={row.number}
                            onChange={(e)=>(handleChangeCard(SELMODE.HAND, row.selKey, "number", e))}
                            isClear={isClear}
                            required xs={4} inputProps={{pattern: "[0-9]*" }}
                            toolTip="半角数値"
                          />
                          <Pjdb1TextFieldList
                            label="特記事項"
                            name={`description_${String(row.selKey)}`} value={row.description}
                            onChange={(name,value)=>(handleChangeCard(SELMODE.HAND, row.selKey, "description", null, value))}
                            isClear={isClear}
                            required multiline rows={1.5}
                            toolTip={
                              <span>
                                文字列<br/>
                                用途、特記すべき仕様<br/>
                                <TextFieldListDescription />
                              </span>
                            }
                          />
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>

          </form>
        </Card>
      </Container>

      <Box component="section" sx={{ textAlign:"center" }}>
        <Pjdb1Button
          label="クリア"
          variant="contained"
          color="inherit"
          isLoading={isLoading}
          sx={{m:2}}
          onClick={clearProc}
        />
        <Pjdb1Button
          label={`事例情報を${regMode?'登録':'更新'}`}
          variant="contained"
          color="primary"
          isLoading={isLoading}
          sx={{m:2}}
          onClick={()=>{
            void editData();
          }}
        />
      </Box>

      <ConfirmDialog
        title={`${regMode?'登録':'更新'}確認`}
        message={`事例情報を${regMode?'登録':'更新'}しますか？`}
        confirmButton={`${regMode?'登録':'更新'}`}
        confirmButtonStyle="primary"
      />

    </>
  )
}