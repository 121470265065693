import { RobocipDbDocument } from "./pj1dbApi";
import { FormHandAttr, handInitInputs, AttrListRowType } from './handForm'
import { HAND_POWER_SOURCES, HAND_FINGER_TYPES, HAND_MOUNT_PATTERN } from "../../../constants/constants";
import { FingertipDocument } from "./pj1dbApiFingertip";
// --------------------------------------------------------------------------------------
/**
 * hand APIのレスポンス型の定義
 */
export interface StrokeIntegrated {
  range: number;
  openLengthOuter?: number;
  closeLengthOuter?: number;
  openLengthInner?: number;
  closeLengthInner?: number;
}
export interface StrokeDetachable {
  range: number;
  openLength: number;
  closeLength: number;
}

export interface MinMax {
  min?: number;
  max?: number;
}

export interface HandInfo {
  name: string;
  number?: string;
  producer: string;
  weight?: number;
  fingertipType: string;
  elasticFingertip: boolean;
  material?: string;
  fingertipDetachable: boolean;
  handPowerSource?: string[];
}
export interface ProductInfo {
  description?: string;
  keyword?: string[];
  source?: string[];
}
export interface HandShapeIntegrated {
  stroke: StrokeIntegrated;
}
export interface HandShapeDetachable {
  mountPattern: string;
  stroke: StrokeDetachable;
}
export interface HandPerformance {
  payload: number;
  gripPositionResolution?: number;
  gripForceInner?: MinMax;
  gripForceOuter?: MinMax;
  gripForceResolution?: number;
  gripSpeed?: MinMax;
  gripSpeedResolution?: number;
}
export interface InterFace {
  communication?: string[];
  control?: string[];
  mechanic?: string[];
  electronic?: string[];
}
export interface Extra {
  [key: string]: string | string[];
}

export interface HandAttr extends RobocipDbDocument {
  _id: string;
  handId: string;
  handInfo: HandInfo;
  productInfo: ProductInfo;
  handShape?: HandShapeIntegrated[]|HandShapeDetachable[];
  handPerformance?: HandPerformance;
  interface?: InterFace;
  extra?: Extra;
}
export interface ObjUrl {
  zip: string[];
  image: string[];
  urdf: string[];
}

export interface HandDocument {
  attr: HandAttr;
  url?: ObjUrl;
}

// HandDocumentの初期値
export const handInitDoc:HandDocument = {
  attr: {
    _id: "",
    handId: "",
    handInfo: {
      name: "",
      number: "",
      producer: "",
      weight: 0,
      fingertipType: "",
      elasticFingertip: false,
      material: "",
      fingertipDetachable: false,
      handPowerSource: [],
    },
    productInfo: {
      description: "",
      keyword: [""],
      source: [""],
    },
    handShape: [
      {
        stroke:{
          range: 0,
          openLengthOuter: 0,
          closeLengthOuter: 0,
          openLengthInner: 0,
          closeLengthInner: 0,
        }
      }
    ],
    handPerformance: {
      payload: 0,
      gripPositionResolution: 0,
      gripForceInner: {},
      gripForceOuter: {},
      gripForceResolution: 0,
      gripSpeed: {},
      gripSpeedResolution: 0,
    },
    interface: {
      communication: [],
      control: [],
      mechanic: [],
      electronic: [],
    },
    extra:{},
    registrationDate: 0,
    registrant: "",
    domain:"",
  },
  url:  {
    zip: [],
    image: [],
    urdf: [],
  },
}

// --------------------------------------------------------------------------------------
/**
 * ハンドAPI 登録用タイプ
 */
export interface ApiHandRegist {
  zipURI: string;
  handInfo: HandInfo;
  productInfo: ProductInfo;
  handShape?: HandShapeIntegrated[]|HandShapeDetachable[];
  handPerformance?: HandPerformance;
  interface?: InterFace;
  extra?: Extra;
}

export interface ApiHandUpdate {
  id:string;
  set:{
    zipURI?: string;
    handInfo?: HandInfo;
    productInfo?: ProductInfo;
    handShape?: HandShapeIntegrated[]|HandShapeDetachable[];
    handPerformance?: HandPerformance;
    interface?: InterFace;
    extra?: Extra;
  }
}

// --------------------------------------------------------------------------------------
function makeApiHandInfoFromForm(inputs:FormHandAttr):HandInfo{
  return {
      name: inputs.name,
      ...(inputs.number ? {number: inputs.number} : {}),
      producer: inputs.producer,
      ...(inputs.weight ? {weight: parseFloat(inputs.weight)} : {}),
      fingertipType: inputs.fingertipType,
      elasticFingertip: inputs.elasticFingertip,
      ...(inputs.material ? {material: inputs.material} : {}),
      fingertipDetachable: inputs.fingertipDetachable,
      ...(inputs.handPowerSource ? {handPowerSource: inputs.handPowerSource} : {}),
  }
}
function makeApiProductInfoFromForm(inputs:FormHandAttr):ProductInfo{
  return {
    ...(inputs.description ? {description: inputs.description} : {}),
    ...(inputs.keyword.filter(val => val !== '').length > 0 ? {keyword: inputs.keyword.filter(val => val !== '')} : {}),
    ...(inputs.source.filter(val => val !== '').length > 0 ? {source: inputs.source.filter(val => val !== '')} : {}),
  }
}
function makeApiHandShapeFromForm(inputs:FormHandAttr):HandShapeIntegrated[]|HandShapeDetachable[]{
  let retHs:HandShapeIntegrated[]|HandShapeDetachable[] = [];

  if(!inputs.fingertipDetachable && inputs.handShapeIntegrated.length > 0){
    const retHsIntegrated:HandShapeIntegrated[]=[];
    inputs.handShapeIntegrated.forEach(hs => {
      retHsIntegrated.push(
        {
          stroke : {
            range:parseFloat(hs.range),
            ...(hs.openLengthOuter ? {openLengthOuter: parseFloat(hs.openLengthOuter)} : {}),
            ...(hs.closeLengthOuter ? {closeLengthOuter: parseFloat(hs.closeLengthOuter)} : {}),
            ...(hs.openLengthInner ? {openLengthInner: parseFloat(hs.openLengthInner)} : {}),
            ...(hs.closeLengthInner ? {closeLengthInner: parseFloat(hs.closeLengthInner)} : {}),
          }
        }
      );
    });
    retHs = retHsIntegrated;

  }else if(inputs.fingertipDetachable && inputs.handShapeDetachable.length > 0){
    const retHsDetachable:HandShapeDetachable[]=[];
    inputs.handShapeDetachable.forEach(hs => {
      retHsDetachable.push(
        {
          mountPattern: hs.mountPattern,
          stroke:{
            range:parseFloat(hs.range),
            openLength: parseFloat(hs.openLength),
            closeLength: parseFloat(hs.closeLength),
          }
        }
      );
    });
    retHs = retHsDetachable;
  }
  return retHs;
}
function makeApiHandPerformanceFromForm(inputs:FormHandAttr):HandPerformance{
  return {
    payload: parseFloat(inputs.payload),
    ...(inputs.gripPositionResolution ? {gripPositionResolution: parseFloat(inputs.gripPositionResolution)} : {}),
    ...(inputs.gripForceInnerMin||inputs.gripForceInnerMax ?
      {
        gripForceInner:{
          ...(inputs.gripForceInnerMin ? {min: parseFloat(inputs.gripForceInnerMin)} : {}),
          ...(inputs.gripForceInnerMax ? {max: parseFloat(inputs.gripForceInnerMax)} : {}),
        }
      } : {}),
    ...(inputs.gripForceOuterMin||inputs.gripForceOuterMax ?
      {
        gripForceOuter:{
          ...(inputs.gripForceOuterMin ? {min: parseFloat(inputs.gripForceOuterMin)} : {}),
          ...(inputs.gripForceOuterMax ? {max: parseFloat(inputs.gripForceOuterMax)} : {}),
        }
      } : {}),
    ...(inputs.gripForceResolution ? {gripForceResolution: parseFloat(inputs.gripForceResolution)} : {}),
    ...(inputs.gripSpeedMin||inputs.gripSpeedMax ?
      {
        gripSpeed:{
          ...(inputs.gripSpeedMin ? {min: parseFloat(inputs.gripSpeedMin)} : {}),
          ...(inputs.gripSpeedMax ? {max: parseFloat(inputs.gripSpeedMax)} : {}),
        }
      } : {}),
    ...(inputs.gripSpeedResolution ? {gripSpeedResolution: parseFloat(inputs.gripSpeedResolution)} : {}),
  }
}
function makeApiInterFaceFromForm(inputs:FormHandAttr):InterFace{
  return {
    ...(inputs.communication.filter(val => val !== '').length > 0 ? {communication: inputs.communication.filter(val => val !== '')} : {}),
    ...(inputs.control.filter(val => val !== '').length > 0 ? {control: inputs.control.filter(val => val !== '')} : {}),
    ...(inputs.mechanic.filter(val => val !== '').length > 0 ? {mechanic: inputs.mechanic.filter(val => val !== '')} : {}),
    ...(inputs.electronic.filter(val => val !== '').length > 0 ? {electronic: inputs.electronic.filter(val => val !== '')} : {}),
  }
}
function makeApiExtraFromForm(inputs:FormHandAttr):Extra{
  let retExt = {};
  if(inputs.extra.length > 0){
    inputs.extra.forEach(ext => {
      retExt = {...retExt, ...{[ext.key] : ext.isArray ? ext.values : ext.value }};
    });
  }
  return retExt;
}

export function makeApiHandRegistFromForm(
  inputs:FormHandAttr,zipURI:string
):ApiHandRegist{
  return {
    zipURI,
    handInfo: makeApiHandInfoFromForm(inputs),
    productInfo: makeApiProductInfoFromForm(inputs),
    ...(!inputs.freeFormat ? { handShape:makeApiHandShapeFromForm(inputs) } : {}),
    ...(!inputs.freeFormat ? { handPerformance:makeApiHandPerformanceFromForm(inputs) } : {}),
    ...(!inputs.freeFormat ? { interface:makeApiInterFaceFromForm(inputs) } : {}),
    ...(inputs.freeFormat ? { extra:makeApiExtraFromForm(inputs) } : {}),
  };
}

export function makeApiHandUpdateFromForm(
  inputs:FormHandAttr,argZipURI:string
):ApiHandUpdate{
  return {
    id: inputs.handId,
    set: {
      ...(argZipURI !== "" ? {zipURI:argZipURI} : {}),
      handInfo: makeApiHandInfoFromForm(inputs),
      productInfo: makeApiProductInfoFromForm(inputs),
      ...(!inputs.freeFormat ? { handShape:makeApiHandShapeFromForm(inputs) } : {}),
      ...(!inputs.freeFormat ? { handPerformance:makeApiHandPerformanceFromForm(inputs) } : {}),
      ...(!inputs.freeFormat ? { interface:makeApiInterFaceFromForm(inputs) } : {}),
      ...(inputs.freeFormat ? { extra:makeApiExtraFromForm(inputs) } : {}),
    }
  };
}

export function makeFormHandFromDocument(
  info:HandDocument
):FormHandAttr{
  let retForm:FormHandAttr = handInitInputs;
  // 共通項目
  retForm = {...retForm, ...{
    handId: info.attr.handId,
    registrationDate:
      info.attr.registrationDate?new Date(info.attr.registrationDate).toLocaleString():"",
    registrant: info.attr.registrant,
    domain: String(info.attr.domain),
    fingertipDetachable: info.attr.handInfo.fingertipDetachable ? info.attr.handInfo.fingertipDetachable :false,
    freeFormat: info.attr.extra!==undefined && Object.keys(info.attr.extra).length > 0,
    name: info.attr.handInfo.name ? info.attr.handInfo.name : "",
    producer: info.attr.handInfo.producer ? info.attr.handInfo.producer : "",
    number: info.attr.handInfo.number ? info.attr.handInfo.number : "",
    weight: info.attr.handInfo.weight ? String(info.attr.handInfo.weight) : "",
    fingertipType: info.attr.handInfo.fingertipType,
    elasticFingertip: info.attr.handInfo.elasticFingertip,
    material: info.attr.handInfo.material ? info.attr.handInfo.material : "",
    handPowerSource: info.attr.handInfo.handPowerSource ? info.attr.handInfo.handPowerSource : [],
    keyword: info.attr.productInfo.keyword ? info.attr.productInfo.keyword : [""],
    description: info.attr.productInfo.description ? info.attr.productInfo.description : "",
    source: info.attr.productInfo.source ? info.attr.productInfo.source : [""],
  }};

  if(!retForm.freeFormat){
    // freeFormat(自由フォーマット):falseの場合

    if(info.attr.handShape && info.attr.handShape.length > 0){
      if(!retForm.fingertipDetachable){
        // fingertipDetachable(爪着脱可能):falseの場合
        retForm = {...retForm, ...{
          handShapeIntegrated: info.attr.handShape.map(hs=>(
            {
              range: String(hs.stroke.range),
              openLengthOuter: "openLengthOuter" in hs.stroke && hs.stroke.openLengthOuter ? String(hs.stroke.openLengthOuter) : "",
              closeLengthOuter: "closeLengthOuter" in hs.stroke && hs.stroke.closeLengthOuter ? String(hs.stroke.closeLengthOuter) : "",
              openLengthInner: "openLengthInner" in hs.stroke && hs.stroke.openLengthInner ? String(hs.stroke.openLengthInner) : "",
              closeLengthInner: "closeLengthInner" in hs.stroke && hs.stroke.closeLengthInner ? String(hs.stroke.closeLengthInner) : "",
            }
          ))
        }}
      }else{
        // fingertipDetachable(爪着脱可能):trueの場合
        retForm = {...retForm, ...{
          handShapeDetachable: info.attr.handShape.map(hs=>(
            {
              mountPattern: "mountPattern" in hs ? hs.mountPattern : "",
              range: String(hs.stroke.range),
              openLength: "openLength" in hs.stroke && hs.stroke.openLength ? String(hs.stroke.openLength) : "",
              closeLength: "closeLength" in hs.stroke && hs.stroke.closeLength ? String(hs.stroke.closeLength) : "",
            }
          ))
        }};
      }
    }
    if(info.attr.handPerformance){
      retForm = {...retForm, ...{
        payload: info.attr.handPerformance?.payload ? String(info.attr.handPerformance?.payload) : "",
        gripPositionResolution: info.attr.handPerformance.gripPositionResolution ? String(info.attr.handPerformance.gripPositionResolution) : "",
        gripForceInnerMin: info.attr.handPerformance.gripForceInner?.min ? String(info.attr.handPerformance.gripForceInner?.min) : "",
        gripForceInnerMax: info.attr.handPerformance.gripForceInner?.max ? String(info.attr.handPerformance.gripForceInner?.max) : "",
        gripForceOuterMin: info.attr.handPerformance.gripForceOuter?.min ? String(info.attr.handPerformance.gripForceOuter?.min) : "",
        gripForceOuterMax: info.attr.handPerformance.gripForceOuter?.max ? String(info.attr.handPerformance.gripForceOuter?.max) : "",
        gripForceResolution: info.attr.handPerformance.gripForceResolution ? String(info.attr.handPerformance.gripForceResolution) : "",
        gripSpeedMin: info.attr.handPerformance.gripSpeed?.min ? String(info.attr.handPerformance.gripSpeed?.min) : "",
        gripSpeedMax: info.attr.handPerformance.gripSpeed?.max ? String(info.attr.handPerformance.gripSpeed?.max) : "",
        gripSpeedResolution: info.attr.handPerformance.gripSpeedResolution ? String(info.attr.handPerformance.gripSpeedResolution) : "",
      }};
    }
    if(info.attr.interface){
      retForm = {...retForm, ...{
        communication: info.attr.interface.communication ? info.attr.interface.communication : [""],
        control: info.attr.interface.control ? info.attr.interface.control : [""],
        mechanic: info.attr.interface.mechanic ? info.attr.interface.mechanic : [""],
        electronic: info.attr.interface.electronic ? info.attr.interface.electronic : [""],
      }};
    }

  }else if(info.attr.extra){
    // freeFormat(自由フォーマット):trueの場合
    retForm = {...retForm, ...{
      extra:Object.entries(info.attr.extra).map(([key, value]) => (
        {
          key,
          value:  Array.isArray(value) ? "" : value,
          values: Array.isArray(value) ? value : [""],
          isArray:Array.isArray(value)
        }
      )),
    }};
  }
  return retForm;

}

// --------------------------------------------------------------------------------------
function getUrlHtml(url:string):string {
  let retHtml = "";
  retHtml = `<span><a href="${url}" style="word-break:break-all;font-size:0.75rem;" target="_blank">${url}</a></span>`;
  return retHtml;
}
export function makeHandAttrList(
  info:HandDocument,
  tipInfo?:FingertipDocument
):AttrListRowType[]{
  const attrList:AttrListRowType[] = [];

  attrList.push({key:"name", class:"基本情報", attr:"製品名", value:info.attr.handInfo.name});
  attrList.push({key:"producer", class:"", attr:"製造者", value:info.attr.handInfo.producer});
  if(info.attr.handInfo.number){
    attrList.push({key:"number", class:"", attr:"型番", value:info.attr.handInfo.number });
  }
  if(info.attr.handInfo.weight){
    if(tipInfo===undefined){
      attrList.push({key:"weight", class:"", attr:"ハンド質量",
        value: `${info.attr.handInfo.weight.toLocaleString()} kg`});
    }else{
      // 合成スペック
      const synthesisWeight = info.attr.handInfo.weight +
        (tipInfo.attr.fingertipInfo.weight?tipInfo.attr.fingertipInfo.weight:0);
      attrList.push({key:"weight", class:"", attr:"ハンド質量",
        value: `${synthesisWeight.toLocaleString()} kg`});
    }
  }
  attrList.push({key:"fingertipDetachable", class:"", attr:"爪脱着可否",
    value: info.attr.handInfo.fingertipDetachable? "脱着可能" : "脱着不可" });
  attrList.push({key:"fingertipType", class:"", attr:"爪タイプ", value:
    String(HAND_FINGER_TYPES.find((row)=>(row.key===info.attr.handInfo.fingertipType))?.label)
  });
  attrList.push({key:"elasticFingertip", class:"", attr:"柔軟爪か否か",
    value:info.attr.handInfo.elasticFingertip?"柔軟爪":"柔軟爪ではない" });
  if(info.attr.handInfo.material){
    attrList.push({key:"material", class:"", attr:"材質", value:info.attr.handInfo.material });
  }
  if(info.attr.handInfo.handPowerSource){
    attrList.push({key:"handPowerSource", class:"", attr:"動作原理", value:
      info.attr.handInfo.handPowerSource.map((hps)=>(
        HAND_POWER_SOURCES.find((row)=>(row.key===hps))?.label
      )).join("、")
    });
  }
  let divLabel = "製品情報";
  if(info.attr.productInfo.keyword){
    attrList.push({key:"keyword", class: divLabel, attr:"キーワード",
      value:info.attr.productInfo.keyword.join("、")});
    divLabel = "";
  }
  if(info.attr.productInfo.description){
    attrList.push({key:"description", class: divLabel, attr:"説明",
      value:String(info.attr.productInfo.description.replace(/\n/g,'<br>')) });
    divLabel = "";
  }
  if(info.attr.productInfo.source){
    let sourceStr = "";
    info.attr.productInfo.source.forEach((src)=>{
      if(sourceStr) sourceStr += "<br />";
      sourceStr += getUrlHtml(src);
    });
    attrList.push({key:"source", class: divLabel, attr:"その他情報", value:sourceStr});
    divLabel = "";
  }

  if(info.attr.extra===undefined || Object.keys(info.attr.extra).length === 0){
    if(!info.attr.handInfo.fingertipDetachable){
      // console.log("一体ハンド 固定フォーマット");
      if(info.attr.handShape && info.attr.handShape.length > 0){
        attrList.push({key:"range", class: "形状", attr:"ストローク",
          value:`${info.attr.handShape[0].stroke.range.toLocaleString()} mm`});
        if("openLengthOuter" in info.attr.handShape[0].stroke &&
          info.attr.handShape[0].stroke.openLengthOuter !== undefined &&
          info.attr.handShape[0].stroke.openLengthOuter !== null){
          attrList.push({key:"openLengthOuter", class:"", attr:"外径把持 爪開寸法",
            value : `${info.attr.handShape[0].stroke?.openLengthOuter.toLocaleString()} mm` });
        }
        if("closeLengthOuter" in info.attr.handShape[0].stroke &&
          info.attr.handShape[0].stroke.closeLengthOuter !== undefined &&
          info.attr.handShape[0].stroke.closeLengthOuter !== null){
          attrList.push({key:"closeLengthOuter", class:"", attr:"外径把持 爪閉寸法",
            value : `${info.attr.handShape[0].stroke?.closeLengthOuter.toLocaleString()} mm` });
        }
        if("openLengthInner" in info.attr.handShape[0].stroke &&
          info.attr.handShape[0].stroke.openLengthInner !== undefined &&
          info.attr.handShape[0].stroke.openLengthInner !== null){
          attrList.push({key:"openLengthInner", class:"", attr:"内径把持 爪開寸法",
            value : `${info.attr.handShape[0].stroke?.openLengthInner.toLocaleString()} mm` });
        }
        if("closeLengthInner" in info.attr.handShape[0].stroke &&
          info.attr.handShape[0].stroke.closeLengthInner !== undefined &&
          info.attr.handShape[0].stroke.closeLengthInner !== null){
          attrList.push({key:"closeLengthInner", class:"", attr:"内径把持 爪閉寸法",
            value : `${info.attr.handShape[0].stroke?.closeLengthInner.toLocaleString()} mm` });
        }
      }
    }else if(tipInfo===undefined){
      // console.log("分離ハンド 非合成スペック");
      if(info.attr.handShape && info.attr.handShape.length > 0){
        attrList.push({key:"range", class: "形状", attr:"ストローク",
          value:`${info.attr.handShape[0].stroke.range.toLocaleString()} mm`});

        info.attr.handShape.forEach((row,idx)=>{
          let mountPattern = "";
          if("mountPattern" in row){
            mountPattern = String(HAND_MOUNT_PATTERN.find((row2)=>(row2.key===row.mountPattern))?.label);
          }
          let lengthStr = "";
          if("openLength" in row.stroke){
            lengthStr += `可動部開寸法：${row.stroke.openLength.toLocaleString()} mm<br />`;
          }
          if("closeLength" in row.stroke){
            lengthStr += `可動部閉寸法：${row.stroke.closeLength.toLocaleString()} mm<br />`;
          }
          attrList.push({key:`handShape${String(idx)}`, class: "",
            attr:`&nbsp;&nbsp;&nbsp;&nbsp;取り付け方向&nbsp;${mountPattern}`, value:lengthStr});
        });
      }
    }else if(info.attr.handShape && info.attr.handShape.length > 0){
      // console.log("分離ハンド 合成スペック");
      attrList.push({key:"range", class: "形状", attr:"ストローク",
        value:`${info.attr.handShape[0].stroke.range.toLocaleString()} mm`});

      info.attr.handShape.forEach((row,idx)=>{
        let mountPatternKey = "";
        let mountPattern = "";
        if("mountPattern" in row){
          mountPatternKey = row.mountPattern;
          mountPattern = String(HAND_MOUNT_PATTERN.find((row2)=>(row2.key===row.mountPattern))?.label);
        }

        const openLength = "openLength" in row.stroke ? row.stroke.openLength : 0;
        const closeLength = "closeLength" in row.stroke ? row.stroke.closeLength : 0;

        // 爪のマウントパターンが一致するもののみ
        const fingertipShape = tipInfo.attr.fingertipShape.filter(fs=>(fs.mountPattern===mountPatternKey))[0];
        if(fingertipShape){
          // console.log(fingertipShape);
          let lengthStr = "";
          if(fingertipShape.thickness.inner !== undefined){
            const thicknessInner = fingertipShape.thickness.inner;
            // 外径把持 爪開寸法：ハンド属性の可動部開寸法から、2×爪属性のthickness/innerを引いた値
            let openLengthOuter = openLength - (thicknessInner * 2);
            if(openLengthOuter < 0) openLengthOuter = 0;
            // 外径把持 爪閉寸法：ハンド属性の可動部閉寸法から、2×爪属性のthickness/innerを引いた値
            let closeLengthOuter = closeLength - (thicknessInner * 2);
            if(closeLengthOuter < 0) closeLengthOuter = 0;

            lengthStr += `外径把持 爪開寸法：${openLengthOuter.toLocaleString()} mm<br />`;
            lengthStr += `外径把持 爪閉寸法：${closeLengthOuter.toLocaleString()} mm<br />`;
          }
          if(fingertipShape.thickness.outer !== undefined){
            const thicknessOuter = fingertipShape.thickness.outer;
            // 内径把持 爪開寸法：ハンド属性の可動部開寸法へ、2×爪属性のthickness/outerを足した値
            let openLengthInner = openLength + (thicknessOuter * 2);
            if(openLengthInner < 0) openLengthInner = 0;
            // 内径把持 爪開寸法：ハンド属性の可動部開寸法へ、2×爪属性のthickness/outerを足した値
            let closeLengthInner = closeLength + (thicknessOuter * 2);
            if(closeLengthInner < 0) closeLengthInner = 0;

            lengthStr += `内径把持 爪開寸法：${openLengthInner.toLocaleString()} mm<br />`;
            lengthStr += `内径把持 爪閉寸法：${closeLengthInner.toLocaleString()} mm<br />`;
          }
          if(lengthStr){
            attrList.push({key:`handShape${String(idx)}`, class: "",
              attr:`&nbsp;&nbsp;&nbsp;&nbsp;取り付け方向&nbsp;${mountPattern}`, value:lengthStr});
          }
        }
      });
    }

    if(info.attr.handPerformance){
      attrList.push({key:"payload", class:"性能", attr:"可搬質量",
        value : `${info.attr.handPerformance.payload.toLocaleString()} kg` });
      if(info.attr.handPerformance.gripPositionResolution){
        attrList.push({key:"gripPositionResolution", class:"", attr:"把持位置分解能",
          value : `${info.attr.handPerformance.gripPositionResolution.toLocaleString()} mm` });
      }
      if(info.attr.handPerformance.gripForceInner?.min){
        attrList.push({key:"gripForceInnerMin", class:"", attr:"外径把持力 最小",
          value : `${String(info.attr.handPerformance.gripForceInner.min?.toLocaleString())} N` });
      }
      if(info.attr.handPerformance.gripForceInner?.max){
        attrList.push({key:"gripForceInnerMax", class:"", attr:"外径把持力 最大",
          value : `${String(info.attr.handPerformance.gripForceInner.max?.toLocaleString())} N` });
      }
      if(info.attr.handPerformance.gripForceOuter?.min){
        attrList.push({key:"gripForceOuterMin", class:"", attr:"内径把持力 最小",
          value : `${String(info.attr.handPerformance.gripForceOuter.min.toLocaleString())} N` });
      }
      if(info.attr.handPerformance.gripForceOuter?.max){
        attrList.push({key:"gripForceOuterMax", class:"", attr:"内径把持力 最大",
          value : `${String(info.attr.handPerformance.gripForceOuter.max.toLocaleString())} N` });
      }
      if(info.attr.handPerformance.gripForceResolution){
        attrList.push({key:"gripForceResolution", class:"", attr:"把持力分解能",
          value : `${String(info.attr.handPerformance.gripForceResolution.toLocaleString())} N` });
      }
      if(info.attr.handPerformance.gripSpeed?.min){
        attrList.push({key:"gripSpeedMin", class:"", attr:"開閉速度 最小",
          value : `${String(info.attr.handPerformance.gripSpeed.min?.toLocaleString())} mm/s` });
      }
      if(info.attr.handPerformance.gripSpeed?.max){
        attrList.push({key:"gripSpeedMax", class:"", attr:"開閉速度 最大",
          value : `${String(info.attr.handPerformance.gripSpeed.max?.toLocaleString())} mm/s` });
      }
      if(info.attr.handPerformance.gripSpeedResolution){
        attrList.push({key:"gripSpeedResolution", class:"", attr:"速度分解能",
          value : `${String(info.attr.handPerformance.gripSpeedResolution.toLocaleString())}` });
      }
    }
    if(info.attr.interface){
      divLabel = "インターフェース";
      if(info.attr.interface.communication){
        attrList.push({key:"communication", class:divLabel, attr:"通信IF",
          value:info.attr.interface.communication.join("、") });
        divLabel="";
      }
      if(info.attr.interface.control){
        attrList.push({key:"control", class:divLabel, attr:"制御IF",
          value:info.attr.interface.control.join("、") });
        divLabel="";
      }
      if(info.attr.interface.mechanic){
        attrList.push({key:"mechanic", class:divLabel, attr:"機械的IF",
          value:info.attr.interface.mechanic.join("、") });
        divLabel="";
      }
      if(info.attr.interface.electronic){
        attrList.push({key:"electronic", class:divLabel, attr:"電気的IF名",
          value:info.attr.interface.electronic.join("、") });
        divLabel="";
      }
    }

  }else{
    // console.log("一体ハンド 任意フォーマット");
    divLabel = "任意属性";
    Object.entries(info.attr.extra).forEach((row,idx)=>{
      if(!Array.isArray(row[1])){
        attrList.push({key:`freeKey${idx}`, class:divLabel, attr:row[0], value:row[1]});
      }else{
        attrList.push({key:`freeKey${idx}`, class:divLabel, attr:row[0], value:row[1].join("、")});
      }
      divLabel="";
    });
  }

  /*

  if(!info.attr.handInfo.fingertipDetachable){
    attrList.push({key:"openLengthOuter", class:"", attr:"外径把持 爪開寸法",
      value : "openLengthOuter" in info.attr.handPerformance.stroke ?
        `${info.attr.handPerformance.stroke.openLengthOuter?.toLocaleString()} mm` : "" });
    attrList.push({key:"closeLengthOuter", class:"", attr:"外径把持 爪閉寸法",
      value : "closeLengthOuter" in info.attr.handPerformance.stroke ?
        `${info.attr.handPerformance.stroke.closeLengthOuter?.toLocaleString()} mm` : "" });
    attrList.push({key:"openLengthInner", class:"", attr:"内径把持 爪開寸法",
      value : "openLengthInner" in info.attr.handPerformance.stroke ?
        `${info.attr.handPerformance.stroke.openLengthInner?.toLocaleString()} mm` : "" });
    attrList.push({key:"closeLengthInner", class:"", attr:"内径把持 爪閉寸法",
      value : "closeLengthInner" in info.attr.handPerformance.stroke ?
        `${info.attr.handPerformance.stroke.closeLengthInner?.toLocaleString()} mm` : "" });
  }else if(tipInfo===undefined){
      attrList.push({key:"openLength", class:"", attr:"可動部開寸法",
        value : "openLength" in info.attr.handPerformance.stroke ?
          `${info.attr.handPerformance.stroke.openLength?.toLocaleString()} mm` : "" });
      attrList.push({key:"closeLength", class:"", attr:"可動部閉寸法",
        value : "closeLength" in info.attr.handPerformance.stroke ?
          `${info.attr.handPerformance.stroke.closeLength?.toLocaleString()} mm` : "" });
  }else{
    // 合成スペック
    const thicknessInner = tipInfo.attr.fingertipShape.thickness.inner !== undefined ? tipInfo.attr.fingertipShape.thickness.inner : 0;
    const thicknessOuter = tipInfo.attr.fingertipShape.thickness.outer !== undefined ? tipInfo.attr.fingertipShape.thickness.outer : 0;
    // ハンド属性の可動部開寸法から2×爪属性のthickness/innerを引いた値
    let synthesisOpenLengthOuter = "openLength" in info.attr.handPerformance.stroke ?
      info.attr.handPerformance.stroke.openLength : 0;
    synthesisOpenLengthOuter -= ( thicknessInner * 2);
    synthesisOpenLengthOuter = synthesisOpenLengthOuter > 0 ? synthesisOpenLengthOuter : 0;
    // ハンド属性の可動部閉寸法から2×爪属性のthickness/innerを引いた値
    let synthesisCloseLengthOuter = "closeLength" in info.attr.handPerformance.stroke ?
      info.attr.handPerformance.stroke.closeLength : 0;
    synthesisCloseLengthOuter -= ( thicknessInner * 2);
    synthesisCloseLengthOuter = synthesisCloseLengthOuter > 0 ? synthesisCloseLengthOuter : 0;
    // ハンド属性の可動部開寸法から2×爪属性のthickness/outerを足した値
    let synthesisOpenLengthInner = "openLength" in info.attr.handPerformance.stroke ?
      info.attr.handPerformance.stroke.openLength : 0;
    synthesisOpenLengthInner += ( thicknessOuter * 2);
    // ハンド属性の可動部閉寸法から2×爪属性のthickness/outerを足した値
    let synthesisCloseLengthInner = "closeLength" in info.attr.handPerformance.stroke ?
      info.attr.handPerformance.stroke.closeLength : 0;
    synthesisCloseLengthInner += ( thicknessOuter * 2);
    attrList.push({key:"openLengthOuter", class:"", attr:"外径把持 爪開寸法",
      value : `${synthesisOpenLengthOuter?.toLocaleString()} mm` });
    attrList.push({key:"closeLengthOuter", class:"", attr:"外径把持 爪閉寸法",
      value : `${synthesisCloseLengthOuter.toLocaleString()} mm` });
    attrList.push({key:"openLengthInner", class:"", attr:"内径把持 爪開寸法",
      value : `${synthesisOpenLengthInner.toLocaleString()} mm` });
    attrList.push({key:"closeLengthInner", class:"", attr:"内径把持 爪閉寸法",
      value : `${synthesisCloseLengthInner.toLocaleString()} mm` });
  }

  */
  return attrList;
}

