import {
  Box,
  Card,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useContext, useState} from "react";
import {FingertipDocument, makeFingertipAttrList} from "../../../equipmentdb/types/pj1dbApiFingertip";
import {
  HandDocument,
  handInitDoc,
  makeHandAttrList as makeBaseHandAttrList
} from "../../../equipmentdb/types/pj1dbApiHand";
import {
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  HandDetailPagePayload,
  HandListItem
} from "../../contexts/HandSelectionContext";
import {AttrListRowType} from "../../../equipmentdb/types/handForm";
import {ROLE_KEYS, useUserRole} from "../../../../utils/awsConfigure";
import Pjdb1RadioGroup from "../../../../components/inputs/Pjdb1RadioGroup";
import UrdfLoader from "../../../../components/UrdfLoader";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import {HAND_MOUNT_PATTERN} from "../../../../constants/constants";
import {convertToFingertipImage, ImageListRowType} from "../../../../utils/handUtil";

/**
 * calcHandCandidate_poll の結果で取得できるハンドと爪は一つの前提
 */
export default function HandDetail() {
  const {userRole} = useUserRole();
  const {state, dispatch} = useContext(DataStoreContext);
  const {hand, selectedFingertipImageKey} = state.handDetailPage;
  const info = hand?.hand ?? handInitDoc;
  const handAttrList = hand ? makeHandAttrList(hand) : [];
  const [dispImageType, setDispImageType] = useState<string>("image");
  const fingertipList = state.handDetailPage.hand?.fingertip
    ? [state.handDetailPage.hand.fingertip]
    : [];
  const selectedFingertipInfo: FingertipDocument | null = fingertipList.find((row) => row.attr.fingertipId === selectedFingertipImageKey) ?? null;
  const handImageList = makeHandImageList({hand: info, fingertipId: selectedFingertipInfo?.attr.fingertipId});
  const [selectedHandImageKey, setSelectedHandImageKey] = useState<string>("image0");
  const fingertipAttrList = selectedFingertipInfo ? makeFingertipAttrList(selectedFingertipInfo, true) : [];
  const fingertipImage: ImageListRowType | null = state.handDetailPage.hand?.fingertip
    ? convertToFingertipImage(state.handDetailPage.hand.fingertip)
    : null
  const imageStyle = {
    border: "1px solid rgba(0, 0, 0, 0.23)",
  }
  const selectedImageStyle = {
    border: "2px solid #48B130",
  }
  const resetSelectedHandImage = () => {
    setSelectedHandImageKey("image0");
  }
  const selectFingertip = (imageKey: string) => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.HAND_DETAIL, payload: {
        ...state.handDetailPage,
        selectedFingertipImageKey: imageKey,
      } satisfies HandDetailPagePayload
    });
  }
  const handleClickFingertip = ({e, fingertipImg}: {
    e: React.MouseEvent<HTMLAnchorElement>,
    fingertipImg: ImageListRowType
  }) => {
    e.preventDefault();
    selectFingertip(fingertipImg.key);
    resetSelectedHandImage();
  }
  const forwardFingertipDetail = () => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.HAND_DETAIL, payload: {
        ...state.handDetailPage,
        isShowFingertipDetail: true,
      } satisfies HandDetailPagePayload
    });
  }

  return (
    <Container maxWidth="lg" sx={{maxWidth: "1500px !important"}}>
      <Card sx={{p: 3, mt: 2}}>

        <Grid container spacing={3} alignItems="flex-end">
          <Grid>
            <Typography variant="h6">ハンド詳細</Typography>
          </Grid>
          <Grid sx={{flexGrow: 1}}>
            <Typography variant="body2" sx={{color: "gray"}}>
              登録日時（登録者）：
              {`${info.attr?.registrationDate ? new Date(info.attr?.registrationDate).toLocaleString() : ""}
                  （${userRole === ROLE_KEYS.ADMIN ? String(info.attr.registrant) : String(info.attr.domain)}）`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems="flex-start" sx={{mt: 1}}>
          <Grid size={{xs: 12, md: 7}} sx={{pt: "0px !important"}}>

            <Pjdb1RadioGroup
              name="dispImageType" value={dispImageType}
              onChange={(e) => (setDispImageType(e.target.value))}
              radios={[
                {btnKey: "image", btnLbl: "画像"},
                {btnKey: "model", btnLbl: "モデル"},
              ]}
              disabledRadios={info.url?.urdf && info.url?.urdf.length > 0 ? [] : ["model"]}
            />

            <Grid container spacing={2} alignItems="flex-start" sx={{mt: 0}}>
              {dispImageType === "image" &&
                  <>
                      <Grid size={{xs: 3, md: 2}}
                            sx={{
                              pt: "0px !important",
                              pr: "1px !important",
                              height: "50vw",
                              maxHeight: "70vh",
                              overflowY: "auto",
                              overflowX: "hidden"
                            }}
                            className="thin-scroll-bar">
                        {handImageList.map(row => (
                          <Box key={row.key}>
                            <a href="" onClick={(e) => {
                              e.preventDefault();
                              setSelectedHandImageKey(row.key);
                            }}>
                              <img src={row.url} className="square-image"
                                   style={row.key === selectedHandImageKey ? selectedImageStyle : imageStyle}
                                   alt={row.key}/>
                            </a>
                          </Box>
                        ))}
                      </Grid>
                      <Grid size={{xs: 9, md: 10}} sx={{pt: "0px !important"}}>
                          <img src={handImageList.find((row) => (row.key === selectedHandImageKey))?.url}
                               style={imageStyle} className="square-image" alt=""/>
                      </Grid>
                  </>
              }
              {dispImageType === "model" &&
                  <Grid sx={{p: "0px !important", border: "1px solid rgba(0, 0, 0, 0.23)"}} className="square-image">
                      <UrdfLoader urdfUrl={String(info.url?.urdf[0])}/>
                  </Grid>
              }
            </Grid>
          </Grid>
          <Grid size={{xs: 12, md: 5}} sx={{pt: "20px !important"}}>
            <Typography variant="subtitle1">ハンド属性表</Typography>
            <TableContainer component={Paper}>
              <Table sx={{maxWidth: "100%"}} size="small" aria-label="a dense table">
                <TableHead sx={{bgcolor: "#EEE"}}>
                  <TableRow>
                    <TableCell sx={{px: 1, py: 0.5}}>分類</TableCell>
                    <TableCell sx={{px: 1, py: 0.5}}>属性</TableCell>
                    <TableCell sx={{px: 1, py: 0.5}}>属性値</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {handAttrList.map((row) => (
                    <TableRow
                      key={row.key}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell width={130} sx={{px: 1, py: 0.5}}>{row.class}</TableCell>
                      <TableCell width={140} sx={{px: 1, py: 0.5}}
                                 dangerouslySetInnerHTML={{__html: String(row.attr)}}/>
                      <TableCell sx={{px: 1, py: 0.5}} dangerouslySetInnerHTML={{__html: String(row.value)}}/>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>
        </Grid>

        {info.attr.handInfo.fingertipDetachable &&
            <>
                <Grid container spacing={3} alignItems="flex-end" sx={{mt: 1}}>
                    <Grid sx={{pt: "0px !important"}}>
                        <Typography variant="h6">接続可能爪一覧</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={3} alignItems="flex-start" sx={{mt: 1}}>
                    <Grid size={{xs: 12, md: 7}} sx={{pt: "0px !important"}}>
                      {fingertipImage === null &&
                          <Typography variant="subtitle1" sx={{ml: 2, fontWeight: "bold"}}>
                              接続可能爪が登録されていません。
                          </Typography>
                      }
                      {fingertipImage && fingertipImage.type === 'maker' && (
                        <FingertipImage
                          title='メーカー標準爪'
                          image={fingertipImage}
                          selectedFingertipImageKey={selectedFingertipImageKey}
                          onClick={(e) => handleClickFingertip({e, fingertipImg: fingertipImage})}
                        />
                      )}

                      {fingertipImage && fingertipImage.type === "public" && (
                        <FingertipImage
                          title='公開カスタム爪'
                          image={fingertipImage}
                          selectedFingertipImageKey={selectedFingertipImageKey}
                          onClick={(e) => handleClickFingertip({e, fingertipImg: fingertipImage})}
                        />
                      )}

                      {fingertipImage && fingertipImage.type === "private" && (
                        <FingertipImage
                          title='非公開カスタム爪'
                          image={fingertipImage}
                          selectedFingertipImageKey={selectedFingertipImageKey}
                          onClick={(e) => handleClickFingertip({e, fingertipImg: fingertipImage})}
                        />
                      )}
                    </Grid>
                    <Grid size={{xs: 12, md: 5}} sx={{pt: "7px !important"}}>
                      {selectedFingertipImageKey &&
                          <>
                              <Typography variant="subtitle1">爪属性表</Typography>
                              <TableContainer component={Paper}>
                                  <Table sx={{maxWidth: "100%"}} size="small" aria-label="a dense table">
                                      <TableHead sx={{bgcolor: "#EEE"}}>
                                          <TableRow>
                                              <TableCell sx={{px: 1, py: 0.5}}>分類</TableCell>
                                              <TableCell sx={{px: 1, py: 0.5}}>属性</TableCell>
                                              <TableCell sx={{px: 1, py: 0.5}}>属性値</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {fingertipAttrList.map((row) => (
                                          <TableRow
                                            key={row.key}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                          >
                                            <TableCell width={130} sx={{px: 1, py: 0.5}}>{row.class}</TableCell>
                                            <TableCell width={140} sx={{px: 1, py: 0.5}}>{row.attr}</TableCell>
                                            <TableCell sx={{px: 1, py: 0.5}}
                                                       dangerouslySetInnerHTML={{__html: String(row.value)}}/>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                  </Table>
                              </TableContainer>

                              <Box sx={{mt: 2}}>
                                  <Pjdb1Button
                                      label="爪選択を解除"
                                      variant="contained"
                                      color="inherit"
                                      onClick={() => {
                                        dispatch({
                                          type: CONTEXT_ACTION_TYPE.HAND_DETAIL, payload: {
                                            ...state.handDetailPage,
                                            selectedFingertipImageKey: null,
                                          } satisfies HandDetailPagePayload
                                        });
                                        resetSelectedHandImage();
                                      }}
                                  />

                                  <Pjdb1Button
                                      label="爪詳細へ"
                                      variant="contained"
                                      color="primary"
                                      sx={{ml: 2}}
                                      onClick={forwardFingertipDetail}
                                  />
                              </Box>
                          </>
                      }
                    </Grid>
                </Grid>
            </>
        }
      </Card>
    </Container>
  )
}


interface FintertipImageProps {
  title: string;
  image: ImageListRowType;
  selectedFingertipImageKey: string | null;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

function FingertipImage(props: FintertipImageProps) {
  const imageStyle = {
    border: "1px solid rgba(0, 0, 0, 0.23)",
  }
  const selectedImageStyle = {
    border: "2px solid #48B130",
  }

  return (
    <Box sx={{ml: 2, mt: 1}}>
      <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>{props.title}</Typography>
      <Grid container spacing={2} alignItems="flex-start" sx={{mt: 0, pl: 2}}>
        <Grid key={props.image.key} size={{xs: 3}} sx={{pt: "0px !important"}}>
          <a href="" onClick={props.onClick}>
            <img src={props.image.url} className="square-image"
                 style={props.image.key === props.selectedFingertipImageKey ? selectedImageStyle : imageStyle}
                 alt={props.image.key}/>
          </a>
        </Grid>
      </Grid>
    </Box>
  )
}

function makeHandImageList(
  {hand, fingertipId}: { hand: HandDocument, fingertipId?: string }
): ImageListRowType[] {
  if (!hand.url) {
    return [];
  }
  const tmpImgList: ImageListRowType[] = [];
  let imgIdx = 0;

  // 合成画像を追加
  if (fingertipId) {
    hand.url?.image.forEach((imageUrl) => {
      const tempUrl: string[] = imageUrl.split("/");
      // console.log(tempUrl);
      if (tempUrl.length > 7) {
        if (tempUrl[6] === "image" && tempUrl[7] === fingertipId) {
          tmpImgList.push({key: `image${imgIdx}`, type: "", url: imageUrl});
          imgIdx += 1;
        }
      }
    });
  }

  // 合成画像以外を追加
  hand.url?.image.forEach((imageUrl) => {
    const tempUrl: string[] = imageUrl.split("/");
    // console.log(tempUrl);
    if (tempUrl.length > 7) {
      if (tempUrl[6] === "image" && (tempUrl[7] === "main" || tempUrl[7] === "sub")) {
        tmpImgList.push({key: `image${imgIdx}`, type: "", url: imageUrl});
        imgIdx += 1;
      }
    }
  });

  return tmpImgList;
}

function makeHandAttrList(hand: HandListItem, tipInfo?: FingertipDocument | undefined): AttrListRowType[] {
  return makeBaseHandAttrList(hand.hand, tipInfo).filter(attr => {
    if (!attr.key.includes('handShape')) {
      return true;
    }

    // 取り付け方向は対象の方向がHandに含まれている場合のみ表示
    return eqMountPattern(attr, hand);
  });
}

/**
 * attr.attr が "取り付け方向 Z" のように最後が取り付け方向のラベルであることが前提
 */
function eqMountPattern(attr: AttrListRowType, hand: HandListItem): boolean {
  const mountPattern = 'mountPattern' in hand.result ? hand.result.mountPattern : null;
  const label = HAND_MOUNT_PATTERN.find(pattern => pattern.key === mountPattern)?.label;
  if (mountPattern === null || label === undefined) {
    return false;
  }

  return attr.attr.endsWith(label);
}
