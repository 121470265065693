/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, {createContext, useReducer} from "react";
import {WorkSortOrderValue} from "../../../constants/constants";
import {WorkClassDocument, WorkInstanceDocument} from "../../../types/pj1dbApiWork";
import {CalcHandCandidatePoolResult, FindModelResponse, SuggestionPatternResult} from "../../../types/pj1dbApiHandling";
import {
  initialSearchCriteriaGroupInputs,
  SearchCriteriaInstanceGroupInputs,
} from "../components/forms/SearchCriteriaInstanceGroup";
import {HandPowerSourceFieldInputs} from "../components/forms/HandPowerSourceField";
import {HandDocument} from "../../equipmentdb/types/pj1dbApiHand";
import {FingertipDocument} from "../../equipmentdb/types/pj1dbApiFingertip";
import {ArmDocument} from "../../equipmentdb/types/pj1dbApiArm";
import {AttachmentDocument} from "../../equipmentdb/types/pj1dbApiAttachment";

export const STEPS = {
  WORK_CLASS_SELECTION: "WORK_CLASS_SELECTION",
  WORK_INSTANCE_SELECTION: "WORK_INSTANCE_SELECTION",
  SEARCH_CRITERIA_ADDITIONAL: "SEARCH_CRITERIA_ADDITIONAL",
  HAND_SELECTION: "HAND_SELECTION",
  HARDWARE_SELECTION: "HARDWARE_SELECTION",
} as const satisfies Record<string, string>;

export function pickPrevStep(step: StepType): StepType {
  return {
    [STEPS.WORK_CLASS_SELECTION]: STEPS.WORK_CLASS_SELECTION,
    [STEPS.WORK_INSTANCE_SELECTION]: STEPS.WORK_CLASS_SELECTION,
    [STEPS.SEARCH_CRITERIA_ADDITIONAL]: STEPS.WORK_INSTANCE_SELECTION,
    [STEPS.HAND_SELECTION]: STEPS.SEARCH_CRITERIA_ADDITIONAL,
    [STEPS.HARDWARE_SELECTION]: STEPS.HAND_SELECTION,
  }[step];
}

export type StepType = typeof STEPS[keyof typeof STEPS];

export type SearchCriteriaInstanceInputs = SearchCriteriaInstanceGroupInputs;

export type SearchCriteriaInstance = {
  // "開時幅"
  rangeOpen: string;
  // "閉時幅の値"
  rangeClose: string;
  // "ストローク"
  stroke: string;
  // "把持力下限"
  forceMin: string;
  // "把持力上限"
  forceMax?: string;
  // "可搬重量"
  payload: string;
}

export type HandPowerSourceInputs = HandPowerSourceFieldInputs;

export type SearchCriteriaListItem = SearchCriteriaInstance & {
  instanceId: string
  direction: FindModelResponse['gravityWithDirections'][number];
}

export interface HandListItem {
  result: SuggestionPatternResult;
  hand: HandDocument;
  fingertip?: FingertipDocument;
}

// useReducerで生成する「参照用のstate」の型
export type DataStoreType = {
  tabIndex: number;
  step: StepType;
  calculatedHand: CalcHandCandidatePoolResult | null;
  workClassSelectionStep: {
    searchCond: {
      keyword: string;
      sortOrder: WorkSortOrderValue;
      listCount: number;
    }
    workClassList: WorkClassDocument[];
    selectedWorkClass: WorkClassDocument | null;
  };
  workInstanceSelectionStep: {
    workInstanceList: WorkInstanceDocument[];
    selectedWorkInstance: WorkInstanceDocument | null;
  };
  searchCriteriaAdditionalStep: {
    workModel: FindModelResponse | null;
    selectedObjectId: string | null;
    selectedModelDirection: FindModelResponse['gravityWithDirections'][number] | null;
    searchCriteriaInstanceInputs: SearchCriteriaInstanceInputs;
    handPowerSourceInputs: HandPowerSourceInputs;
    searchCriteriaList: SearchCriteriaListItem[];
  };
  handSelectionStep: {
    handList: HandListItem[];
    selectedHand: HandListItem | null;
  };
  hardwareSelectionStep: {
    attachments: AttachmentDocument[];
    arms: ArmDocument[];
  };
  handDetailPage: {
    hand: HandListItem | null;
    selectedFingertipImageKey: string | null;
    isShowFingertipDetail: boolean;
  }
};

export const initialState: DataStoreType = {
  tabIndex: 0,
  step: STEPS.WORK_CLASS_SELECTION,
  calculatedHand: null,
  workClassSelectionStep: {
    searchCond: {
      keyword: "",
      sortOrder: "name",
      listCount: 0,
    },
    workClassList: [],
    selectedWorkClass: null,
  },
  workInstanceSelectionStep: {
    workInstanceList: [],
    selectedWorkInstance: null,
  },
  searchCriteriaAdditionalStep: {
    workModel: null,
    selectedObjectId: null,
    selectedModelDirection: null,
    searchCriteriaInstanceInputs: initialSearchCriteriaGroupInputs,
    handPowerSourceInputs: {
      handPowerSource: [],
    },
    searchCriteriaList: [],
  },
  handSelectionStep: {
    handList: [],
    selectedHand: null,
  },
  hardwareSelectionStep: {
    attachments: [],
    arms: [],
  },
  handDetailPage: {
    hand: null,
    selectedFingertipImageKey: null,
    isShowFingertipDetail: false,
  }
};

// dispatch関数の第2引数に渡す「action」の型
type ReducerActionType = {
  type: string;
  payload: any;
};

// createContext()のデフォルト値オブジェクトにasで割り当てる。
type DataStoreContextType = {
  state: DataStoreType;
  // dispatchの引数オブジェクトの型を、React.Dispatch<XXXXX> に定義する。
  dispatch: React.Dispatch<ReducerActionType>;
};

// reducer関数：更新用dispatchトリガーで、stateを更新する処理。
// 引数:   1.state 2.action(dispatch関数の引数)
// 戻り値: 更新後の新しいstate
export const CONTEXT_ACTION_TYPE = {
  TAB: "SET_TAB_INDEX",
  STEP: "SET_STEP",
  CALCULATED_HAND: "CALCULATED_HAND",
  SEARCH_WORK_CLASS: "SEARCH_WORK_CLASS",
  WORK_CLASS_SELECTION_STEP: "WORK_CLASS_SELECTION_STEP",
  WORK_INSTANCE_SELECTION_STEP: "WORK_INSTANCE_SELECTION_STEP",
  SEARCH_CRITERIA_ADDITIONAL_STEP: "SEARCH_CRITERIA_ADDITIONAL_STEP",
  HAND_SELECTION_STEP: "HAND_SELECTION_STEP",
  HARDWARE_SELECTION_STEP: "HARDWARE_SELECTION_STEP",
  HAND_DETAIL: "HAND_DETAIL_PAGE",
  RESET: "RESET",
} as const;

const reducerFunc = (state: DataStoreType, action: ReducerActionType) => {
  // action.typeの値で更新内容を切り替える。
  switch (action.type) {
    case CONTEXT_ACTION_TYPE.TAB:
      return {
        ...state,
        tabIndex: action.payload,
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.STEP:
      return {
        ...state,
        step: action.payload,
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.CALCULATED_HAND:
      return {
        ...state,
        step: action.payload.step || state.step,
        calculatedHand: action.payload.calculatedHand,
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.SEARCH_WORK_CLASS:
      return {
        ...state,
        // 検索したら、ワーククラス選択ステップに戻る。
        step: STEPS.WORK_CLASS_SELECTION,
        workClassSelectionStep: {
          ...state.workClassSelectionStep,
          searchCond: action.payload.searchCond,
          workClassList: action.payload.workClassList,
          selectedWorkClass: null,
        },
        // ハンド詳細のデータはクリアする。
        handDetailPage: initialState.handDetailPage,
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.WORK_CLASS_SELECTION_STEP:
      return {
        ...state,
        step: action.payload.step || state.step,
        workClassSelectionStep: Object.keys(action.payload as WorkClassSelectionStepPayload)
          .filter(key => key !== 'step')
          .reduce((acc, key) => {
            acc[key as keyof WorkClassSelectionStepPayload] = action.payload[key];
            return acc;
          }, {} as WorkClassSelectionStepPayload),
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.WORK_INSTANCE_SELECTION_STEP:
      return {
        ...state,
        step: action.payload.step || state.step,
        workInstanceSelectionStep: Object.keys(action.payload as WorkInstanceSelectionStepPayload)
          .filter(key => key !== 'step')
          .reduce((acc, key) => {
            acc[key as keyof WorkInstanceSelectionStepPayload] = action.payload[key];
            return acc;
          }, {} as WorkInstanceSelectionStepPayload),
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.SEARCH_CRITERIA_ADDITIONAL_STEP:
      return {
        ...state,
        step: action.payload.step || state.step,
        searchCriteriaAdditionalStep: Object.keys(action.payload as SearchCriteriaAdditionalStepPayload)
          .filter(key => key !== 'step')
          .reduce((acc, key) => {
            acc[key as keyof SearchCriteriaAdditionalStepPayload] = action.payload[key];
            return acc;
          }, {} as SearchCriteriaAdditionalStepPayload),
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.HAND_SELECTION_STEP:
      return {
        ...state,
        step: action.payload.step || state.step,
        handSelectionStep: Object.keys(action.payload as HandSelectionStepPayload)
          .filter(key => key !== 'step')
          .reduce((acc, key) => {
            acc[key as keyof HandSelectionStepPayload] = action.payload[key];
            return acc;
          }, {} as HandSelectionStepPayload),
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.HARDWARE_SELECTION_STEP:
      return {
        ...state,
        hardwareSelectionStep: action.payload,
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.HAND_DETAIL:
      return {
        ...state,
        handDetailPage: action.payload,
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.RESET:
      return initialState;
    // 更新前のstateをそのまま返す。
    default:
      return state;
  }
};


// createContextはReactフックではないため、コンポーネント外で使用可能
// as でオブジェクトの型チェックをクリアする。
export const DataStoreContext = createContext({} as DataStoreContextType);

export function DataStoreContextProvider(props: any): JSX.Element {
  // useReducerで生成した「参照用state」と「更新用dispatch」を、contextに渡す。
  const [state, dispatch] = useReducer(reducerFunc, initialState);
  return (
    <DataStoreContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </DataStoreContext.Provider>
  );
}

export type TabIndexPayload = DataStoreType['tabIndex'];

export type StepPayload = DataStoreType['step'];

export type CalculatedHandPayload = {
  calculatedHand: DataStoreType['calculatedHand'];
  step?: DataStoreType['step'];
}

export type SearchWorkClassPayload = {
  searchCond: DataStoreType['workClassSelectionStep']['searchCond'];
  workClassList: DataStoreType['workClassSelectionStep']['workClassList'];
}

export type WorkClassSelectionStepPayload = DataStoreType['workClassSelectionStep'] & {
  step?: DataStoreType['step'];
}

export type WorkInstanceSelectionStepPayload = DataStoreType['workInstanceSelectionStep'] & {
  step?: DataStoreType['step'];
}

export type SearchCriteriaAdditionalStepPayload = DataStoreType['searchCriteriaAdditionalStep'] & {
  step?: DataStoreType['step'];
}

export type HandSelectionStepPayload = DataStoreType['handSelectionStep'] & {
  step?: DataStoreType['step'];
}

export type HardwareSelectionStepPayload = DataStoreType['hardwareSelectionStep'] & {
  step?: DataStoreType['step'];
}

export type HandDetailPagePayload = DataStoreType['handDetailPage'];
