import React, {useContext, useState} from "react";
import {Box, Card, CardMedia, CircularProgress, Container, Stack, Typography} from "@mui/material";
import {
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  HardwareSelectionStepPayload,
  STEPS
} from "../../contexts/HandSelectionContext";
import useNotification from "../../../../utils/notificationUtil";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import {HandDocument} from "../../../equipmentdb/types/pj1dbApiHand";
import {FingertipDocument} from "../../../equipmentdb/types/pj1dbApiFingertip";
import {findArm, findAttachment} from "../../../../utils/awsAmplifyUtil";
import {AttachmentDocument} from "../../../equipmentdb/types/pj1dbApiAttachment";
import {ArmDocument} from "../../../equipmentdb/types/pj1dbApiArm";

export interface HardwareSelectionStepProps {
  isLoading: boolean;
  // eslint-disable-next-line react/require-default-props
  hand?: HandDocument;
  // eslint-disable-next-line react/require-default-props
  fingertip?: FingertipDocument;
  attachments: AttachmentDocument[];
  arms: ArmDocument[];
  onPrevStep: () => void;
}

export default function HardwareSelectionStep(props: HardwareSelectionStepProps) {
  return (
    <Container
      sx={{px: 0, py: 2, display: "flex", flexDirection: "row", flexWrap: "wrap"}}
      maxWidth={false}
    >
      <Stack width='100%' gap={4}>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h6' component='h2'>ハードウェア構成</Typography>
          <Pjdb1Button
            label='計算結果に戻る'
            onClick={props.onPrevStep}
            variant='text'
            color='inherit'
          />
        </Box>

        <HandAndFingertip
          hand={props.hand}
          fingertip={props.fingertip}
        />

        <AttachmentAndArm
          isLoading={props.isLoading}
          attachments={props.attachments}
          arms={props.arms}
        />

      </Stack>
    </Container>
  );
}

export function useHardwareSelectionStepHelpers() {
  const {state, dispatch} = useContext(DataStoreContext);
  const {errorMsg} = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const toPrevStep = () => {
    dispatch({type: CONTEXT_ACTION_TYPE.STEP, payload: STEPS.HAND_SELECTION});
  }

  const findAttachmentAndArmProc = async (hand: HandDocument) => {
    setIsLoading(true);
    try {
      const attachmentResult = await findAttachment({
        query: {
          handIds: {
            $regex: hand.attr.handId,
          }
        }
      })
      const attachments = attachmentResult.data;
      if (!attachmentResult.isSuccess || !attachments) {
        errorMsg("アタッチメント情報の取得に失敗しました。");
        setIsLoading(false);
        return;
      }

      const armResult = await findArm({
        query: {
          armId: {
            $in: Object.values(attachments).map(attachment => attachment.attr.armIds).flat(),
          }
        }
      })
      const arms = armResult.data;
      if (!armResult.isSuccess || !arms) {
        errorMsg("アーム情報の取得に失敗しました。");
        setIsLoading(false);
        return;
      }

      dispatch({
        type: CONTEXT_ACTION_TYPE.HARDWARE_SELECTION_STEP,
        payload: {
          ...state.hardwareSelectionStep,
          attachments: Object.values(attachments),
          arms: Object.values(arms),
        } satisfies HardwareSelectionStepPayload
      });
    } catch (e) {
      console.error(e);
      errorMsg("アタッチメント・アーム情報の取得に失敗しました。");
    }
    setIsLoading(false);
  }

  return {
    isLoading,
    toPrevStep,
    findAttachmentAndArmProc,
  }
}

function HandAndFingertip(
  {
    hand,
    fingertip,
  }: {
    // eslint-disable-next-line react/require-default-props
    hand?: HandDocument;
    // eslint-disable-next-line react/require-default-props
    fingertip?: FingertipDocument;
  }
) {
  return (
    <Stack width='100%' gap={2}>
      <Typography variant='subtitle1' component='h3'>ハンド・爪</Typography>
      <Box display='flex' gap={1} flexWrap='wrap'>
        {hand && (
          <Card sx={{width: 240, height: 'fit-content'}}>
            <CardMedia
              component="img"
              height="250"
              image={hand?.url?.image[0]}
              alt={hand.attr.handInfo.name}
            />
          </Card>
        )}
        {fingertip && (
          <Card>
            <CardMedia
              component="img"
              height="250"
              image={fingertip.url?.image[0]}
              alt={fingertip.attr.fingertipInfo.name}
            />
          </Card>
        )}
      </Box>
    </Stack>
  );
}

function AttachmentAndArm(
  {
    isLoading,
    attachments,
    arms,
  }: {
    isLoading: boolean;
    attachments: AttachmentDocument[],
    arms: ArmDocument[]
  }
) {
  return (
    <Stack width='100%' gap={2}>
      <Typography variant='subtitle1' component='h3'>接続可能なアタッチメントとアーム一覧</Typography>

      {isLoading && (
        <Box display='flex' mt={2}>
          <CircularProgress/>
        </Box>
      )}


      {/* TODO: ここはアタッチメントとアームの組合せごとに */}
      {!isLoading && attachments.map(attachment => {
        const myArms = arms.filter(arm => attachment.attr.armIds.includes(arm.attr.armId));
        return (
          <Box key={attachment.attr.attachmentId} display='flex' gap={1} flexWrap='wrap'>
            <Card sx={{width: 240, height: 'fit-content'}}>
              <CardMedia
                component="img"
                height="250"
                image={attachment.url?.image[0]}
                alt={attachment.attr.attachmentInfo.name}
              />
            </Card>
            {!isLoading && arms.length > 0 && (
              <Typography variant='h6' component={Box} textAlign='center' alignContent='center'>▶︎</Typography>
            )}
            {myArms.map(arm => (
              <Card key={arm.attr.armId} sx={{width: 240, height: 'fit-content'}}>
                <CardMedia
                  component="img"
                  height="250"
                  image={arm.url?.image[0]}
                  alt={arm.attr.armInfo.name}
                />
              </Card>
            ))}
          </Box>
        )
      })}

      {!isLoading && attachments.length === 0 && arms.length === 0 && (
        <Box width='100%' textAlign='center'>
          <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>該当データがありません。</Typography>
        </Box>
      )}
    </Stack>
  );
}
