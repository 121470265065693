/**
 * ワーク属性表用タイプ
 */
export interface AttrListRowType {
  key: string;
  label: string;
  meta?: Record<string, any>
}

/**
 * ワーク属性表
 */
export const WORK_ATTRS = {
  // Class Attributes
  nameJp: {key: "nameJp", label: "商品/部品名（日本語表記）"},
  nameEn: {key: "nameEn", label: "商品/部品名（英語表記）"},
  code: {key: "code", label: "JANコード/部品コード"},
  contentWeight: {key: "contentWeight", label: "内容重量[g]", meta: {unit: 'g'}},
  contentVolume: {key: "contentVolume", label: "内容容量[ℓ]", meta: {unit: 'ℓ'}},
  // Instance Attributes
  description: {key: "description", label: "説明"},
  // Model Attributes
  modelType: {key: "modelType", label: "モデル種別"},
  modelFile: {key: "modelFile", label: "モデルファイル"},
  fileType: {key: "fileType", label: "入力ファイル方式"},
  when: {key: "when", label: "測定日時"},
  who: {key: "who", label: "測定者"},

  // Common Attributes
  classId: {key: "class-id", label: "クラスID"},
  instanceId: {key: "instance-id", label: "インスタンスID"},
  modelId: {key: "model-id", label: "モデルID"},

  created: {key: "created", label: "登録日時"}, // inserted と同じ値が入っているがとりあえず created で表示
  creator: {key: "creator", label: "登録者"},

  children: {key: "children", label: "子クラスのID"},
} as const satisfies Readonly<Record<string, AttrListRowType>>
