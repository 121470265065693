/**
 * Rounds a number to 2 decimal places
 * @param num
 * @param decimalPlaces
 */
// eslint-disable-next-line import/prefer-default-export
export function roundNum(num: number, decimalPlaces = 2): number {
  const multiplier = 10**decimalPlaces;

  if (num < 0) {
    const absNum = Math.abs(num);
    return -Math.round((absNum + Number.EPSILON) * multiplier) / multiplier;
  }

  return Math.round((num + Number.EPSILON) * multiplier) / multiplier;
}
