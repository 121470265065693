import React, {useContext, useEffect, useRef, useState} from "react";
import {
  Box,
  BoxProps,
  Card,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {WorkClassDocument, WorkInstanceDocument} from "../../../../types/pj1dbApiWork";
import useNotification from "../../../../utils/notificationUtil";
import {
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  SearchCriteriaAdditionalStepPayload,
  SearchCriteriaInstance,
  SearchCriteriaInstanceInputs,
} from "../../contexts/HandSelectionContext";
import {findHandlingWorkModels} from "../../../../utils/awsAmplifyUtil";
import {WORK_ATTRS} from "../../../../constants/work";
import {FindModelResponse, Grasp2pInfo, pickGrasp2pRegionTypeLabel} from "../../../../types/pj1dbApiHandling";
import {handleInputChange} from "../../../../utils/formUtil";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import SearchCriteriaInstanceGroup, {initialSearchCriteriaGroupInputs,} from "../forms/SearchCriteriaInstanceGroup";
import HandPowerSourceField, {HandPowerSourceFieldInputs} from "../forms/HandPowerSourceField";
import WorkCandidateModel3D from "../3d/WorkCandidateModel3D";
import {ControlState, useControlState, UseControlStateReturn, useThree, UseThreeReturn} from "../3d/View3D";
import {MaterialTypeField} from "../3d/controlFields/MaterialTypeField";
import {PerspectiveField} from "../3d/controlFields/PerspectiveField";
import {LookAtField} from "../3d/controlFields/LookAtField";
import {GridSizeField} from "../3d/controlFields/GridSizeField";
import {roundNum} from "../../../../utils/numberUtil";

export interface SearchCriteriaAdditionalStepProps {
  three: UseThreeReturn;
  controlStateResult: UseControlStateReturn;
  selectedObjectId: string | null;
  setSelectedObjectId: React.Dispatch<React.SetStateAction<string | null>>;
  searchCriteriaFormRef: React.RefObject<HTMLFormElement>;
  isLoading: boolean;
  workClass: WorkClassDocument;
  workInstance: WorkInstanceDocument;
  workModel: FindModelResponse | null;
  searchCriteria: SearchCriteriaInstanceInputs;
  setSearchCriteria: React.Dispatch<React.SetStateAction<SearchCriteriaInstanceInputs>>;
  handPowerSource: HandPowerSourceFieldInputs;
  setHandPowerSource: React.Dispatch<React.SetStateAction<HandPowerSourceFieldInputs>>;
  selectedModelDirection: FindModelResponse['gravityWithDirections'][number] | null;
  onSelectModelDirection: (workModelDirection: FindModelResponse['gravityWithDirections'][number]) => void;
  onAddSearchCriteria: (params: {
    workClass: WorkClassDocument,
    workInstance: WorkInstanceDocument,
    workModel: FindModelResponse,
    workModelDirection: FindModelResponse['gravityWithDirections'][number],
    criteria: SearchCriteriaInstanceInputs
  }) => void;
}

export default function SearchCriteriaAdditionalStep(props: SearchCriteriaAdditionalStepProps) {
  const {
    controlState,
    setControlState,
  } = props.controlStateResult;
  const isSelectedDirection = (direction: FindModelResponse['gravityWithDirections'][number]) =>
    props.selectedModelDirection?.thumbnail === direction.thumbnail;
  const selectedStyle: BoxProps['sx'] = {
    outlineColor: (theme) => theme.palette.primary.light,
    outlineStyle: "solid",
    outlineWidth: 6,
    borderRadius: 1,
  };

  if (props.isLoading) {
    return (
      <Box display='flex' mt={2}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <Stack gap={2} mt={2}>
      <Box display='flex' gap={4}>
        {props.workModel?.gravityWithDirections.map((direction) => (
          <Box
            key={direction.thumbnail}
            sx={isSelectedDirection(direction) ? selectedStyle : {}}
            width={200}
            height={200}
            onClick={() => props.onSelectModelDirection(direction)}
          >
            <img
              src={direction.thumbnail}
              alt="thumbnail"
              width={200}
              height={200}
            />
          </Box>
        ))}
      </Box>

      {props.selectedModelDirection
        && props.workModel?.gravityWithDirections?.some((direction) => direction.thumbnail === props.selectedModelDirection?.thumbnail)
        && (
          <Card sx={{p: 3, mt: 2}}>
            <Box display='flex' gap={6} flexWrap='wrap' width='100%'>
              <Stack gap={1}>
                <Typography variant="body2">※ モデルはイメージです。実際とは異なる場合があります。</Typography>
                {props.workModel && (
                  <WorkCandidateModel3D
                    three={props.three}
                    model={props.workModel}
                    gravity={props.selectedModelDirection.gravity}
                    onObjectSelected={(object) => props.setSelectedObjectId(object?.name ?? null)}
                  />
                )}
              </Stack>

              <Stack gap={6}>
                <Stack gap={2}>
                  <MaterialTypeField control={props.three.control} value={controlState.materialType}
                                     onChange={(e) => handleInputChange<ControlState>(e, controlState, setControlState)}/>
                  <PerspectiveField control={props.three.control} value={controlState.perspective}
                                    onChange={(e) => handleInputChange<ControlState>(e, controlState, setControlState)}/>
                  <LookAtField control={props.three.control} value={controlState.lookAtType}
                               onChange={(e) => handleInputChange<ControlState>(e, controlState, setControlState)}/>
                  <GridSizeField control={props.three.control} value={controlState.gridSize}
                                 onChange={(e) => handleInputChange<ControlState>(e, controlState, setControlState)}/>
                </Stack>

                <Stack gap={1}>
                  <Typography variant='body1'>把持候補リスト</Typography>
                  <TableContainer component={Paper} sx={{width: 'fit-content', maxWidth: "100%"}}>
                    <Table size="small">
                      <TableHead sx={{bgcolor: "#EEE"}}>
                        <TableRow>
                          <TableCell align='center' sx={{px: 1, py: 0.5}}>No</TableCell>
                          <TableCell align='center' sx={{px: 1, py: 0.5}}>把持候補領域タイプ</TableCell>
                          <TableCell align='center' sx={{px: 1, py: 0.5}}>必要把持力</TableCell>
                          <TableCell align='center' sx={{px: 1, py: 0.5}}>必要把持幅</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.workModel?.grasp2p &&
                          Object.entries(props.workModel.grasp2p)
                            .map(([objectId, value], i) =>
                              value.map((info, infoIndex) => {
                                const calculated = calcSearchCriteria({info, direction: props.selectedModelDirection});
                                return (
                                  <TableRow
                                    key={`${objectId}-${info.id}`}
                                    sx={{
                                      '&:last-child td, &:last-child th': {border: 0},
                                      bgcolor: props.selectedObjectId === objectId ? "#deeeff" : undefined,
                                    }}
                                    onClick={() => {
                                      props.setSearchCriteria({
                                        ...props.searchCriteria,
                                        rangeOpen: calculated.rangeOpen,
                                        rangeClose: calculated.rangeClose,
                                        forceMin: calculated.forceMin,
                                        stroke: calculated.stroke,
                                      })
                                      props.setSelectedObjectId(objectId);
                                    }}
                                  >
                                    <TableCell align='center' sx={{px: 1, py: 0.5}}>{i + infoIndex + 1}</TableCell>
                                    <TableCell align='center'
                                               sx={{px: 1, py: 0.5}}>{pickGrasp2pRegionTypeLabel(objectId)}</TableCell>
                                    <TableCell align='center' sx={{px: 1, py: 0.5}}
                                               dangerouslySetInnerHTML={{__html: `${roundNum(info.requiredForce ?? 0)} N`}}/>
                                    <TableCell align='center' sx={{px: 1, py: 0.5}}
                                               dangerouslySetInnerHTML={{__html: `${roundNum(info.position?.radius ? info.position.radius * 1000 * 2 : 0)} mm`}}/>
                                  </TableRow>
                                );
                              })
                            )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Stack>

              <form ref={props.searchCriteriaFormRef} style={{width: '100%'}}>
                <Stack gap={2} width='100%'>
                  <Typography variant='h6'>ハンド検索条件</Typography>

                  <SearchCriteriaInstanceGroup isClear={false} inputs={props.searchCriteria}
                                               setInputs={props.setSearchCriteria}/>

                  <HandPowerSourceField isClear={false} inputs={props.handPowerSource}
                                        setInputs={props.setHandPowerSource}/>

                  <Box display='flex' justifyContent='end'>
                    <Pjdb1Button
                      label='検索条件決定'
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        if (!props.workModel) {
                          throw new Error("モデルが選択されていません。");
                          return;
                        }
                        if (!props.selectedModelDirection) {
                          throw new Error("モデルの向きが選択されていません。");
                          return;
                        }
                        props.onAddSearchCriteria({
                          workClass: props.workClass,
                          workInstance: props.workInstance,
                          workModel: props.workModel,
                          workModelDirection: props.selectedModelDirection,
                          criteria: props.searchCriteria,
                        })
                      }}/>
                  </Box>
                </Stack>
              </form>
            </Box>
          </Card>
        )}
    </Stack>
  );
}

export type UseSearchCriteriaAdditionalStepHelpersResult = ReturnType<typeof useSearchCriteriaAdditionalStepHelpers>;

export function useSearchCriteriaAdditionalStepHelpers() {
  const {state, dispatch} = useContext(DataStoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const {errorMsg, infoMsg} = useNotification();
  const three = useThree();
  const controlStateResult = useControlState();
  const [selectedModelDirection, setSelectedModelDirection] = useState<FindModelResponse['gravityWithDirections'][number] | null>(null);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteriaInstanceInputs>(state.searchCriteriaAdditionalStep.searchCriteriaInstanceInputs);
  const [handPowerSource, setHandPowerSource] = useState<HandPowerSourceFieldInputs>(state.searchCriteriaAdditionalStep.handPowerSourceInputs);
  const [notFoundMsg, setNotFoundMsg] = useState("");
  const [selectedObjectId, setSelectedObjectId] = useState<string | null>(null);
  const searchCriteriaFormRef = useRef<HTMLFormElement>(null);
  const place = Object.values(state.searchCriteriaAdditionalStep.workModel?.grasp2p ?? {}).find(() => true)?.find(() => true) ?? null;
  const initSearchCriteria = calcSearchCriteria({info: place, direction: selectedModelDirection});

  const findWorkModels = async ({workInstance}: {
    workInstance: WorkInstanceDocument
  }) => {
    setIsLoading(true);
    try {
      const result = await findHandlingWorkModels({
        instanceId: workInstance.attr[WORK_ATTRS.instanceId.key],
      });

      if (!result.isSuccess) {
        errorMsg("検索処理が失敗しました。");
        return;
      }

      const data = result.data ?? null;
      if (data === null) {
        setNotFoundMsg("該当データがありません。");
      }

      const firstDirection = data?.gravityWithDirections[0] ?? null;
      setSelectedModelDirection(firstDirection);
      const objId = Object.entries(data?.grasp2p ?? {}).find(() => true)?.[0] ?? null;
      setSelectedObjectId(objId);
      dispatch({
        type: CONTEXT_ACTION_TYPE.SEARCH_CRITERIA_ADDITIONAL_STEP, payload: {
          ...state.searchCriteriaAdditionalStep,
          workModel: data,
          selectedModelDirection: firstDirection,
          selectedObjectId: objId,
        } satisfies SearchCriteriaAdditionalStepPayload
      });
    } finally {
      setIsLoading(false);
    }
  };

  const selectModelDirection = (workModelDirection: FindModelResponse['gravityWithDirections'][number]) => {
    const direction = workModelDirection.thumbnail === selectedModelDirection?.thumbnail ? null : workModelDirection;
    setSelectedModelDirection(direction);
    dispatch({
      type: CONTEXT_ACTION_TYPE.SEARCH_CRITERIA_ADDITIONAL_STEP, payload: {
        ...state.searchCriteriaAdditionalStep,
        selectedModelDirection: direction,
      } satisfies SearchCriteriaAdditionalStepPayload
    });
  }

  const addSearchCriteria: SearchCriteriaAdditionalStepProps['onAddSearchCriteria'] = (
    {
      criteria,
      workInstance,
    }
  ) => {
    if (selectedModelDirection === null || !searchCriteriaFormRef.current?.reportValidity()) {
      errorMsg("入力内容に誤りがあります。");
      return;
    }

    setSearchCriteria(initSearchCriteria);
    dispatch({
      type: CONTEXT_ACTION_TYPE.SEARCH_CRITERIA_ADDITIONAL_STEP, payload: {
        ...state.searchCriteriaAdditionalStep,
        searchCriteriaInstanceInputs: initSearchCriteria,
        searchCriteriaList: [...state.searchCriteriaAdditionalStep.searchCriteriaList, {
          ...criteria as SearchCriteriaInstance,
          instanceId: workInstance.attr[WORK_ATTRS.instanceId.key],
          direction: selectedModelDirection,
        }],
      } satisfies SearchCriteriaAdditionalStepPayload
    });
    infoMsg('検索条件を追加しました。');
  }

  const deleteSearchCriteria = (index: number) => {
    const newSearchCriteriaList = state.searchCriteriaAdditionalStep.searchCriteriaList.filter((_, i) => i !== index);
    dispatch({
      type: CONTEXT_ACTION_TYPE.SEARCH_CRITERIA_ADDITIONAL_STEP, payload: {
        ...state.searchCriteriaAdditionalStep,
        searchCriteriaList: newSearchCriteriaList,
      } satisfies SearchCriteriaAdditionalStepPayload
    });
    infoMsg('検索条件を削除しました。');
  }

  useEffect(() => {
    three.control?.selectObject(selectedObjectId ?? undefined);
  }, [selectedObjectId, three]);

  useEffect(() => {
    // 手動で編集していた場合は、stateの値を使用し、そうでない場合は初期値を使用する
    setSearchCriteria(initSearchCriteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModelDirection]);

  useEffect(() => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.SEARCH_CRITERIA_ADDITIONAL_STEP, payload: {
        ...state.searchCriteriaAdditionalStep,
        searchCriteriaInstanceInputs: searchCriteria,
      } satisfies SearchCriteriaAdditionalStepPayload
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteria]);

  useEffect(() => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.SEARCH_CRITERIA_ADDITIONAL_STEP, payload: {
        ...state.searchCriteriaAdditionalStep,
        handPowerSourceInputs: handPowerSource,
      } satisfies SearchCriteriaAdditionalStepPayload
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handPowerSource]);

  return {
    three,
    controlStateResult,
    searchCriteria,
    handPowerSource,
    searchCriteriaFormRef,
    isLoading,
    notFoundMsg,
    selectedModelDirection,
    selectedObjectId,
    setSelectedObjectId,
    setSearchCriteria,
    setHandPowerSource,
    findWorkModels,
    selectModelDirection,
    addSearchCriteria,
    deleteSearchCriteria,
  };
}

function calcSearchCriteria({info, direction}: {
  info: Grasp2pInfo | null,
  direction: FindModelResponse['gravityWithDirections'][number] | null,
}) {
  // APIで取得される数値の単位は m だが、UI表示、APIへのインプットは mm にする必要がある
  const radius = info?.position?.radius ? info.position.radius * 1000 : 0;
  // 初期値: 閉時幅 = 半径(radius) × 2 - 2mm
  const rangeClose = roundNum(radius * 2 - (radius === 0 ? 0 : 2));
  // 初期値: 開時幅 = 閉時幅 × 1.1
  const rangeOpen = roundNum(rangeClose * 1.1);

  return {
    ...initialSearchCriteriaGroupInputs,
    rangeOpen: rangeOpen.toString(),
    rangeClose: rangeClose.toString(),
    // 初期値: ストローク = 開時幅 - 閉時幅
    stroke: String(roundNum(rangeOpen - rangeClose)),
    // 初期値: 把持力下限 = 必要把持力
    forceMin: roundNum(info?.requiredForce ?? 0).toString(),
    // 初期値: なし
    forceMax: '',
    // 初期値: 可搬重量 = 重量
    payload: roundNum(direction?.gravity.weight ?? 0).toString(),
  }
}
