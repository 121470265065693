import React, {useCallback, useEffect} from "react";
import {Stack, Typography} from "@mui/material";
import ThreeControl, {LookAtType} from "../../../utils/view3dUtil";
import Pjdb1RadioGroup from "../../../../../components/inputs/Pjdb1RadioGroup";

export interface LookAtFieldProps {
  control: ThreeControl | undefined;
  value: LookAtType;
  // eslint-disable-next-line react/require-default-props
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}


export function LookAtField(props: LookAtFieldProps) {
  const changeLookAtType = useCallback((lookAtType: LookAtType) => {
    console.log("LookAtField changeLookAtType", lookAtType);
    if (props.control) props.control.setLookAtType(lookAtType);
  }, [props.control]);

  useEffect(() => {
    changeLookAtType(props.value);
  }, [changeLookAtType, props.value]);

  return (
    <Stack>
      <Typography>LookAt</Typography>
      <Pjdb1RadioGroup
        name="lookAtType"
        radios={[
          {btnKey: 'origin', btnLbl: '原点'},
          {btnKey: 'modelCenter', btnLbl: 'モデル中心'},
          {btnKey: 'selectedObjectCenter', btnLbl: '選択部分中心'},
          {btnKey: 'controlPoint', btnLbl: '制御点', disabled: !props.control?.options.displayControlPoint}
        ]}
        value={props.value}
        onChange={(e) => {
          changeLookAtType(e.target.value as LookAtType)
          if (props.onChange) props.onChange(e);
        }}
      />
    </Stack>
  )
}
