import {useContext, useEffect, useState} from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {ROLE_KEYS, useUserRole} from "../../../../utils/awsConfigure";
import {FingertipDocument, makeFingertipAttrList} from "../../../equipmentdb/types/pj1dbApiFingertip";
import {AttrListRowType} from "../../../equipmentdb/types/handForm";
import {HandDocument} from "../../../equipmentdb/types/pj1dbApiHand";
import Pjdb1RadioGroup from "../../../../components/inputs/Pjdb1RadioGroup";
import UrdfLoader from "../../../../components/UrdfLoader";
import {CONTEXT_ACTION_TYPE, DataStoreContext, HandDetailPagePayload} from "../../contexts/HandSelectionContext";

/**
 * calcHandCandidate_poll の結果で取得できるハンドと爪は一つの前提
 */
export default function FingertipDetail() {
  const {userRole} = useUserRole();
  const {state, dispatch} = useContext(DataStoreContext);
  const info: FingertipDocument = state.handDetailPage.hand!.fingertip!;
  const handList: HandDocument[] = [state.handDetailPage.hand!.hand];
  const [fingertipAttrList, setFingertipAttrList] = useState<AttrListRowType[]>([]);
  const [dispImageType, setDispImageType] = useState<string>("image");

  interface ImageListRowType {
    key: string;
    url: string;
  }

  const [fingertipImageList, setFingertipImageList] = useState<ImageListRowType[]>([]);
  const [selectedFingertipImageKey, setSelectedFingertipImageKey] = useState<string>("");
  const imageStyle = {
    border: "1px solid rgba(0, 0, 0, 0.23)",
  }
  const selectedImageStyle = {
    border: "2px solid #48B130",
  }

  useEffect(() => {
    if (info.attr.fingertipId === "") return;

    // 爪属性表
    setFingertipAttrList(makeFingertipAttrList(info, false));

    // 画像リストを作成
    if (info.url) {
      setFingertipImageList(
        info.url?.image.map((imageUrl, idx) => (
          {key: `image${idx}`, url: imageUrl}
        ))
      );
      setSelectedFingertipImageKey("image0");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);


  return (
    <Container maxWidth="lg" sx={{maxWidth: "1500px !important"}}>
      <Card sx={{p: 3, mt: 2}}>

        <Grid container spacing={3} alignItems="flex-end">
          <Grid>
            <Typography variant="h6">爪詳細</Typography>
          </Grid>
          <Grid sx={{flexGrow: 1}}>
            <Typography variant="body2" sx={{color: "gray"}}>
              登録日時（登録者）：
              {`${info.attr?.registrationDate ? new Date(info.attr?.registrationDate).toLocaleString() : ""}
                  （${userRole === ROLE_KEYS.ADMIN ? String(info.attr.registrant) : String(info.attr.domain)}）`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems="flex-start" sx={{mt: 1}}>
          <Grid size={{xs: 12, md: 7}} sx={{pt: "0px !important"}}>

            <Pjdb1RadioGroup
              name="dispImageType" value={dispImageType}
              onChange={(e) => (setDispImageType(e.target.value))}
              radios={[
                {btnKey: "image", btnLbl: "画像"},
                {btnKey: "model", btnLbl: "モデル"},
              ]}
              disabledRadios={info.url?.urdf && info.url?.urdf.length > 0 ? [] : ["model"]}
            />

            <Grid container spacing={2} alignItems="flex-start" sx={{mt: 0}}>
              {dispImageType === "image" &&
                  <>
                      <Grid size={{xs: 3, md: 2}}
                            sx={{
                              pt: "0px !important",
                              pr: "1px !important",
                              height: "50vw",
                              maxHeight: "70vh",
                              overflowY: "auto",
                              overflowX: "hidden"
                            }}
                            className="thin-scroll-bar">
                        {fingertipImageList.map(row => (
                          <Box key={row.key}>
                            <a href="" onClick={(e) => {
                              e.preventDefault();
                              setSelectedFingertipImageKey(row.key);
                            }}>
                              <img src={row.url} className="square-image"
                                   style={row.key === selectedFingertipImageKey ? selectedImageStyle : imageStyle}
                                   alt={row.key}/>
                            </a>
                          </Box>
                        ))}
                      </Grid>
                      <Grid size={{xs: 9, md: 10}} sx={{pt: "0px !important"}}>
                          <img src={fingertipImageList.find((row) => (row.key === selectedFingertipImageKey))?.url}
                               style={imageStyle} className="square-image" alt=""/>
                      </Grid>
                  </>
              }
              {dispImageType === "model" &&
                  <Grid sx={{p: "0px !important", border: "1px solid rgba(0, 0, 0, 0.23)"}} className="square-image">
                      <UrdfLoader urdfUrl={String(info.url?.urdf[0])}/>
                  </Grid>
              }
            </Grid>

          </Grid>
          <Grid size={{xs: 12, md: 5}} sx={{pt: "20px !important"}}>

            <Typography variant="subtitle1">爪属性表</Typography>
            <TableContainer component={Paper}>
              <Table sx={{maxWidth: "100%"}} size="small" aria-label="a dense table">
                <TableHead sx={{bgcolor: "#EEE"}}>
                  <TableRow>
                    <TableCell sx={{px: 1, py: 0.5}}>分類</TableCell>
                    <TableCell sx={{px: 1, py: 0.5}}>属性</TableCell>
                    <TableCell sx={{px: 1, py: 0.5}}>属性値</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fingertipAttrList.map((row) => (
                    <TableRow
                      key={row.key}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell width={80} sx={{px: 1, py: 0.5}}>{row.class}</TableCell>
                      <TableCell width={150} sx={{px: 1, py: 0.5}}
                                 dangerouslySetInnerHTML={{__html: String(row.attr)}}/>
                      <TableCell sx={{px: 1, py: 0.5}} dangerouslySetInnerHTML={{__html: String(row.value)}}/>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems="flex-end" sx={{mt: 1}}>
          <Grid sx={{pt: "8px !important"}}>
            <Typography variant="h6">接続可能ハンド一覧</Typography>
          </Grid>
        </Grid>

        {handList.length === 0 &&
            <Typography variant="subtitle1" sx={{m: 2, fontWeight: "bold"}}>
                接続可能ハンドがありません。
            </Typography>
        }
        {handList.length > 0 &&
            <Grid container spacing={3} alignItems="flex-start" sx={{mt: 1, m: 2}}>
              {handList.map(row => (
                <Grid key={row.attr.handId} onClick={() => {
                  dispatch({
                    type: CONTEXT_ACTION_TYPE.HAND_DETAIL, payload: {
                      ...state.handDetailPage,
                      isShowFingertipDetail: false,
                    } satisfies HandDetailPagePayload
                  })
                }}>
                  <Card sx={{width: 240, height: 200}}>
                    <CardMedia
                      component="img"
                      height="145"
                      image={row.url?.image[0]}
                      alt={row.attr.handInfo.name}
                    />
                    <CardContent sx={{py: 0.5, px: 1, m: 0}}>
                      <Typography variant="subtitle2" noWrap component='p' sx={{mt: 0.5}}>
                        製品名：{row.attr.handInfo.name}
                      </Typography>
                      <Typography variant="subtitle2" noWrap component='p' sx={{color: "#888"}}>
                        製造者：{row.attr.handInfo.producer}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
        }
      </Card>
    </Container>
  )
}
