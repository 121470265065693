import React, {useCallback, useEffect} from "react";
import {Stack, Typography} from "@mui/material";
import ThreeControl, {MaterialType} from "../../../utils/view3dUtil";
import Pjdb1RadioGroup from "../../../../../components/inputs/Pjdb1RadioGroup";

export interface MaterialTypeFieldProps {
  control: ThreeControl | undefined;
  value: MaterialType;
  // eslint-disable-next-line react/require-default-props
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function MaterialTypeField(props: MaterialTypeFieldProps) {
  const changeMaterialType = useCallback((materialType: MaterialType) => {
    console.log("MaterialTypeField changeMaterialType", materialType);
    if (props.control) props.control.setMaterialType(materialType);
  }, [props.control]);

  useEffect(() => {
    changeMaterialType(props.value);
  }, [changeMaterialType, props.value]);

  return (
    <Stack>
      <Typography>Material</Typography>
      <Pjdb1RadioGroup
        name="materialType"
        radios={[
          {btnKey: 'original', btnLbl: '標準'},
          {btnKey: 'wireframe', btnLbl: 'ワイヤー'},
          {btnKey: 'solid', btnLbl: 'ソリッド'},
        ]}
        value={props.value}
        onChange={(e) => {
          changeMaterialType(e.target.value as MaterialType);
          if (props.onChange) props.onChange(e);
        }}
      />
    </Stack>
  )
}
