import { RobocipDbDocument } from "./pj1dbApi";
import { FormSystemAttr, AttrListRowType } from './systemForm'
import { SYSTEM_INDUSTRY, SYSTEM_APPLICATION, ARM_STRUCTURE } from "../../../constants/constants";

// --------------------------------------------------------------------------------------
/**
 * system APIのレスポンス型の定義
 */
export interface Reach {
  maximumReach: number;
}
export interface ArmInfo{
  name: string;
  manufacturer: string;
  structure: string;
  cobot: boolean;
  numberOfAxes: number;
}
export interface ArmPerformance{
  payload: number;
  reach: Reach;
}
export interface ArmInfos{
  number: number;
  description: string[];
  armId?: string;
  armInfo?: ArmInfo;
  armPerformance?: ArmPerformance;
}

export interface HandInfo {
  name: string;
  producer: string;
}
export interface HandInfos {
  number: number;
  description: string[];
  handId?: string;
  handInfo?: HandInfo;
}

export interface MinMax {
  min: number;
  max: number;
}
export interface WorkInfos {
  number: number;
  "class-id"?: string;
  nameJp?: string;
  width?: MinMax;
  depth?: MinMax;
  height?: MinMax;
  mass?: MinMax;
}

export interface Cycletime {
  time?: number;
  remarks?: string;
}
export interface Layout {
  width: number;
  depth: number;
  height: number;
}

export interface BasicInfo{
  title: string;
  overview: string;
  industry: string[];
  application: string[];
  sier: string;
}
export interface Works{
  workInfos?: WorkInfos[];
}
export interface Parts{
  armInfos?: ArmInfos[];
  handInfos?: HandInfos[];
}
export interface Description{
  background?: string;
  result?: string;
  problem?: string[];
  solution?: string[];
  feature: string[];
  carryIn?: string;
  carryOut?: string;
  workDetection?: string;
  sequence: string[];
  cycletime?: Cycletime;
  layout: Layout;
}

export interface SystemAttr extends RobocipDbDocument {
  _id: string;
  systemId: string;
  public: boolean;
  basicInfo: BasicInfo;
  works: Works;
  parts: Parts;
  description: Description;
}
export interface ObjUrl {
  zip: string[];
  image: string[];
  urdf: string[];
}

export interface SystemDocument {
  attr: SystemAttr;
  url?: ObjUrl;
}

// SystemDocumentの初期値
export const systemInitDoc:SystemDocument = {
  attr: {
    _id: "",
    systemId: "",
    public: false,
    basicInfo: {
      title: "",
      overview: "",
      industry: [],
      application: [],
      sier: "",
    },
    works: {},
    parts: {},
    description: {
      feature: [],
      sequence: [],
      layout: {
        width: 0,
        depth: 0,
        height: 0,
      },
    },
    registrationDate: 0,
    registrant: "",
    domain:"",
  },
  url:  {
    zip: [],
    image: [],
    urdf: [],
  },
}

// --------------------------------------------------------------------------------------

/**
 * システムAPI 登録用タイプ
 */
export interface ApiSystemRegist {
  zipURI: string;
  public: boolean;
  basicInfo: BasicInfo;
  works: Works;
  parts: Parts;
  description: Description;
}

export interface ApiSystemUpdate {
  id:string;
  set:{
    zipURI?: string;
    public: boolean;
    basicInfo: BasicInfo;
    works: Works;
    parts: Parts;
    description: Description;
  }
}

// --------------------------------------------------------------------------------------
export function makeApiSystemRegistFromForm(
  inputs:FormSystemAttr,zipURI:string
):ApiSystemRegist{
  return {
    zipURI,
    public: inputs.public,
    basicInfo: {
      title: inputs.title,
      overview: inputs.overview,
      industry: inputs.industry.filter(val => val !== ''),
      application: inputs.application.filter(val => val !== ''),
      sier: inputs.sier,
    },
    works: {
      ...(inputs.workInfos.length > 0 ?
        {workInfos: inputs.workInfos.map(info => ({
          number: parseFloat(info.number),
          ...(info.classId ? {"class-id": info.classId} : {}),
          ...(!info.classId ? {nameJp: info.nameJp} : {}),
          width: {
            min: parseFloat(info.widthMin),
            max: parseFloat(info.widthMax),
          },
          depth: {
            min: parseFloat(info.depthMin),
            max: parseFloat(info.depthMax),
          },
          height: {
            min: parseFloat(info.heightMin),
            max: parseFloat(info.heightMax),
          },
          mass: {
            min: parseFloat(info.massMin),
            max: parseFloat(info.massMax),
          },
        }))} : {}),
    },
    parts:{
      ...(inputs.armInfos.length > 0 ?
        {armInfos: inputs.armInfos.map(info => ({
          number: parseFloat(info.number),
          description: info.description.filter(val => val !== ''),
          ...(info.armId ? {armId: info.armId} : {}),
          ...(!info.armId ? {
            armInfo: {
              name: info.name,
              manufacturer: info.manufacturer,
              structure: info.structure,
              cobot: info.cobot,
              numberOfAxes: parseFloat(info.numberOfAxes),
            },
            armPerformance: {
              payload: parseFloat(info.payload),
              reach: {
                maximumReach: parseFloat(info.maximumReach),
              }
            },
          } : {}),
        }))} : {}),
      ...(inputs.handInfos.length > 0 ?
        {handInfos: inputs.handInfos.map(info => ({
          number: parseFloat(info.number),
          description: info.description.filter(val => val !== ''),
          ...(info.handId ? {handId: info.handId} : {}),
          ...(!info.handId ? {
            handInfo: {
              name: info.name,
              producer: info.producer,
            },
          } : {}),
        }))} : {}),
    },
    description:{
      ...(inputs.background ? {background: inputs.background} : {}),
      ...(inputs.result ? {result: inputs.result} : {}),
      ...(inputs.problem.filter(val => val !== '').length > 0 ? {problem: inputs.problem.filter(val => val !== '')} : {}),
      ...(inputs.solution.filter(val => val !== '').length > 0 ? {solution: inputs.solution.filter(val => val !== '')} : {}),
      feature: inputs.feature.filter(val => val !== ''),
      ...(inputs.carryIn ? {carryIn: inputs.carryIn} : {}),
      ...(inputs.carryOut ? {carryOut: inputs.carryOut} : {}),
      ...(inputs.workDetection ? {workDetection: inputs.workDetection} : {}),
      sequence: inputs.sequence.filter(val => val !== ''),
      ...(inputs.cycletime||inputs.cycletimeRemarks ?
        {cycletime: {
          ...(inputs.cycletime ? {time: parseFloat(inputs.cycletime)} : {}),
          ...(inputs.cycletimeRemarks ? {remarks: inputs.cycletimeRemarks} : {}),
        }} : {}),
      layout:{
        width: parseFloat(inputs.layoutWidth),
        depth: parseFloat(inputs.layoutDepth),
        height: parseFloat(inputs.layoutHeight),
      }
    },
  };
}
export function makeApiSystemUpdateFromForm(
  inputs:FormSystemAttr,argZipURI:string
):ApiSystemUpdate{
  return {
    id: inputs.systemId,
    set: {
      ...(argZipURI !== "" ? {zipURI:argZipURI} : {}),
      public: inputs.public,
      basicInfo: {
        title: inputs.title,
        overview: inputs.overview,
        industry: inputs.industry.filter(val => val !== ''),
        application: inputs.application.filter(val => val !== ''),
        sier: inputs.sier,
      },
      works: {
        ...(inputs.workInfos.length > 0 ?
          {workInfos: inputs.workInfos.map(info => ({
            number: parseFloat(info.number),
            ...(info.classId ? {"class-id": info.classId} : {}),
            ...(!info.classId ? {nameJp: info.nameJp} : {}),
            width: {
              min: parseFloat(info.widthMin),
              max: parseFloat(info.widthMax),
            },
            depth: {
              min: parseFloat(info.depthMin),
              max: parseFloat(info.depthMax),
            },
            height: {
              min: parseFloat(info.heightMin),
              max: parseFloat(info.heightMax),
            },
            mass: {
              min: parseFloat(info.massMin),
              max: parseFloat(info.massMax),
            },
          }))} : {}),
      },
      parts:{
        ...(inputs.armInfos.length > 0 ?
          {armInfos: inputs.armInfos.map(info => ({
            number: parseFloat(info.number),
            description: info.description.filter(val => val !== ''),
            ...(info.armId ? {armId: info.armId} : {}),
            ...(!info.armId ? {
              armInfo: {
                name: info.name,
                manufacturer: info.manufacturer,
                structure: info.structure,
                cobot: info.cobot,
                numberOfAxes: parseFloat(info.numberOfAxes),
              },
              armPerformance: {
                payload: parseFloat(info.payload),
                reach: {
                  maximumReach: parseFloat(info.maximumReach),
                }
              },
            } : {}),
          }))} : {}),
        ...(inputs.handInfos.length > 0 ?
          {handInfos: inputs.handInfos.map(info => ({
            number: parseFloat(info.number),
            description: info.description.filter(val => val !== ''),
            ...(info.handId ? {handId: info.handId} : {}),
            ...(!info.handId ? {
              handInfo: {
                name: info.name,
                producer: info.producer,
              },
            } : {}),
          }))} : {}),
      },
      description:{
        ...(inputs.background ? {background: inputs.background} : {}),
        ...(inputs.result ? {result: inputs.result} : {}),
        ...(inputs.problem.filter(val => val !== '').length > 0 ? {problem: inputs.problem.filter(val => val !== '')} : {}),
        ...(inputs.solution.filter(val => val !== '').length > 0 ? {solution: inputs.solution.filter(val => val !== '')} : {}),
        feature: inputs.feature.filter(val => val !== ''),
        ...(inputs.carryIn ? {carryIn: inputs.carryIn} : {}),
        ...(inputs.carryOut ? {carryOut: inputs.carryOut} : {}),
        ...(inputs.workDetection ? {workDetection: inputs.workDetection} : {}),
        sequence: inputs.sequence.filter(val => val !== ''),
        ...(inputs.cycletime||inputs.cycletimeRemarks ?
          {cycletime: {
            ...(inputs.cycletime ? {time: parseFloat(inputs.cycletime)} : {}),
            ...(inputs.cycletimeRemarks ? {remarks: inputs.cycletimeRemarks} : {}),
          }} : {}),
        layout:{
          width: parseFloat(inputs.layoutWidth),
          depth: parseFloat(inputs.layoutDepth),
          height: parseFloat(inputs.layoutHeight),
        }
      },
    }
  };
}

export function makeFormSystemFromDocument(
  info:SystemDocument
):FormSystemAttr{
  return {
    systemId:  info.attr.systemId,
    registrationDate: info.attr.registrationDate?new Date(info.attr.registrationDate).toLocaleString():"",
    registrant: info.attr.registrant,
    domain: String(info.attr.domain),
    public: info.attr.public,
    title: info.attr.basicInfo.title,
    sier: info.attr.basicInfo.sier,
    industry: info.attr.basicInfo.industry,
    application: info.attr.basicInfo.application,
    overview: info.attr.basicInfo.overview,
    background: info.attr.description.background ? info.attr.description.background : "",
    result: info.attr.description.result ? info.attr.description.result : "",
    problem: info.attr.description.problem ? info.attr.description.problem : [],
    solution: info.attr.description.solution ? info.attr.description.solution : [],
    feature: info.attr.description.feature,
    carryIn: info.attr.description.carryIn ? info.attr.description.carryIn : "",
    carryOut: info.attr.description.carryOut ? info.attr.description.carryOut : "",
    workDetection: info.attr.description.workDetection ? info.attr.description.workDetection : "",
    sequence: info.attr.description.sequence,
    cycletime: info.attr.description.cycletime?.time ? String(info.attr.description.cycletime?.time) : "",
    cycletimeRemarks: info.attr.description.cycletime?.remarks ? info.attr.description.cycletime?.remarks : "",
    layoutWidth: String(info.attr.description.layout.width),
    layoutDepth: String(info.attr.description.layout.depth),
    layoutHeight: String(info.attr.description.layout.height),
    workInfos : info.attr.works.workInfos && info.attr.works.workInfos.length > 0 ?
      info.attr.works.workInfos.map(row=>({
        classId: row["class-id"] ? row["class-id"] : "",
        nameJp: row.nameJp ? row.nameJp : "",
        number: String(row.number),
        widthMin: row.width?.min !== undefined ? String(row.width?.min) : "",
        widthMax: row.width?.max !== undefined ? String(row.width?.max) : "",
        depthMin: row.depth?.min !== undefined ? String(row.depth?.min) : "",
        depthMax: row.depth?.max !== undefined ? String(row.depth?.max) : "",
        heightMin: row.height?.min !== undefined ? String(row.height?.min) : "",
        heightMax: row.height?.max !== undefined ? String(row.height?.max) : "",
        massMin: row.mass?.min !== undefined ? String(row.mass?.min) : "",
        massMax: row.mass?.max !== undefined ? String(row.mass?.max) : "",
      })) : [],
    armInfos: info.attr.parts.armInfos && info.attr.parts.armInfos.length > 0 ?
      info.attr.parts.armInfos.map(row=>({
        armId: row.armId ? row.armId : "",
        name: row.armInfo?.name? row.armInfo?.name : "",
        manufacturer: row.armInfo?.manufacturer ? row.armInfo?.manufacturer : "",
        number: String(row.number),
        description: row.description,
        structure: row.armInfo?.structure ? row.armInfo?.structure : "",
        cobot: row.armInfo?.cobot ? row.armInfo?.cobot : false,
        numberOfAxes: row.armInfo?.numberOfAxes !== undefined ? String(row.armInfo?.numberOfAxes) : "",
        payload: row.armPerformance?.payload !== undefined ? String(row.armPerformance?.payload) : "",
        maximumReach: row.armPerformance?.reach.maximumReach !== undefined ? String(row.armPerformance?.reach.maximumReach) : "",
      })) : [],
    handInfos: info.attr.parts.handInfos && info.attr.parts.handInfos.length > 0 ?
      info.attr.parts.handInfos.map(row=>({
        handId: row.handId ? row.handId : "",
        name: row.handInfo?.name ? row.handInfo?.name : "",
        producer: row.handInfo?.producer ? row.handInfo?.producer : "",
        number: String(row.number),
        description: row.description,
      })) : [],
    file: null,
    zipURI: "",
  }
}
// --------------------------------------------------------------------------------------
export function makeSystemAttrList(
  info:SystemDocument
):AttrListRowType[]{
  const attrList:AttrListRowType[] = [];
  attrList.push({key:"public", class:"基本情報", attr:"公開可否", value:info.attr.public?"公開事例":"非公開事例"});
  attrList.push({key:"title", class:"", attr:"事例名称", value:info.attr.basicInfo.title});
  attrList.push({key:"sier", class:"", attr:"製作者", value:info.attr.basicInfo.sier});
  attrList.push({key:"overview", class:"", attr:"概要", value:String(info.attr.basicInfo.overview.replace(/\n/g,'<br>'))});
  if(Array.isArray(info.attr.basicInfo.industry)){
    attrList.push({key:"industry", class:"", attr:"産業分野", value:
      info.attr.basicInfo.industry.map((ids)=>(
        SYSTEM_INDUSTRY.find((row)=>(row.key===ids))?.label
      )).join("、")
    });
  }
  if(Array.isArray(info.attr.basicInfo.application)){
    attrList.push({key:"application", class:"", attr:"用途", value:
      info.attr.basicInfo.application.map((apl)=>(
        SYSTEM_APPLICATION.find((row)=>(row.key===apl))?.label
      )).join("、")
    });
  }
  let divLabel = "説明";
  if(info.attr.description.background){
    attrList.push({key:"background", class: divLabel, attr:"背景・目的",
      value:String(info.attr.description.background.replace(/\n/g,'<br>'))});
    divLabel = "";
  }
  if(info.attr.description.result){
    attrList.push({key:"result", class: divLabel, attr:"効果",
      value:String(info.attr.description.result.replace(/\n/g,'<br>'))});
    divLabel = "";
  }
  if(info.attr.description.problem){
    attrList.push({key:"problem", class: divLabel, attr:"課題",
      value: info.attr.description.problem.map((row)=>(
        row.replace(/\n/g,'<br>')
      )).join("<br>")});
    divLabel = "";
  }
  if(info.attr.description.solution){
    attrList.push({key:"solution", class: divLabel, attr:"解決策",
      value: info.attr.description.solution.map((row)=>(
        row.replace(/\n/g,'<br>')
      )).join("<br>")});
    divLabel = "";
  }
  if(info.attr.description.feature){
    attrList.push({key:"feature", class: divLabel, attr:"工夫点",
      value: info.attr.description.feature.map((row)=>(
        row.replace(/\n/g,'<br>')
      )).join("<br>")});
    divLabel = "";
  }
  if(info.attr.description.carryIn){
    attrList.push({key:"carryIn", class: divLabel, attr:"搬入方法",
      value:String(info.attr.description.carryIn.replace(/\n/g,'<br>'))});
    divLabel = "";
  }
  if(info.attr.description.carryOut){
    attrList.push({key:"carryOut", class: divLabel, attr:"搬出方法",
      value:String(info.attr.description.carryOut.replace(/\n/g,'<br>'))});
    divLabel = "";
  }
  if(info.attr.description.workDetection){
    attrList.push({key:"workDetection", class: divLabel, attr:"判別方法",
      value:String(info.attr.description.workDetection.replace(/\n/g,'<br>'))});
    divLabel = "";
  }
  if(info.attr.description.sequence){
    attrList.push({key:"sequence", class: divLabel, attr:"動作の流れ",
      value: info.attr.description.sequence.map((row)=>(
        row.replace(/\n/g,'<br>')
      )).join("<br>")});
    divLabel = "";
  }
  if(info.attr.description.cycletime?.time){
    attrList.push({key:"cycletime", class: divLabel, attr:"1サイクル時間",
      value : `${String(info.attr.description.cycletime?.time.toLocaleString())} s` });
    divLabel = "";
  }
  if(info.attr.description.cycletime?.remarks){
    attrList.push({key:"cycletimeRemarks", class: divLabel, attr:"動作条件補足",
      value:String(info.attr.description.cycletime?.remarks.replace(/\n/g,'<br>'))});
    divLabel = "";
  }
  if(info.attr.description.layout?.width){
    attrList.push({key:"layoutWidth", class: divLabel, attr:"設備寸法(幅)",
      value : `${String(info.attr.description.layout.width.toLocaleString())} mm` });
    divLabel = "";
  }
  if(info.attr.description.layout?.depth){
    attrList.push({key:"layoutDepth", class: divLabel, attr:"設備寸法(奥行)",
      value : `${String(info.attr.description.layout.depth.toLocaleString())} mm` });
    divLabel = "";
  }
  if(info.attr.description.layout?.height){
    attrList.push({key:"layoutHeight", class: divLabel, attr:"設備寸法(高さ)",
      value : `${String(info.attr.description.layout.height.toLocaleString())} mm` });
    divLabel = "";
  }
  return attrList;
}
export function makeWorkAttrList(
  info:WorkInfos|undefined
):AttrListRowType[]{
  const attrList:AttrListRowType[] = [];
  if(info===undefined) return attrList;
  attrList.push({key:"nameJp", class:"", attr:"商品/部品名", value:info.nameJp ? info.nameJp : ""});
  attrList.push({key:"number", class:"", attr:"品種ごとの種類数", value:String(info.number.toLocaleString())});
  if(info.width?.min !== undefined) attrList.push({key:"widthMin", class:"", attr:"幅(最小値)", value: `${String(info.width?.min.toLocaleString())} mm`});
  if(info.width?.max !== undefined) attrList.push({key:"widthMax", class:"", attr:"幅(最大値)", value: `${String(info.width?.max.toLocaleString())} mm`});
  if(info.depth?.min !== undefined) attrList.push({key:"depthMin", class:"", attr:"奥行(最小値)", value: `${String(info.depth?.min.toLocaleString())} mm`});
  if(info.depth?.max !== undefined) attrList.push({key:"depthMax", class:"", attr:"奥行(最大値)", value: `${String(info.depth?.max.toLocaleString())} mm`});
  if(info.height?.min !== undefined) attrList.push({key:"heightMin", class:"", attr:"高さ(最小値)", value: `${String(info.height?.min.toLocaleString())} mm`});
  if(info.height?.max !== undefined) attrList.push({key:"heightMax", class:"", attr:"高さ(最大値)", value: `${String(info.height?.max.toLocaleString())} mm`});
  if(info.mass?.min !== undefined) attrList.push({key:"massMin", class:"", attr:"質量(最小値)", value: `${String(info.mass?.min.toLocaleString())} kg`});
  if(info.mass?.max !== undefined) attrList.push({key:"massMax", class:"", attr:"質量(最大値)", value: `${String(info.mass?.max.toLocaleString())} kg`});
  return attrList;
}
export function makeArmAttrList(
  info:ArmInfos|undefined
):AttrListRowType[]{
  const attrList:AttrListRowType[] = [];
  if(info===undefined) return attrList;
  attrList.push({key:"name", class:"", attr:"製品名", value:info.armInfo?.name ? info.armInfo?.name : ""});
  attrList.push({key:"manufacturer", class:"", attr:"製造者", value:info.armInfo?.manufacturer ? info.armInfo?.manufacturer : ""});
  attrList.push({key:"number", class:"", attr:"使用台数", value: String(info.number.toLocaleString()) });
  if(info.description){
    attrList.push({key:"description", class: "", attr:"特記事項",
      value: info.description.map((row)=>(
        row.replace(/\n/g,'<br>')
      )).join("<br>")});
  }
  if(info.armInfo?.structure){
    attrList.push({key:"structure", class:"", attr:"アーム構造", value: String(ARM_STRUCTURE.find((row)=>(row.key===info.armInfo?.structure))?.label) });
  }
  if(info.armInfo?.cobot !== undefined){
    attrList.push({key:"cobot", class:"", attr:"協働か否か", value: info.armInfo?.cobot ? "協働ロボット" : "非協働ロボット" });
  }
  if(info.armInfo?.numberOfAxes !== undefined){
    attrList.push({key:"numberOfAxes", class:"", attr:"制御軸数", value: String(info.armInfo?.numberOfAxes.toLocaleString()) });
  }
  if(info.armPerformance?.payload !== undefined){
    attrList.push({key:"payload", class:"", attr:"可搬質量", value: `${String(info.armPerformance?.payload.toLocaleString())} kg` });
  }
  if(info.armPerformance?.reach.maximumReach !== undefined){
    attrList.push({key:"maximumReach", class:"", attr:"最大リーチ", value: `${String(info.armPerformance?.reach.maximumReach.toLocaleString())} mm` });
  }
  return attrList;
}
export function makeHandAttrList(
  info:HandInfos|undefined
):AttrListRowType[]{
  const attrList:AttrListRowType[] = [];
  if(info===undefined) return attrList;
  attrList.push({key:"name", class:"", attr:"製品名", value:info.handInfo?.name ? info.handInfo?.name : ""});
  attrList.push({key:"producer", class:"", attr:"製造者", value:info.handInfo?.producer ? info.handInfo?.producer : ""});
  attrList.push({key:"number", class:"", attr:"使用台数", value: String(info.number.toLocaleString()) });
  if(info.description){
    attrList.push({key:"description", class: "", attr:"特記事項",
      value: info.description.map((row)=>(
        row.replace(/\n/g,'<br>')
      )).join("<br>")});
  }
  return attrList;
}