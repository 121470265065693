/* eslint-disable no-void */
import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import {
  Typography,
  Container,
  Box,
  Card,
  Paper,
  TableContainer,Table,TableHead,TableRow,TableCell,TableBody
} from '@mui/material/';
import Grid from '@mui/material/Grid2';
import { ROLE_KEYS, useUserRole } from '../../../utils/awsConfigure';
import useNotification from "../../../utils/notificationUtil";
import useConfirmDialog from '../../../components/ConfirmDialog'
import { SYSTEM_URLS, EQUIPMENT_URLS, WORK_URLS } from '../../../constants/constants'
import { DataStoreContext, CONTEXT_ACTION_TYPE, initialState } from "../contexts/SystemContext";

import { ResponseType, deleteSystem, findWork, findHand, findArm, findSystem} from '../../../utils/awsAmplifyUtil'
import { SystemDocument, systemInitDoc, makeSystemAttrList, makeFormSystemFromDocument, makeWorkAttrList, makeArmAttrList, makeHandAttrList } from '../types/pj1dbApiSystem';
import { ArmDocument } from '../../equipmentdb/types/pj1dbApiArm';
import { HandDocument } from '../../equipmentdb/types/pj1dbApiHand';
import {WorkClassDocument} from "../../workdb/types/pj1dbApiWork";
import { AttrListRowType, WorkInfoMore, ArmInfoMore, HandInfoMore } from '../types/systemForm';
import pickImageUrl from "../../workdb/utils/pickImageUrl";

import Pjdb1RadioGroup from '../../../components/inputs/Pjdb1RadioGroup';
import Pjdb1Button from '../../../components/inputs/Pjdb1Button';
import UrdfLoader from '../../../components/UrdfLoader';

export default function SystemDetail() {
  const { userRole, userDomain } = useUserRole();
  const { deletedMsg ,errorMsg } = useNotification();
  const {ConfirmDialog, openConfirmDialog} = useConfirmDialog();

  const { state, dispatch } = useContext(DataStoreContext);
  const [isLoading, setIsLoading] = useState(false);

  const [info, setInfo] = useState<SystemDocument>(systemInitDoc);
  const [systemAttrList, setSystemAttrList] = useState<AttrListRowType[]>([]);
  const [dispImageType, setDispImageType] = useState<string>("image");

  interface ImageListRowType {
    key:string;
    url:string;
  }

  const [systemImageList, setSystemImageList] = useState<ImageListRowType[]>([]);
  const [selectedSystemImageKey, setSelectedSystemImageKey] = useState<string>("");

  const [workList, setWorkList] = useState<WorkInfoMore[]>([]);
  const [armList, setArmList] = useState<ArmInfoMore[]>([]);
  const [handList, setHandList] = useState<HandInfoMore[]>([]);

  const [workAttrList, setWorkAttrList] = useState<AttrListRowType[]>([]);
  const [armAttrList, setArmAttrList] = useState<AttrListRowType[]>([]);
  const [handAttrList, setHandAttrList] = useState<AttrListRowType[]>([]);

  const navigate = useNavigate();
  const urlParams = useParams<{ systemId: string }>();

  const imageStyle = {
    border : "1px solid rgba(0, 0, 0, 0.23)",
  }
  const selectedImageStyle = {
    border : "2px solid #48B130",
  }

  const findSystemProc = async (systemId:string) => {
    try {
      const result = await findSystem({"query":{"systemId":systemId}});
      if(!result.isSuccess) return;
      let systemListData:SystemDocument[] = [];
      if(result.data && Object.entries(result.data).length > 0){
        systemListData = Object.entries(result.data).map(([,systemDoc])=>(systemDoc));
      }
      if(systemListData.length===0){
        dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: SYSTEM_URLS.SYSTEM_LIST.TAB_IDX });
        navigate(`${SYSTEM_URLS.SYSTEM_LIST.URL}`);
      }else{
        setInfo(systemListData[0]);
        dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: SYSTEM_URLS.SYSTEM_DETAIL.TAB_IDX });
        dispatch({ type: CONTEXT_ACTION_TYPE.SYSTEM_DETAIL , payload: {info: systemListData[0]} });
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(()=>{
    let tempInfo:SystemDocument;
    if(urlParams.systemId===state.systemDetailPage.info.attr.systemId){
      tempInfo = state.systemDetailPage.info;
    }else if (!urlParams.systemId){
      tempInfo = state.systemDetailPage.info;
    }else{
      [tempInfo] =
        state.systemListPage.systemList.filter(
          (item)=>item.attr.systemId === urlParams.systemId
        );
    }
    if(tempInfo!==undefined){
      setInfo(tempInfo);
      return;
    }

    // 別機能からの遷移、情報検索して表示する
    if (urlParams.systemId){
      void findSystemProc(urlParams.systemId);
    }else{
      dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: SYSTEM_URLS.SYSTEM_LIST.TAB_IDX });
      navigate(`${SYSTEM_URLS.SYSTEM_LIST.URL}`);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const findWorkProc = async () => {
    let workListData:WorkClassDocument[] = [];
    const classIds = info.attr.works.workInfos?.filter(row=>row['class-id']).map(row=>row['class-id']);
    if(classIds && classIds?.length>0){
      const params = {
        version: "work-user",
        queryAttr: {"class-id":{ "$in" : classIds }},
      };
      const result = await findWork('class', params);
      if(!result.isSuccess) return;
      workListData =result.data ? Object.values(result.data.items) : [];
    }
    const tmpWorkList:WorkInfoMore[] = [];
    info.attr.works.workInfos?.forEach((row,idx) => {
      const tmpDataRow = workListData.find(data=>data.attr['class-id']===row['class-id']);
      let tmpImgUrl = "";
      if(tmpDataRow!==undefined){
        tmpImgUrl = pickImageUrl(tmpDataRow);
      }
      tmpWorkList.push({
        index: idx,
        id : row['class-id'] ? row['class-id'] : "",
        imgUrl : tmpImgUrl,
        isSelected : false,
        docAttr : row
      });
    });
    setWorkList(tmpWorkList);
    // setWorkAttrList(makeWorkAttrList(tmpWorkList.find(row=>row.isSelected)?.docAttr));
  }
  const findArmProc = async () => {
    let armListData:ArmDocument[] = [];
    const armIds = info.attr.parts.armInfos?.filter(row=>row.armId).map(row=>row.armId);
    if(armIds && armIds?.length>0){
      const cond = {
        "query":{
          "armId":{ "$in" : armIds }
        }
      };
      const result = await findArm(cond);
      if(!result.isSuccess) return;
      if(result.data && Object.entries(result.data).length > 0){
        armListData = Object.entries(result.data).map(([,armDoc])=>(armDoc));
      }
    }
    const tmpArmList:ArmInfoMore[] = [];
    info.attr.parts.armInfos?.forEach((row,idx) => {
      tmpArmList.push({
        index: idx,
        id : row.armId ? row.armId : "",
        imgUrl : armListData.find(data=>data.attr.armId===row.armId)?.url?.image[0],
        isSelected : false,
        docAttr : row
      });
    });
    setArmList(tmpArmList);
    // setArmAttrList(makeArmAttrList(tmpArmList.find(row=>row.isSelected)?.docAttr));
  }
  const findHandProc = async () => {
    let handListData:HandDocument[] = [];
    const handIds = info.attr.parts.handInfos?.filter(row=>row.handId).map(row=>row.handId);
    if(handIds && handIds?.length>0){
      const cond = {
        "query":{
          "handId":{ "$in" : handIds }
        }
      };
      const result = await findHand(cond);
      if(!result.isSuccess) return;
      if(result.data && Object.entries(result.data).length > 0){
        handListData = Object.entries(result.data).map(([,handDoc])=>(handDoc));
      }
    }
    const tmpHandList:HandInfoMore[] = [];
    info.attr.parts.handInfos?.forEach((row,idx) => {
      // findSystem_syncにてhandInfo取得未対応のため、必要情報をマッピング
      const dataRow = row;
      if(!dataRow.handInfo){
        const newRow = handListData.find(data=>data.attr.handId===row.handId);
        if(newRow){
          dataRow.handInfo = {
            name: newRow.attr.handInfo.name,
            producer: newRow.attr.handInfo.producer
          };
        }
      }
      tmpHandList.push({
        index: idx,
        id : row.handId ? row.handId : "",
        imgUrl : handListData.find(data=>data.attr.handId===row.handId)?.url?.image[0],
        isSelected : false,
        docAttr : dataRow
      });
    });
    setHandList(tmpHandList);
    // setHandAttrList(makeHandAttrList(tmpHandList.find(row=>row.isSelected)?.docAttr));
  }

  useEffect(() => {
    if(info.attr.systemId==="") return;

    // システム事例属性表
    setSystemAttrList(makeSystemAttrList(info));

    // 画像リストを作成
    if(info.url){
      setSystemImageList(
        info.url?.image.map((imageUrl,idx)=>(
          {key:`image${idx}`,url:imageUrl}
        ))
      );
      setSelectedSystemImageKey("image0");
    }

    void findWorkProc();
    void findArmProc();
    void findHandProc();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[info]);

  const deleteData = async () => {
    try {
      console.log("deleteData",info.attr.systemId);
      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      setIsLoading(true);

      const delData = {
        id:info.attr.systemId
      }
      const resDel:ResponseType<string> = await deleteSystem(delData);
      if(!resDel.isSuccess){
        errorMsg("削除処理が失敗しました");
        return;
      }

      deletedMsg();

      dispatch({ type: CONTEXT_ACTION_TYPE.SYSTEM_DETAIL ,
        payload: {info: initialState.systemDetailPage.info} });

      const newList = state.systemListPage.systemList.filter(row=>row.attr.systemId!==info.attr.systemId);
      const newState = state.systemListPage;
      newState.systemList = newList;
      newState.searchCond.listCount = newList.length;
      dispatch({ type: CONTEXT_ACTION_TYPE.SYSTEM_LIST , payload: newState });

      dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: SYSTEM_URLS.SYSTEM_LIST.TAB_IDX });
      navigate(`${SYSTEM_URLS.SYSTEM_LIST.URL}`);

    } catch (error) {
      console.log("deleteData Error : ", error);
      errorMsg("削除処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }
  const editData = () => {
    dispatch({ type: CONTEXT_ACTION_TYPE.REGIST ,
      payload: {...state.registPage,regMode:false,systemPanel:makeFormSystemFromDocument(info)} });
    dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: SYSTEM_URLS.REGISTE.TAB_IDX });
    navigate(`${SYSTEM_URLS.REGISTE.URL}/edit/${info.attr.systemId}`);
  }
  const copyData = () => {
    dispatch({ type: CONTEXT_ACTION_TYPE.REGIST ,
      payload: {...state.registPage,regMode:true,systemPanel:makeFormSystemFromDocument(info)} });
    dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: SYSTEM_URLS.REGISTE.TAB_IDX });
    navigate(`${SYSTEM_URLS.REGISTE.URL}/copy/${info.attr.systemId}`);
  }

  return (
    <>
      <Container maxWidth="lg" sx={{maxWidth:"1500px !important"}}>
        <Card sx={{p:3,mt:2}}>

          <Grid container spacing={3} alignItems="flex-end" >
            <Grid>
              <Typography variant="h6">事例詳細</Typography>
            </Grid>
            <Grid sx={{flexGrow: 1}}>
              <Typography variant="body2" sx={{color:"gray"}}>
                登録日時（登録者）：
                  {`${info.attr?.registrationDate?new Date(info.attr?.registrationDate).toLocaleString():""}
                  （${userRole === ROLE_KEYS.ADMIN ? String(info.attr.registrant) : String(info.attr.domain)}）`}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3} alignItems="flex-start" sx={{mt:1,mb:2}}>
            <Grid size={{xs:12,md:7}} sx={{pt:"0px !important"}}>

              <Pjdb1RadioGroup
                name="dispImageType" value={dispImageType}
                onChange={(e)=>(setDispImageType(e.target.value))}
                radios={[
                  {btnKey:"image",btnLbl:"画像"},
                  {btnKey:"model",btnLbl:"モデル"},
                ]}
                disabledRadios={info.url?.urdf && info.url?.urdf.length > 0 ? [] : ["model"]}
              />

              <Grid container spacing={2} alignItems="flex-start" sx={{mt:0}}>
                { dispImageType === "image" &&
                  <>
                    <Grid size={{xs:3,md:2}}
                    sx={{pt:"0px !important",pr:"1px !important",height:"50vw",maxHeight:"70vh",overflowY:"auto",overflowX:"hidden"}}
                    className="thin-scroll-bar">
                        {systemImageList.map(row =>(
                          <Box key={row.key}>
                            <a href="" onClick={(e)=>{
                              e.preventDefault();
                              setSelectedSystemImageKey(row.key);
                            }}>
                              <img src={row.url} className="square-image" style={row.key === selectedSystemImageKey ? selectedImageStyle : imageStyle} alt={row.key}/>
                            </a>
                          </Box>
                        ))}
                    </Grid>
                    <Grid size={{xs:9,md:10}} sx={{pt:"0px !important"}}>
                      <img src={systemImageList.find((row)=>(row.key===selectedSystemImageKey))?.url} style={imageStyle} className="square-image" alt="" />
                    </Grid>
                  </>
                }
                { dispImageType === "model" &&
                  <Grid sx={{p:"0px !important",border:"1px solid rgba(0, 0, 0, 0.23)"}} className="square-image">
                    <UrdfLoader urdfUrl={String(info.url?.urdf[0])} />
                  </Grid>
                }
              </Grid>

            </Grid>
            <Grid size={{xs:12,md:5}} sx={{pt:"20px !important"}}>

              <Typography variant="subtitle1">事例属性表</Typography>
              <TableContainer component={Paper}>
                <Table sx={{ maxWidth: "100%" }} size="small" aria-label="a dense table">
                  <TableHead sx={{bgcolor:"#EEE"}}>
                    <TableRow>
                      <TableCell sx={{px:1,py:0.5}}>分類</TableCell>
                      <TableCell sx={{px:1,py:0.5}}>属性</TableCell>
                      <TableCell sx={{px:1,py:0.5}}>属性値</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {systemAttrList.map((row) => (
                      <TableRow
                        key={row.key}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell width={130} sx={{px:1,py:0.5}}>{row.class}</TableCell>
                        <TableCell width={140} sx={{px:1,py:0.5}} dangerouslySetInnerHTML={{ __html: String(row.attr) }} />
                        <TableCell sx={{px:1,py:0.5}} dangerouslySetInnerHTML={{ __html: String(row.value) }} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>
          </Grid>

          { info.attr.works.workInfos &&
            <>
              <Grid container spacing={3} alignItems="flex-end" sx={{mt:1}}>
                <Grid sx={{pt:"0px !important"}}>
                  <Typography variant="h6">商品/部品</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="flex-start" sx={{mt:1}}>
                <Grid size={{xs:12,md:7}} sx={{pt:"0px !important"}}>
                  <Box sx={{ml:2,mt:1}}>
                    <Grid container spacing={2} alignItems="flex-start" sx={{mt:0,pl:0}}>
                      {workList.map(row =>(
                        <Grid key={row.index} size={{xs:3}} sx={{pt:"0px !important"}}>
                          <a href="" style={{ textDecoration:'none' }} onClick={(e)=>{
                            e.preventDefault();
                            setWorkList(workList.map(row2=>({...row2, isSelected:row2.index===row.index})));
                            setWorkAttrList(makeWorkAttrList(workList.find(row2=>row2.index===row.index)?.docAttr));
                          }}>
                            {row.imgUrl &&
                              <img src={row.imgUrl} className="square-image" style={row.isSelected ? selectedImageStyle : imageStyle} alt={row.docAttr.nameJp}/>
                            }
                            {!row.imgUrl &&
                              <Box className="square-image" sx={{ display:"flex", justifyContent:"center", alignItems: "center"}}
                                style={row.isSelected ? selectedImageStyle : imageStyle}>
                                <Box sx={{textAlign:"center"}}>
                                  <Typography variant="subtitle2" sx={{color:'text.primary', display: 'block'}}>
                                    {row.docAttr.nameJp}
                                  </Typography>
                                  <Typography variant="subtitle2" noWrap component='p' sx={{color:"#888"}}>
                                    DB未登録
                                  </Typography>
                                </Box>
                              </Box>
                            }
                          </a>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
                <Grid size={{xs:12,md:5}}>
                  { workList.find(row=>row.isSelected) &&
                    <>
                      <Typography variant="subtitle1">商品/部品属性表</Typography>
                      <TableContainer component={Paper}>
                        <Table sx={{ maxWidth: "100%" }} size="small" aria-label="a dense table">
                          <TableHead sx={{bgcolor:"#EEE"}}>
                            <TableRow>
                              <TableCell sx={{px:1,py:0.5}}>属性</TableCell>
                              <TableCell sx={{px:1,py:0.5}}>属性値</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {workAttrList.map((row) => (
                              <TableRow
                                key={row.key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell width={140} sx={{px:1,py:0.5}}>{row.attr}</TableCell>
                                <TableCell sx={{px:1,py:0.5}} dangerouslySetInnerHTML={{ __html: String(row.value) }} />
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box sx={{mt:2}}>
                        <Pjdb1Button
                          label="選択を解除"
                          variant="contained"
                          color="inherit"
                          onClick={()=>{
                            setWorkList(workList.map(row2=>({...row2, isSelected:false})));
                            setWorkAttrList([]);
                          }}
                        />
                        { workList.find(row=>row.isSelected)?.id &&
                          <Pjdb1Button
                            label="詳細へ"
                            variant="contained"
                            color="primary"
                            sx={{ml:2}}
                            onClick={()=>{
                              const sId = workList.find(row=>row.isSelected)?.id;
                              if(sId) navigate(`${WORK_URLS.WORK_DETAIL.URL}/${sId}`);
                            }}
                          />
                        }
                      </Box>
                    </>
                  }
                </Grid>
              </Grid>
            </>
          }

          { info.attr.parts.armInfos &&
            <>
              <Grid container spacing={3} alignItems="flex-end" sx={{mt:1}}>
                <Grid sx={{pt:"0px !important"}}>
                  <Typography variant="h6">アーム</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="flex-start" sx={{mt:1}}>
                <Grid size={{xs:12,md:7}} sx={{pt:"0px !important"}}>
                  <Box sx={{ml:2,mt:1}}>
                    <Grid container spacing={2} alignItems="flex-start" sx={{mt:0,pl:0}}>
                      {armList.map(row =>(
                        <Grid key={row.index} size={{xs:3}} sx={{pt:"0px !important"}}>
                          <a href="" style={{ textDecoration:'none' }} onClick={(e)=>{
                            e.preventDefault();
                            setArmList(armList.map(row2=>({...row2, isSelected:row2.index===row.index})));
                            setArmAttrList(makeArmAttrList(armList.find(row2=>row2.index===row.index)?.docAttr));
                          }}>
                            {row.imgUrl &&
                              <img src={row.imgUrl} className="square-image" style={row.isSelected ? selectedImageStyle : imageStyle} alt={row.docAttr.armInfo?.name}/>
                            }
                            {!row.imgUrl &&
                              <Box className="square-image" sx={{ display:"flex", justifyContent:"center", alignItems: "center"}}
                                style={row.isSelected ? selectedImageStyle : imageStyle}>
                                <Box sx={{textAlign:"center"}}>
                                  <Typography variant="subtitle2" sx={{color:'text.primary', display: 'block'}}>
                                    {row.docAttr.armInfo?.name}
                                  </Typography>
                                  <Typography variant="subtitle2" noWrap component='p' sx={{color:"#888"}}>
                                    DB未登録
                                  </Typography>
                                </Box>
                              </Box>
                            }
                          </a>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
                <Grid size={{xs:12,md:5}}>
                  { armList.find(row=>row.isSelected) &&
                    <>
                      <Typography variant="subtitle1">アーム属性表</Typography>
                      <TableContainer component={Paper}>
                        <Table sx={{ maxWidth: "100%" }} size="small" aria-label="a dense table">
                          <TableHead sx={{bgcolor:"#EEE"}}>
                            <TableRow>
                              <TableCell sx={{px:1,py:0.5}}>属性</TableCell>
                              <TableCell sx={{px:1,py:0.5}}>属性値</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {armAttrList.map((row) => (
                              <TableRow
                                key={row.key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell width={140} sx={{px:1,py:0.5}}>{row.attr}</TableCell>
                                <TableCell sx={{px:1,py:0.5}} dangerouslySetInnerHTML={{ __html: String(row.value) }} />
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box sx={{mt:2}}>
                        <Pjdb1Button
                          label="選択を解除"
                          variant="contained"
                          color="inherit"
                          onClick={()=>{
                            setArmList(armList.map(row2=>({...row2, isSelected:false})));
                            setArmAttrList([]);
                          }}
                        />
                        { armList.find(row=>row.isSelected)?.id &&
                          <Pjdb1Button
                            label="詳細へ"
                            variant="contained"
                            color="primary"
                            sx={{ml:2}}
                            onClick={()=>{
                              const sId = armList.find(row=>row.isSelected)?.id;
                              if(sId) navigate(`${EQUIPMENT_URLS.ARM_DETAIL.URL}/${sId}`);
                            }}
                          />
                        }
                      </Box>
                    </>
                  }
                </Grid>
              </Grid>
            </>
          }

          { info.attr.parts.handInfos &&
            <>
              <Grid container spacing={3} alignItems="flex-end" sx={{mt:1}}>
                <Grid sx={{pt:"0px !important"}}>
                  <Typography variant="h6">ハンド</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="flex-start" sx={{mt:1}}>
                <Grid size={{xs:12,md:7}} sx={{pt:"0px !important"}}>
                  <Box sx={{ml:2,mt:1}}>
                    <Grid container spacing={2} alignItems="flex-start" sx={{mt:0,pl:0}}>
                      {handList.map(row =>(
                        <Grid key={row.index} size={{xs:3}} sx={{pt:"0px !important"}}>
                          <a href="" style={{ textDecoration:'none' }} onClick={(e)=>{
                            e.preventDefault();
                            setHandList(handList.map(row2=>({...row2, isSelected:row2.index===row.index})));
                            setHandAttrList(makeHandAttrList(handList.find(row2=>row2.index===row.index)?.docAttr));
                          }}>
                            {row.imgUrl &&
                              <img src={row.imgUrl} className="square-image" style={row.isSelected ? selectedImageStyle : imageStyle} alt={row.docAttr.handInfo?.name}/>
                            }
                            {!row.imgUrl &&
                              <Box className="square-image" sx={{ display:"flex", justifyContent:"center", alignItems: "center"}}
                                style={row.isSelected ? selectedImageStyle : imageStyle}>
                                <Box sx={{textAlign:"center"}}>
                                  <Typography variant="subtitle2" sx={{color:'text.primary', display: 'block'}}>
                                    {row.docAttr.handInfo?.name}
                                  </Typography>
                                  <Typography variant="subtitle2" noWrap component='p' sx={{color:"#888"}}>
                                    DB未登録
                                  </Typography>
                                </Box>
                              </Box>
                            }
                          </a>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
                <Grid size={{xs:12,md:5}}>
                  { handList.find(row=>row.isSelected) &&
                    <>
                      <Typography variant="subtitle1">ハンド属性表</Typography>
                      <TableContainer component={Paper}>
                        <Table sx={{ maxWidth: "100%" }} size="small" aria-label="a dense table">
                          <TableHead sx={{bgcolor:"#EEE"}}>
                            <TableRow>
                              <TableCell sx={{px:1,py:0.5}}>属性</TableCell>
                              <TableCell sx={{px:1,py:0.5}}>属性値</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {handAttrList.map((row) => (
                              <TableRow
                                key={row.key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell width={140} sx={{px:1,py:0.5}}>{row.attr}</TableCell>
                                <TableCell sx={{px:1,py:0.5}} dangerouslySetInnerHTML={{ __html: String(row.value) }} />
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box sx={{mt:2}}>
                        <Pjdb1Button
                          label="選択を解除"
                          variant="contained"
                          color="inherit"
                          onClick={()=>{
                            setHandList(handList.map(row2=>({...row2, isSelected:false})));
                            setHandAttrList([]);
                          }}
                        />
                        { handList.find(row=>row.isSelected)?.id &&
                          <Pjdb1Button
                            label="詳細へ"
                            variant="contained"
                            color="primary"
                            sx={{ml:2}}
                            onClick={()=>{
                              const sId = handList.find(row=>row.isSelected)?.id;
                              if(sId) navigate(`${EQUIPMENT_URLS.HAND_DETAIL.URL}/${sId}`);
                            }}
                          />
                        }
                      </Box>
                    </>
                  }
                </Grid>
              </Grid>
            </>
          }

        </Card>
      </Container>

      <Box component="section" sx={{ textAlign:"center", mt:2 }}>
        { (userRole === ROLE_KEYS.ADMIN || userDomain===info.attr.domain) &&
          <Pjdb1Button
            label="登録情報を変更"
            variant="contained"
            color="primary"
            isLoading={isLoading}
            sx={{m:1}}
            onClick={editData}
            disabled={info.attr.systemId===""}
          />
        }

        { (userRole === ROLE_KEYS.ADMIN || userDomain===info.attr.domain) &&
          <Pjdb1Button
            label="登録情報を削除"
            variant="contained"
            color="error"
            isLoading={isLoading}
            sx={{m:1}}
            onClick={()=>{void deleteData();}}
            disabled={info.attr.systemId===""}
          />
        }

        <Pjdb1Button
          label="コピー登録"
          variant="contained"
          color="secondary"
          isLoading={isLoading}
          sx={{m:1}}
          onClick={copyData}
          disabled={info.attr.systemId===""}
        />

      </Box>

      <ConfirmDialog
        title="削除確認"
        message="事例情報を削除しますか？"
        confirmButton="削除"
        confirmButtonStyle="error"
      />
    </>
  )
}