import {
  Backdrop,
  BackdropProps,
  Box,
  CircularProgress,
  CircularProgressProps,
  LinearProgress,
  Typography
} from "@mui/material";
import {HAND_CANDIDATE_STATUS_PROGRESS, HandCandidateStatusType} from "../../../types/pj1dbApiHandling";

export type CalcHandLoadingProps = BackdropProps & {
  status: HandCandidateStatusType
}

export default function CalcHandLoading({status, ...props}: CalcHandLoadingProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Backdrop sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})} {...props}>
      <Box sx={{bgcolor: 'white', borderRadius: 2, p: 1}}>
        <Typography
          variant="h6"
          align='center'
          sx={{color: 'text.primary'}}
        >最適ハンド計算中</Typography>

        <Box p={2}/>
        <CircularProgressWithLabel value={HAND_CANDIDATE_STATUS_PROGRESS[status]}/>

        <Box p={2}/>
        <LinearProgress color='info' sx={{borderRadius: 2}}/>
      </Box>
    </Backdrop>
  );
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{position: 'relative', display: 'inline-flex', px: 16}}>
      <CircularProgress
        variant="determinate"
        color='info'
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{color: 'text.secondary'}}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
