import type { WorkInfos, ArmInfos, HandInfos } from "./pj1dbApiSystem";

/**
 * システム事例一覧検索条件
 */
export interface FormSystemSearchCond {
  keyword:string,
  industry:string[],
  application:string[],
  listCount:number,
  sortOrder:string,
}

/**
 * システム事例登録フォーム用タイプ
 */
export interface FormSystemWorkInfo {
  classId: string;
  nameJp: string;
  number: string;
  widthMin: string;
  widthMax: string;
  depthMin: string;
  depthMax: string;
  heightMin: string;
  heightMax: string;
  massMin: string;
  massMax: string;
  image?: string;
  selKey?: number;
}
export interface FormSystemArmInfo {
  armId: string;
  name: string;
  manufacturer: string;
  number: string;
  description: string[];
  structure: string;
  cobot: boolean;
  numberOfAxes: string;
  payload: string;
  maximumReach: string;
  image?: string;
  selKey?: number;
}
export interface FormSystemHandInfo {
  handId: string;
  name: string;
  producer: string;
  number: string;
  description: string[];
  image?: string;
  selKey?: number;
}

export interface FormSystemAttr {
  systemId: string;
  registrationDate: string;
  registrant: string;
  domain: string;
  public: boolean;
  title: string;
  sier: string;
  industry: string[];
  application: string[];
  overview: string;
  background: string;
  result: string;
  problem: string[];
  solution: string[];
  feature: string[];
  carryIn: string;
  carryOut: string;
  workDetection: string;
  sequence: string[];
  cycletime: string;
  cycletimeRemarks: string;
  layoutWidth: string;
  layoutDepth: string;
  layoutHeight: string;
  workInfos: FormSystemWorkInfo[];
  armInfos: FormSystemArmInfo[];
  handInfos: FormSystemHandInfo[];
  file: File|null;
  zipURI: string;
}

// システム事例登録フォーム 初期値
export const systemInitInputs:FormSystemAttr = {
  systemId: "",
  registrationDate: "",
  registrant: "",
  domain: "",
  public: false,
  title: "",
  sier: "",
  industry: [""],
  application: [""],
  overview: "",
  background: "",
  result: "",
  problem: [""],
  solution: [""],
  feature: [""],
  carryIn: "",
  carryOut: "",
  workDetection: "",
  sequence: [""],
  cycletime: "",
  cycletimeRemarks: "",
  layoutWidth: "",
  layoutDepth: "",
  layoutHeight: "",
  workInfos: [],
  armInfos: [],
  handInfos: [],
  file: null,
  zipURI: "",
}
export const systemWorkInitInputs:FormSystemWorkInfo = {
  classId: "",
  nameJp: "",
  number: "",
  widthMin: "",
  widthMax: "",
  depthMin: "",
  depthMax: "",
  heightMin: "",
  heightMax: "",
  massMin: "",
  massMax: "",
}
export const systemArmInitInputs:FormSystemArmInfo = {
  armId: "",
  name: "",
  manufacturer: "",
  number: "",
  description: [""],
  structure: "",
  cobot: false,
  numberOfAxes: "",
  payload: "",
  maximumReach: "",
}
export const systemHandInitInputs:FormSystemHandInfo = {
  handId: "",
  name: "",
  producer: "",
  number: "",
  description: [""],
}

/**
 * 属性表用タイプ
 */
export interface AttrListRowType {
  key: string;
  class: string;
  attr: string;
  value: string;
}

/**
 * システム事例詳細画面 ワーク、アーム、ハンド表示表
 */
export interface WorkInfoMore {
  index : number;
  id: string;
  imgUrl : string|undefined;
  isSelected : boolean;
  docAttr : WorkInfos;
}
export interface ArmInfoMore {
  index : number;
  id: string;
  imgUrl : string|undefined;
  isSelected : boolean;
  docAttr : ArmInfos;
}
export interface HandInfoMore {
  index : number;
  id: string;
  imgUrl : string|undefined;
  isSelected : boolean;
  docAttr : HandInfos;
}