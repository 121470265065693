/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useReducer, createContext } from "react";
import { SystemDocument, systemInitDoc } from '../types/pj1dbApiSystem'
import {
  FormSystemSearchCond,
  FormSystemAttr,
  systemInitInputs,
} from '../types/systemForm'

// useReducerで生成する「参照用のstate」の型
type DataStoreType = {
  tabIndex:number;
  systemListPage:{
    searchCond:FormSystemSearchCond;
    systemList:SystemDocument[];
  };
  systemDetailPage:{
    info:SystemDocument;
  };
  registPage:{
    regMode:boolean;
    systemPanel:FormSystemAttr;
  };
};
export const initialState: DataStoreType = {
  tabIndex: 0,
  systemListPage: {
    searchCond:{
      keyword:"",
      industry:[],
      application:[],
      listCount:0,
      sortOrder:"",
    },
    systemList:[],
  },
  systemDetailPage: {
    info: systemInitDoc,
  },
  registPage:{
    regMode:true,
    systemPanel: systemInitInputs,
  }
};

// dispatch関数の第2引数に渡す「action」の型
type ReducerActionType = {
  type: string;
  payload: any;
};
// createContext()のデフォルト値オブジェクトにasで割り当てる。
type DataStoreContextType = {
  state: DataStoreType;
  // dispatchの引数オブジェクトの型を、React.Dispatch<XXXXX> に定義する。
  dispatch: React.Dispatch<ReducerActionType>;
};

// reducer関数：更新用dispatchトリガーで、stateを更新する処理。
// 引数:   1.state 2.action(dispatch関数の引数)
// 戻り値: 更新後の新しいstate
export const CONTEXT_ACTION_TYPE = {
  TAB: "SET_TAB_INDEX",
  SYSTEM_LIST: "SET_SYSTEM_LIST_PAGE",
  SYSTEM_DETAIL: "SET_SYSTEM_DETAIL_PAGE",
  REGIST: "SET_REGIST_PAGE",
} as const;
const reducerFunc = (state: DataStoreType, action: ReducerActionType) => {
  // action.typeの値で更新内容を切り替える。
  switch (action.type) {
    case CONTEXT_ACTION_TYPE.TAB:
      return {
        ...state,
        tabIndex: action.payload,
      };
    case CONTEXT_ACTION_TYPE.SYSTEM_LIST:
      return {
        ...state,
        systemListPage: action.payload,
      };
    case CONTEXT_ACTION_TYPE.SYSTEM_DETAIL:
      return {
        ...state,
        systemDetailPage: action.payload,
      };
    case CONTEXT_ACTION_TYPE.REGIST:
      return {
        ...state,
        registPage: action.payload,
      };
    // 更新前のstateをそのまま返す。
    default:
      return state;
  }
};


// createContextはReactフックではないため、コンポーネント外で使用可能
// as でオブジェクトの型チェックをクリアする。
export const DataStoreContext = createContext({} as DataStoreContextType);

export function DataStoreContextProvider(props:any): JSX.Element {
  // useReducerで生成した「参照用state」と「更新用dispatch」を、contextに渡す。
  const [state, dispatch] = useReducer(reducerFunc, initialState);
  return (
    <DataStoreContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </DataStoreContext.Provider>
  );
}