import React, {useState} from "react";
import {Dict} from "../../../types/pj1dbApi";

export type NumberState = {
  name: string;

  value: number | undefined;
  setValue: React.Dispatch<React.SetStateAction<number | undefined>>;
  isChanged: () => boolean;
};

export type NumberListState = {
  nameList: string[];
  defaultValueList: number[];
  minmaxList?: MinMax[];

  reset: (newList: (number | undefined)[], minmaxList?: MinMax[]) => void;

  valueList: number[];
  setValueList: (valueList: number[]) => void;
  isChanged: () => boolean;
};

export type NumberDictState = {
  name: string;
  defaultValueDict: Dict<number>;

  reset: (newDict: Dict<number | undefined>) => void;
  getChangedKeys: () => string[];

  valueDict: Dict<number>;
  setValueDict: React.Dispatch<React.SetStateAction<Dict<number>>>;
};

export type BooleanState = {
  name: string;

  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

export type BooleanListState = {
  nameList: string[];

  checkedList: boolean[];
  setCheckedList: React.Dispatch<React.SetStateAction<boolean[]>>;
};

export type TextState = {
  name: string;
  type: "json" | "plain";
  allowedSymbols: string;
  regardBlankAsUndefined: boolean;

  text: string | undefined;
  setText: React.Dispatch<React.SetStateAction<string | undefined>>;

  getJson: () => object | string | number | boolean | undefined;
  getError: () => string | undefined;
  hasError: () => boolean;
  isChanged: () => boolean;
};

export function useNumberParam(
  name: string,
  defaultValue?: number
): NumberState {
  const [value, setValue] = useState<number | undefined>(defaultValue);
  return {
    name,
    value,
    setValue,
    isChanged: () => defaultValue !== value,
  };
}

export type MinMax = {
  min?: number;
  max?: number;
};

function arraysAreEqual(arr1: number[], arr2: number[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

export function useNumberListParam(
  nameList: string[],
  defaultValueList: (number | undefined)[],
  onChanged?: (valueList: number[]) => void,
  minmaxList?: MinMax[]
): NumberListState {
  const defaultValueListDefined = defaultValueList.map((value) =>
    typeof value === "undefined" ? NaN : value
  );
  const [valueList, setValueList] = useState<number[]>(defaultValueListDefined);
  const state = {
    nameList,
    defaultValueList: defaultValueListDefined,
    valueList,
    setValueList: (newValueList: number[]) => {
      setValueList(newValueList);
      if (onChanged) {
        onChanged(newValueList);
      }
    },
    minmaxList,
    reset: (newValueList: (number | undefined)[], newMinmaxList?: MinMax[]) => {
      const newValueListDefined = newValueList.map((value) =>
        typeof value === "undefined" ? NaN : value
      );

      state.defaultValueList = newValueListDefined;
      state.setValueList(newValueListDefined);

      if (newMinmaxList) {
        state.minmaxList = newMinmaxList;
      }
    },
    isChanged: () => !arraysAreEqual(defaultValueListDefined, valueList),
  };
  return state;
}

export function useNumberDictParam(
  name: string,
  defaultValueDict: Dict<number | undefined>
): NumberDictState {
  const defaultValueDictDefined = Object.fromEntries(
    Object.entries(defaultValueDict).map(([key, value]) => [
      key,
      typeof value === "undefined" ? NaN : value,
    ])
  );

  const [valueDict, setValueDict] = useState<Dict<number>>(
    defaultValueDictDefined
  );
  const state = {
    name,
    defaultValueDict: defaultValueDictDefined,
    valueDict,
    setValueDict,
    reset: (newDict: Dict<number | undefined>) => {
      const newDictDefined = Object.fromEntries(
        Object.entries(defaultValueDict).map(([key, value]) => [
          key,
          typeof value === "undefined" ? NaN : value,
        ])
      );
      state.defaultValueDict = newDictDefined;
      state.setValueDict(newDictDefined);
    },
    getChangedKeys: () =>
      Object.entries(defaultValueDict)
        .filter(([key, val]) => val !== valueDict[key])
        .map(([key, val]) => key),
  };
  return state;
}

export function useBooleanParam(
  name: string,
  defaultChecked: boolean
): BooleanState {
  const [checked, setChecked] = useState(defaultChecked);
  return {
    name,
    checked,
    setChecked,
  };
}

export function useBooleanListParam(
  nameList: string[],
  defaultChecked: boolean[]
): BooleanListState {
  const [checkedList, setCheckedList] = useState<boolean[]>(defaultChecked);
  return {
    nameList,
    checkedList,
    setCheckedList,
  };
}

export function usePlainTextParam(
  name: string,
  defaultText: string | undefined = undefined,
  allowedSymbols = "",
  regardBlankAsUndefined = true
): TextState {
  const [text, setText] = useState(defaultText);

  const getErrorMessage = (): string | undefined => {
    if (!text) return undefined;

    const symbolsEscaped = allowedSymbols
      .split("")
      .map((str: string) => `\\${str}`)
      .join();
    const regex = new RegExp(`^[0-9a-zA-Z${symbolsEscaped}]*$`);
    if (!text.match(regex)) {
      if (allowedSymbols) {
        if (allowedSymbols.includes(" ")) {
          const symbolds = allowedSymbols.replace(" ", "");
          return `使用できるのは英数字・半角スペース及び${symbolds}のみ`;
        }
        return `使用できるのは英数字及び${allowedSymbols}のみ`;
      }
      return "使用できるのは英数字のみ";
    }
    return undefined;
  };

  return {
    type: "plain",
    name,
    allowedSymbols,
    regardBlankAsUndefined,
    text,
    setText,
    getJson: () => text,
    getError: getErrorMessage,
    hasError: (): boolean => getErrorMessage() !== undefined,
    isChanged: () => defaultText !== text,
  };
}

export function useJsonTextParam(
  name: string,
  defaultText: string | undefined = undefined,
  regardBlankAsUndefined = true
): TextState {
  const [text, setText] = useState(defaultText);

  const getErrorMessage = (): string | undefined => {
    if (!text) return undefined;
    try {
      JSON.parse(text);
    } catch (error) {
      return "JSONの文法に誤りがあります";
    }
    return undefined;
  };

  return {
    type: "json",
    name,
    allowedSymbols: "",
    regardBlankAsUndefined,
    text,
    setText,
    getJson: (): object | string | number | boolean | undefined =>
      text
        ? (JSON.parse(text) as object | string | number | boolean)
        : undefined,
    getError: getErrorMessage,
    hasError: (): boolean => getErrorMessage() !== undefined,
    isChanged: () => defaultText !== text,
  };
}
