import {useContext} from "react";
import HandDetail from "../components/details/HandDetail";
import {DataStoreContext} from "../contexts/HandSelectionContext";
import FingertipDetail from "../components/details/FingertipDetail";

export default function HandSelectionDetail() {
  const {state} = useContext(DataStoreContext);

  if (state.handDetailPage.isShowFingertipDetail && state.handDetailPage.hand?.fingertip) {
    return <FingertipDetail/>
  }

  return (
    <HandDetail/>
  )
}
