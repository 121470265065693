import {Dict} from "./pj1dbApi";
import {HandPowerSource} from "../constants/constants";

// --------------------------------------------------------------------------------------
/**
 * handling APIのレスポンス型の定義
 */

type RegionState =
  | "notcalculated"
  | "calculating"
  | "calculated"
  | "recalculating"
  | "notcalculatedError"
  | "calculatedError";

type AttrStatus = "set" | "notset";

type CalcStatus =
  | "not_calc"
  | "queued_calc"
  | "calculating"
  | "calculated"
  | "error";

type UrdfStatus = "created" | "notcreated";

type XYZ = {
  x: number;
  y: number;
  z: number;
};

type ModelCalcInfo = {
  commonModel: {
    size: XYZ;
    center: XYZ;
  };
};

export type Direction = {
  theta: number;
  phi: number;
};

export type GravityWithThumbnail = {
  thumbnail: string;
  gravity: GravityInfo;
};

export type GravityInfo = {
  center?: Partial<XYZ>;
  radius?: number;
  weight?: number;
  standingDirection: Direction;
};

export type ModelStatusInfo = {
  gravity: AttrStatus;
  friction: AttrStatus;
  region: RegionState;
  grasp2p: RegionState;
  urdf_zip: UrdfStatus;
};

export type FrictionRegionInfo = {
  id: string;
  meshCount: number;
  friction: number | undefined;
};

export const GRASP2P_REGION_TYPES = {
  PLANE: "Plane",
  CYLINDER: "Cylinder",
} as const

export const GRASP2P_REGION_TYPE_LABELS = {
  [GRASP2P_REGION_TYPES.PLANE]: "平面",
  [GRASP2P_REGION_TYPES.CYLINDER]: "円筒",
} as const

export function pickGrasp2pRegionTypeLabel(type: string): string {
  const unknown = `未定義（${type}）`;

  const key = Object.values(GRASP2P_REGION_TYPES).find((v) => type.startsWith(v))
  if (!key) {
    return unknown;
  }

  return GRASP2P_REGION_TYPE_LABELS[key] ?? unknown;
}

export type Grasp2pInfo = {
  id: string;
  position?: {
    center: number[];
    orientation: number[];
    radius: number;
    arc: number;
  };
  force: number;
  requiredForce: number;
};

export type FindInstancesResponse = {
  [instanceId: string]: {
    thumbnail_list: string[];
  };
};

export type FindModelResponse = {
  modelId: string;
  metaInfo: ModelCalcInfo;
  status: ModelStatusInfo;
  gravityWithDirections: GravityWithThumbnail[];
  regionList: FrictionRegionInfo[];
  grasp2p: {
    [key: string]: Grasp2pInfo[];
  };
  weight: number;
  url: {
    glb: {
      original: string[];
      common10k: string[];
      candidate?: string[];
      stable?: string[];
    };
    urdf_zip?: {
      common10k: string[];
    };
  };
};

export type CalcStablePollResponse = {
  result: string;
};

export type CalcBulkCandidateResponse = {
  count: number;
};

export type CalcBulkCandidatePollResponse = {
  count_in_progress: number;
};

export type CalcIndivCandidateResponse = {
  count: number;
};

export type CalcIndivCandidatePollResponse = {
  region: RegionState;
  calc: CalcStatus;
};

export type WriteFrictionResponse = {
  queueCalcStable: string;
};

export type WriteGravityResponse = {
  queueCalcStable: string;
};

// --------------------------------------------------------------------------------------
/**
 * handling APIのリクエストパラメータ型の定義
 */

type AttrFlag = {
  notset: boolean;
  set: boolean;
};

type CalcFlag = {
  notcalculated: boolean;
  calculating: boolean;
  calculated: boolean;
  recalculating: boolean;
  notcalculatedError: boolean;
  calculatedError: boolean;
};

export type FindInstancesParam = {
  version: string | undefined;
  deleted: boolean;
  queryAttr: object | undefined;
  queryCalc: object | undefined;
  handlingSearchCondition: {
    candidate_region_flag: CalcFlag;
    stable_region_flag: CalcFlag;
    friction_info_flag: AttrFlag;
    gravity_info_flag: AttrFlag;
  };
};

export type FindModelParam = {
  instanceId: string;
};

export interface CalcHandCandidateParam {
  conditions: {
    instances: {
      // instanceId: "instance-0123456789abcdefghijk"
      instanceId: string;
      // 開時幅
      rangeOpen: string;
      // 閉時幅
      rangeClose: string;
      // ストローク
      stroke: string;
      // 把持力下限
      forceMin: string;
      // 把持力上限
      forceMax?: string;
      // 可搬重量
      payload: string;
      // ワークAPIのクラス情報から取得（UI上は表示せず自動入力）
      softMaterial: boolean;
      // ワークAPIのクラス情報から取得（UI上は表示せず自動入力）
      deformable: boolean;
    }[];
    // "ハンドの動作原理"でチェックされた値
    handPowerSource: HandPowerSource[];
  }
}

export interface CalcHandCandidateResult {
  jobId: string;
  handselectId: string;
}

export const HAND_CANDIDATE_STATUS = {
  SUBMITTED: 'SUBMITTED',
  PENDING: 'PENDING',
  RUNNABLE: 'RUNNABLE',
  STARTING: 'STARTING',
  RUNNING: 'RUNNING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
} as const

export type HandCandidateStatusType = typeof HAND_CANDIDATE_STATUS[keyof typeof HAND_CANDIDATE_STATUS];

export const HAND_CANDIDATE_STATUS_PROGRESS = {
  [HAND_CANDIDATE_STATUS.SUBMITTED]: 0,
  [HAND_CANDIDATE_STATUS.PENDING]: 10,
  [HAND_CANDIDATE_STATUS.RUNNABLE]: 20,
  [HAND_CANDIDATE_STATUS.STARTING]: 30,
  [HAND_CANDIDATE_STATUS.RUNNING]: 70,
  [HAND_CANDIDATE_STATUS.SUCCEEDED]: 100,
  [HAND_CANDIDATE_STATUS.FAILED]: 0,
}

export const SUGGESTION_PATTERNS = {
  HAND: 'hand',
  HAND_PLUS_FINGERTIP: 'hand_plus_fingertip',
  HAND_PLUS_VIRTUAL_FINGERTIP: 'hand_plus_virtual_fingertip',
} as const


export type SuggestionPatternType = typeof SUGGESTION_PATTERNS[keyof typeof SUGGESTION_PATTERNS];

export const SUGGESTION_PATTERN_LABELS = {
  [SUGGESTION_PATTERNS.HAND]: 'ハンドのみ',
  [SUGGESTION_PATTERNS.HAND_PLUS_FINGERTIP]: 'ハンド + 爪',
  [SUGGESTION_PATTERNS.HAND_PLUS_VIRTUAL_FINGERTIP]: 'ハンド + 仮想爪',
} as const satisfies Record<SuggestionPatternType, string>

export interface CalcHandCandidatePoolParam {
  jobId: string;
  handselectId: string;
}

export interface BaseSuggestionPatternResult {
  suggestionPattern: SuggestionPatternType;
  handID: string;
  score: number;
}

export interface SuggestionPatternHandResult extends BaseSuggestionPatternResult {
  suggestionPattern: typeof SUGGESTION_PATTERNS.HAND;
}

export interface SuggestionPatternHandPlusFingertipResult extends BaseSuggestionPatternResult {
  suggestionPattern: typeof SUGGESTION_PATTERNS.HAND_PLUS_FINGERTIP;
  fingertipID: string;
  mountPattern: string;
}

export interface SuggestionPatternHandPlusVirtualFingertipResult extends BaseSuggestionPatternResult {
  suggestionPattern: typeof SUGGESTION_PATTERNS.HAND_PLUS_VIRTUAL_FINGERTIP;
  mountPattern: string;
}

export type SuggestionPatternResult =
  SuggestionPatternHandResult
  | SuggestionPatternHandPlusFingertipResult
  | SuggestionPatternHandPlusVirtualFingertipResult;

export interface CalcHandCandidatePoolResult {
  status: HandCandidateStatusType;
  _id: {
    $oid: string
  },
  handselectId: string;
  results: SuggestionPatternResult[]
  creator: string;
  processing: {
    _id: {
      $oid: string
    },
    handselectId: string
  }
}

export type CalcStablePollParam = {
  modelId: string;
};

export type CalcBulkCandidateParam = {
  instanceIds: string[];
};

export type CalcBulkCandidatePollParam = {
  instanceIds: string[];
};

export type CalcIndivCandidateParam = {
  modelId: string;
};

export type CalcIndivCandidatePollParam = {
  modelId: string;
};

export type WriteFrictionParam = {
  modelId: string;
  friction: Dict<number | undefined>;
  creator: string;
};

export type WriteGravityParam = {
  modelId: string;
  gravity: GravityInfo;
  creator: string;
};

//--------------------------------------------------------------------------------------
/**
 * utility関数
 */
export const calcInitialGravityInfo = (
  gravity: GravityInfo | undefined,
  rawSize: XYZ | undefined
) => {
  const info = {
    centerDefault: [gravity?.center?.x, gravity?.center?.y, gravity?.center?.z],
    centerMinMax: [{}, {}, {}],
    radiusDefault: gravity?.radius,
    radiusMinMax: {
      min: 0,
    },
    weightDefault: gravity?.weight,
    weightMinMax: {
      min: 0,
    },
  };

  if (rawSize) {
    const maxSize = Math.max(rawSize.x, rawSize.y, rawSize.z);
    const limitSize = Math.sqrt(3) * maxSize;
    info.centerMinMax[0] = {min: -limitSize / 2, max: limitSize / 2};
    info.centerMinMax[1] = {min: -limitSize / 2, max: limitSize / 2};
    info.centerMinMax[2] = {min: 0, max: limitSize};
  }

  return info;
};
