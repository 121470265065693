import React, {useContext, useState} from "react";
import Grid from "@mui/material/Grid2";
import {Box, CircularProgress} from "@mui/material";
import {WorkClassDocument, WorkInstanceDocument} from "../../../../types/pj1dbApiWork";
import {WORK_ATTRS} from "../../../../constants/work";
import WorkInstanceListItem from "../lists/WorkInstanceListItem";
import useNotification from "../../../../utils/notificationUtil";
import {
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  STEPS,
  WorkInstanceSelectionStepPayload
} from "../../contexts/HandSelectionContext";
import {findWork} from "../../../../utils/awsAmplifyUtil";

export interface WorkInstanceSelectionStepProps {
  isLoading: boolean;
  workClass: WorkClassDocument;
  workInstances: WorkInstanceDocument[];
  onSelectInstance: (work: WorkInstanceDocument) => void;
}

export default function WorkInstanceSelectionStep(props: WorkInstanceSelectionStepProps) {
  return (
    <>
      {props.isLoading && (
        <Box display='flex' mt={2}>
          <CircularProgress/>
        </Box>
      )}

      {!props.isLoading && (
        <Grid container alignItems="stretch" spacing={2} sx={{mt: 2}}>
          {props.workInstances.map(workInstance => (
            <Grid
              key={workInstance.attr[WORK_ATTRS.instanceId.key]}
              onClick={() => props.onSelectInstance(workInstance)}
            >
              <WorkInstanceListItem work={workInstance}/>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

export function useWorkInstanceSelectionStepHelpers() {
  const [isLoading, setIsLoading] = useState(false);
  const {errorMsg} = useNotification();
  const {state, dispatch} = useContext(DataStoreContext);
  const [workInstanceList, setWorkInstanceList] = useState<WorkInstanceDocument[]>([]);
  const [notFoundMsg, setNotFoundMsg] = useState("");

  const findWorkInstances = async ({workClass}: {
    workClass: WorkClassDocument
  }) => {
    setIsLoading(true);
    try {
      const result = await findWork('instance', {
        version: "work-user",
        queryAttr: {
          [WORK_ATTRS.classId.key]: workClass.attr[WORK_ATTRS.classId.key],
        }
      });

      if (!result.isSuccess) {
        errorMsg("検索処理が失敗しました。");
        return;
      }

      const data = result.data ? Object.values(result.data.items) : [];
      if (data.length === 0) {
        setNotFoundMsg("該当データがありません。");
      }
      setWorkInstanceList(data);
      dispatch({
        type: CONTEXT_ACTION_TYPE.WORK_INSTANCE_SELECTION_STEP, payload: {
          ...state.workInstanceSelectionStep,
          workInstanceList: data,
        } satisfies WorkInstanceSelectionStepPayload
      });
    } finally {
      setIsLoading(false);
    }
  };

  const selectInstance = (workInstance: WorkInstanceDocument) => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_INSTANCE_SELECTION_STEP, payload: {
        ...state.workInstanceSelectionStep,
        selectedWorkInstance: workInstance,
        step: STEPS.SEARCH_CRITERIA_ADDITIONAL,
      } satisfies WorkInstanceSelectionStepPayload
    });
  }

  return {
    isLoading,
    workInstanceList,
    notFoundMsg,
    findWorkInstances,
    selectInstance,
  };
}
