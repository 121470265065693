/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {useLocation, useNavigate} from "react-router-dom";

import {HAND_SELECTION_URLS} from "../../../constants/constants";
import {CONTEXT_ACTION_TYPE, DataStoreContext, STEPS} from "../contexts/HandSelectionContext";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function HandSelectionFuncTab() {
  const {state, dispatch} = React.useContext(DataStoreContext);

  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch({type: CONTEXT_ACTION_TYPE.TAB, payload: newValue});
    Object.entries(HAND_SELECTION_URLS).some(([, CONST]) => {
      if (CONST.TAB_IDX === newValue) {
        navigate(CONST.URL);
        return true;
      }
      return false;
    });
  };

  // locationの状態からタブ状態を復元（戻る・進む対応）
  const location = useLocation();
  React.useEffect(() => {
    Object.entries(HAND_SELECTION_URLS).some(([, CONST]) => {
      // 前方一致で判定しているため HAND_SELECTION_URLS.BEGINNING_HAND.URL が詳細画面のURLと誤判定されるため
      // 先に詳細画面のURLを判定する
      if (location.pathname.includes(HAND_SELECTION_URLS.DETAIL.URL)) {
        if (state.tabIndex !== HAND_SELECTION_URLS.DETAIL.TAB_IDX) {
          dispatch({type: CONTEXT_ACTION_TYPE.TAB, payload: HAND_SELECTION_URLS.DETAIL.TAB_IDX});
        }
        return true;
      }

      if (location.pathname.includes(HAND_SELECTION_URLS.BEGINNING_HAND.URL)) {
        if (state.tabIndex !== HAND_SELECTION_URLS.BEGINNING_HAND.TAB_IDX) {
          dispatch({type: CONTEXT_ACTION_TYPE.TAB, payload: HAND_SELECTION_URLS.BEGINNING_HAND.TAB_IDX});
        }
        return true;
      }

      if (location.pathname.includes(CONST.URL)) {
        if (state.tabIndex !== CONST.TAB_IDX)
          dispatch({type: CONTEXT_ACTION_TYPE.TAB, payload: CONST.TAB_IDX});
        return true;
      }
      return false;
    });
  }, [dispatch, location.pathname, state.tabIndex]);

  const isCalculatedStep = state.step === STEPS.HAND_SELECTION || state.step === STEPS.HARDWARE_SELECTION;

  return (
    <Container
      sx={{
        m: 0,
        pt: 2,
        backgroundColor: "white",
        width: "100%",
        maxWidth: "unset!important",
      }}
    >
      <Box sx={{width: "100%"}}>
        <Box sx={{borderBottom: 1, borderColor: "divider"}}>
          <Tabs value={state.tabIndex} onChange={handleChange}>
            <Tab
              label="ハンド選択支援"
              {...a11yProps(HAND_SELECTION_URLS.BEGINNING_WORK.TAB_IDX)}
              sx={{fontSize: "1rem", display: isCalculatedStep ? 'none' : 'block'}}
            />
            <Tab
              label="ハンド選択支援"
              {...a11yProps(HAND_SELECTION_URLS.BEGINNING_HAND.TAB_IDX)}
              sx={{fontSize: "1rem", display: isCalculatedStep ? 'block' : 'none'}}
            />
            <Tab
              label="詳細画面"
              {...a11yProps(HAND_SELECTION_URLS.DETAIL.TAB_IDX)}
              sx={{fontSize: "1rem"}}
              disabled={state.handDetailPage.hand === null}
            />
          </Tabs>
        </Box>
      </Box>
    </Container>
  );
}
