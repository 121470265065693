import {Box, Container, Link, Stack, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid2";
import {useNavigate} from "react-router-dom";
import WorkClassSelectionStep, {useWorkClassSelectionStepHelpers,} from "../components/steps/WorkClassSelectionStep";
import SelectedModels from "../components/lists/SelectedModels";
import {
  CalculatedHandPayload,
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  pickPrevStep,
  SearchCriteriaAdditionalStepPayload,
  StepPayload,
  STEPS,
} from "../contexts/HandSelectionContext";
import WorkInstanceSelectionStep, {
  useWorkInstanceSelectionStepHelpers
} from "../components/steps/WorkInstanceSelectionStep";
import SearchForm, {SearchCond} from "../components/forms/SearchForm";
import {WORK_ATTRS} from "../../../constants/work";
import {WorkClassDocument, WorkInstanceDocument} from "../../../types/pj1dbApiWork";
import SortOrder, {SortOrderProps} from "../components/forms/SortOrder";
import SearchCriteriaAdditionalStep, {
  useSearchCriteriaAdditionalStepHelpers,
  UseSearchCriteriaAdditionalStepHelpersResult
} from "../components/steps/SearchCriteriaAdditionalStep";
import Pjdb1Button from "../../../components/inputs/Pjdb1Button";
import CalcHandLoading from "../components/CalcHandLoading";
import {caclHandCandidate, caclHandCandidatePoll} from "../../../utils/awsAmplifyUtil";
import {
  CalcHandCandidateResult,
  FindModelResponse,
  HAND_CANDIDATE_STATUS,
  HandCandidateStatusType
} from "../../../types/pj1dbApiHandling";
import useNotification from "../../../utils/notificationUtil";
import {HAND_SELECTION_URLS} from "../../../constants/constants";


export default function WorkSelection() {
  const {state, dispatch} = useContext(DataStoreContext);
  const navigate = useNavigate();
  const {errorMsg} = useNotification()
  const {
    notFoundMsg,
    isLoading: isWorkClassLoading,
    setSearchCond,
    searchCond,
    workClassList,
    findWorkClasses,
    changeSortOrder,
    selectClass,
  } = useWorkClassSelectionStepHelpers();

  const {
    isLoading: isWorkInstanceLoading,
    workInstanceList,
    findWorkInstances,
    selectInstance,
  } = useWorkInstanceSelectionStepHelpers();

  const searchCriteriaAdditionalStepProps = useSearchCriteriaAdditionalStepHelpers();

  const [isCalc, setIsCalc] = useState(false);
  const [calcStatus, setCalcStatus] = useState<HandCandidateStatusType | null>(null);

  useEffect(() => {
    if (!state.workClassSelectionStep.selectedWorkClass) return;
    findWorkInstances({workClass: state.workClassSelectionStep.selectedWorkClass}).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.workClassSelectionStep.selectedWorkClass]);

  useEffect(() => {
    if (!state.workInstanceSelectionStep.selectedWorkInstance) return;
    searchCriteriaAdditionalStepProps.findWorkModels({workInstance: state.workInstanceSelectionStep.selectedWorkInstance}).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.workInstanceSelectionStep.selectedWorkInstance]);

  useEffect(() => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.SEARCH_CRITERIA_ADDITIONAL_STEP,
      payload: {
        ...state.searchCriteriaAdditionalStep,
        searchCriteriaInstanceInputs: searchCriteriaAdditionalStepProps.searchCriteria,
      } satisfies SearchCriteriaAdditionalStepPayload
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteriaAdditionalStepProps.searchCriteria]);

  useEffect(() => {
    if (calcStatus === HAND_CANDIDATE_STATUS.FAILED) {
      errorMsg('計算ジョブの実行に失敗しました。');
      setIsCalc(false);
      setCalcStatus(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcStatus]);

  const handleCalcHandCandidatePool = (data: CalcHandCandidateResult) => {
    const interval = setInterval(() => {
      caclHandCandidatePoll(data)
        .then((poolRes) => {
          const poolResData = poolRes.data;
          if (!poolRes.isSuccess || !poolResData) {
            setCalcStatus(HAND_CANDIDATE_STATUS.FAILED);
            return;
          }

          const status = poolResData.status ?? null;
          setCalcStatus(status);

          if (status === HAND_CANDIDATE_STATUS.SUCCEEDED || status === HAND_CANDIDATE_STATUS.FAILED) {
            clearInterval(interval);
          }

          if (status === HAND_CANDIDATE_STATUS.FAILED) {
            setCalcStatus(HAND_CANDIDATE_STATUS.FAILED);
          }

          if (status === HAND_CANDIDATE_STATUS.SUCCEEDED) {
            dispatch({
              type: CONTEXT_ACTION_TYPE.CALCULATED_HAND,
              payload: {
                step: STEPS.HAND_SELECTION,
                calculatedHand: poolResData,
              } satisfies CalculatedHandPayload
            });
            navigate(HAND_SELECTION_URLS.BEGINNING_HAND.URL);
          }
        })
        .catch((e) => {
          setCalcStatus(HAND_CANDIDATE_STATUS.FAILED);
          console.error(e);
        });
    }, 1000 * 5);
  }

  const handleCalcHandCandidate = () => {
    setIsCalc(true);
    caclHandCandidate({
      conditions: {
        instances: state.searchCriteriaAdditionalStep.searchCriteriaList.map((criteria) => ({
          ...criteria,
          softMaterial: state.workClassSelectionStep.selectedWorkClass?.attr.softMaterial ?? false,
          deformable: state.workClassSelectionStep.selectedWorkClass?.attr.deformable ?? false,
        })),
        handPowerSource: state.searchCriteriaAdditionalStep.handPowerSourceInputs.handPowerSource,
      }
    }).then(r => {
      const {data} = r;
      if (!r.isSuccess || !data) {
        setCalcStatus(HAND_CANDIDATE_STATUS.FAILED);
        return;
      }

      handleCalcHandCandidatePool(data);
    }).catch((e) => {
      setCalcStatus(HAND_CANDIDATE_STATUS.FAILED);
      console.error(e);
    });
  }

  return (
    <>
      <SearchForm
        isLoading={isWorkClassLoading}
        searchCond={searchCond}
        setSearchCond={setSearchCond}
        onSubmit={(e) => {
          e.preventDefault();
          findWorkClasses().catch(console.error);
        }}
      />

      <Container
        sx={{p: 0, display: "flex", flexDirection: "row", flexWrap: "wrap"}}
        maxWidth={false}
      >
        {state.step === STEPS.WORK_CLASS_SELECTION && (
          <WorkClassSelection
            notFoundMsg={notFoundMsg}
            workClassList={workClassList}
            searchCond={searchCond}
            changeSortOrder={changeSortOrder}
            selectClass={selectClass}
          />
        )}
        {state.step === STEPS.WORK_INSTANCE_SELECTION &&
          state.workClassSelectionStep.selectedWorkClass &&
          (
            <WorkInstanceSelection
              isLoading={isWorkInstanceLoading}
              workClass={state.workClassSelectionStep.selectedWorkClass}
              workInstances={workInstanceList}
              selectInstance={selectInstance}
            />
          )}

        {state.step === STEPS.SEARCH_CRITERIA_ADDITIONAL
          && state.workClassSelectionStep.selectedWorkClass
          && state.workInstanceSelectionStep.selectedWorkInstance
          && (
            <SearchCriteriaAdditional
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...searchCriteriaAdditionalStepProps}
              workClass={state.workClassSelectionStep.selectedWorkClass}
              workInstance={state.workInstanceSelectionStep.selectedWorkInstance}
              workModel={state.searchCriteriaAdditionalStep.workModel}
              selectedModelDirection={state.searchCriteriaAdditionalStep.selectedModelDirection}
            />
          )}

        <Stack gap={2} mt={2} width='100%'>
          <SelectedModels
            searchCriteriaList={state.searchCriteriaAdditionalStep.searchCriteriaList}
            onDelete={searchCriteriaAdditionalStepProps.deleteSearchCriteria}/>

          <Box display='flex' justifyContent='end'>
            <Pjdb1Button
              label='計算実行'
              disabled={state.searchCriteriaAdditionalStep.searchCriteriaList.length <= 0}
              onClick={handleCalcHandCandidate}
              variant='contained'
              color='info'
            />
          </Box>

          <CalcHandLoading
            open={isCalc}
            status={calcStatus ?? HAND_CANDIDATE_STATUS.SUBMITTED}
          />
        </Stack>
      </Container>
    </>
  );
}

function WorkClassSelection(props: {
  notFoundMsg: string,
  searchCond: SearchCond,
  workClassList: WorkClassDocument[],
  changeSortOrder: SortOrderProps['onChange'],
  selectClass: (work: WorkClassDocument) => void
}) {
  return (
    <>
      <SearchResult
        actionText='商品/部品を選択してください'
        searchCond={props.searchCond}
        onChangeSortOrder={props.changeSortOrder}
      />
      <WorkClassSelectionStep
        notFoundMsg={props.notFoundMsg}
        workClasses={props.workClassList}
        onSelectClass={props.selectClass}
      />
    </>
  );
}

function WorkInstanceSelection(props: {
  isLoading: boolean,
  workClass: WorkClassDocument,
  workInstances: WorkInstanceDocument[],
  selectInstance: (workInstance: WorkInstanceDocument) => void
}) {
  return (
    <>
      <TitleWithBackButton
        workClass={props.workClass}
        prevText='検索結果に戻る'
        actionText='サンプルを選択してください。'
      />
      <WorkInstanceSelectionStep
        isLoading={props.isLoading}
        workClass={props.workClass}
        workInstances={props.workInstances}
        onSelectInstance={props.selectInstance}
      />
    </>
  )
}

function SearchCriteriaAdditional(
  props: UseSearchCriteriaAdditionalStepHelpersResult & {
    workClass: WorkClassDocument,
    workInstance: WorkInstanceDocument,
    workModel: FindModelResponse | null,
  }
) {
  return (
    <>
      <TitleWithBackButton workClass={props.workClass} prevText='サンプル選択に戻る'/>
      <SearchCriteriaAdditionalStep
        three={props.three}
        controlStateResult={props.controlStateResult}
        searchCriteriaFormRef={props.searchCriteriaFormRef}
        searchCriteria={props.searchCriteria}
        handPowerSource={props.handPowerSource}
        selectedObjectId={props.selectedObjectId}
        setSelectedObjectId={props.setSelectedObjectId}
        isLoading={props.isLoading}
        workClass={props.workClass}
        workInstance={props.workInstance}
        workModel={props.workModel}
        setSearchCriteria={props.setSearchCriteria}
        setHandPowerSource={props.setHandPowerSource}
        selectedModelDirection={props.selectedModelDirection}
        onSelectModelDirection={props.selectModelDirection}
        onAddSearchCriteria={props.addSearchCriteria}
      />
    </>
  )
}

function SearchResult(
  {
    actionText,
    searchCond,
    onChangeSortOrder,
  }: {
    actionText: string,
    searchCond: SearchCond,
    onChangeSortOrder: SortOrderProps['onChange']
  }
) {
  return (
    <Stack width='100%'>
      <Grid container alignItems="end" sx={{mt: 2, flexWrap: "nowrap"}} size={{xs: 12}}>
        <Grid size="grow">
          <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>
            検索結果一覧
          </Typography>
        </Grid>
        <Grid size="auto">
          <Grid container alignItems="center" spacing={2} sx={{flexWrap: "nowrap"}}>
            <Grid>
              <Box component="span">
                {searchCond.listCount > 0 ? `${searchCond.listCount.toLocaleString()}件` : ""}
              </Box>
            </Grid>
            <Grid>
              <Box component="span">
                <SortOrder value={searchCond.sortOrder} onChange={onChangeSortOrder}/>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="subtitle2" sx={{fontWeight: "bold"}}>
        {actionText}
      </Typography>
    </Stack>
  )
}

function TitleWithBackButton({workClass, prevText, actionText}: {
  workClass: WorkClassDocument,
  prevText: string,
  // eslint-disable-next-line react/require-default-props
  actionText?: string
}) {
  const {state, dispatch} = useContext(DataStoreContext);
  return (
    <Stack width='100%'>
      <Grid container alignItems="end" sx={{mt: 2, flexWrap: "nowrap"}} size={{xs: 12}}>
        <Grid size="grow">
          <Typography variant="h6" sx={{fontWeight: "bold"}}>
            商品/部品名：{workClass.attr[WORK_ATTRS.nameJp.key]}
          </Typography>
        </Grid>
        <Grid size="auto">
          <Grid container alignItems="center" spacing={2} sx={{flexWrap: "nowrap"}}>
            <Grid>
              <Box component="span">
                <Link
                  href="#"
                  style={{textDecoration: 'none', color: 'black'}}
                  onClick={() => dispatch({
                    type: CONTEXT_ACTION_TYPE.STEP,
                    payload: pickPrevStep(state.step) satisfies StepPayload
                  })}>
                  {prevText}
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {actionText && (
        <Typography variant="subtitle2" sx={{fontWeight: "bold"}}>
          {actionText}
        </Typography>
      )}
    </Stack>
  );
}
