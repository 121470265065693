/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React,{ useState , useEffect} from "react";

import { InputLabel, Box , IconButton, FormHelperText} from "@mui/material";
import Grid from '@mui/material/Grid2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { HAND_MOUNT_PATTERN } from '../../../../constants/constants'

import { FormFingertipAttr, FormFingertipShape } from '../../types/fingertipForm'

import {handleInputChangeNameVal} from "../../../../utils/formUtil"

import Pjdb1TextField from '../../../../components/inputs/Pjdb1TextField';
import Pjdb1RadioGroup from '../../../../components/inputs/Pjdb1RadioGroup';

import Pjdb1ToolTip from "../../../../components/inputs/Pjdb1ToolTip";

interface Props {
  inputs: FormFingertipAttr;
  setInputs: React.Dispatch<React.SetStateAction<FormFingertipAttr>>;
  isClear?: boolean;
  customErrorCheck?:() => void;
  customErrorMsg?:string[];
}

const areEqual = (prevProps:Props, nextProps:Props) => {
  // console.log('[FingertipShape]:areEqual(prev)',prevProps);
  // console.log('[FingertipShape]:areEqual(next)',nextProps);
  // let retEqual:boolean = prevProps.inputs.fingertipShape === nextProps.inputs.fingertipShape;
  let retEqual:boolean = JSON.stringify(prevProps.inputs.fingertipShape) === JSON.stringify(nextProps.inputs.fingertipShape);
  if (nextProps.isClear !== undefined && prevProps.isClear !== nextProps.isClear) retEqual = false;
  if (nextProps.customErrorMsg !== undefined && JSON.stringify(prevProps.customErrorMsg) !== JSON.stringify(nextProps.customErrorMsg)) retEqual = false;
  // console.log('[FingertipShape]:areEqual(dif) val:',retEqual);
  return retEqual;
}

export default React.memo((props: Props) => {
  // console.log('[FingertipShape]:rendering!!',props);
  const [dispList, setDispList] = useState<FormFingertipShape[]>([]);
  useEffect(()=>{
    const listCount = props.inputs.fingertipShape && props.inputs.fingertipShape.length > 0 ? props.inputs.fingertipShape.length : 1;
    const dispListData:FormFingertipShape[] = [];
    for (let i = 0; i < listCount; i+=1) {
      if(props.inputs.fingertipShape.length > i){
        dispListData.push({
          mountPattern: props.inputs.fingertipShape[i].mountPattern,
          thicknessInner: props.inputs.fingertipShape[i].thicknessInner ? props.inputs.fingertipShape[i].thicknessInner : "",
          thicknessOuter: props.inputs.fingertipShape[i].thicknessOuter ? props.inputs.fingertipShape[i].thicknessOuter : "",
        });
      }else{
        dispListData.push({
          mountPattern: "",
          thicknessInner: "",
          thicknessOuter: "",
        });
      }
    }
    setDispList(dispListData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.inputs.fingertipShape]);

  const makeToolTip = (mountPattern:string, controlName:string) => {
    let retToolTip:JSX.Element = <span>&nbsp;</span>;
    if (!mountPattern){
      retToolTip = <>取り付け方向を選択してください。</>;
    }else{
      let description = <span>&nbsp;</span>;
      switch (mountPattern) {
        case "x":
          description = controlName==="thicknessInner" ?
            <span>
              ねじ穴中心部から外径把持部までの横方向の符号付き距離<br/>
              ※矢印方向を正とする<br/>
              ※外径把持方向、内径把持方向のいずれかは必須
            </span> :
            <span>
              ねじ穴中心部から内径把持部までの横方向の符号付き距離<br/>
              ※矢印方向を正とする<br/>
              ※外径把持方向、内径把持方向のいずれかは必須
            </span>
          break;
        case "yi":
        case "yo":
          description = controlName==="thicknessInner" ?
            <span>
              可動部との接平面から外径把持部までの横方向の符号付き距離<br/>
              ※矢印方向を正とする<br/>
              ※外径把持が想定外の場合は未入力でもよい<br/>
              ※外径把持方向、内径把持方向のいずれかは必須
            </span> :
            <span>
              可動部との接平面から内径把持部までの横方向の符号付き距離<br/>
              ※矢印方向を正とする<br/>
              ※内径把持が想定外の場合は未入力でもよい<br/>
              ※外径把持方向、内径把持方向のいずれかは必須
            </span>
          break;
        case "z":
          description = controlName==="thicknessInner" ?
            <span>
              ねじ穴中心部から外径把持部までの横方向の符号付き距離<br/>
              ※矢印方向を正とする<br/>
              ※内径把持を想定するならば負の符号になる<br/>
              ※外径把持方向、内径把持方向のいずれかは必須
            </span> :
            <span>
              ねじ穴中心部から内径把持部までの横方向の符号付き距離<br/>
              ※矢印方向を正とする<br/>
              ※外径把持方向、内径把持方向のいずれかは必須
            </span>
          break;
        default:
          // console.log(mountPattern);
      }

      retToolTip =
        <Grid container spacing={1} alignItems="start">
          <Grid>
            <img src={`/images/handdb/tooltip/fingertip.${mountPattern}.${controlName}.jpg`} alt={controlName}/>
          </Grid>
          <Grid>
            {description}
          </Grid>
        </Grid>;
    }

    return retToolTip;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>,controlName:string,rowIdx:number) => {
    const controlValue:string = event.target.value ? event.target.value : "";
    // console.log("handleChange",controlName,rowIdx,controlValue);
    setDispList((prevList)=>{
      const newList:FormFingertipShape[] = prevList.map((row,idx)=>{
        if(idx === rowIdx){
          return {
            mountPattern: controlName==="mountPattern" ? controlValue : row.mountPattern,
            thicknessInner: controlName==="thicknessInner" ? controlValue : row.thicknessInner,
            thicknessOuter: controlName==="thicknessOuter" ? controlValue : row.thicknessOuter,
          };
        }
        return row;
      });
      handleInputChangeNameVal<FormFingertipAttr>("fingertipShape", newList , props.inputs, props.setInputs);
      return newList;
    });
  }

  const addList = () => {
    setDispList((prevList)=>{
      const newList:FormFingertipShape[] = prevList;
      newList.push({
        mountPattern: "",
        thicknessInner: "",
        thicknessOuter: "",
      });
      handleInputChangeNameVal<FormFingertipAttr>("fingertipShape", newList , props.inputs, props.setInputs);
      return newList;
    });
  }
  const delList = (index:number) => {
    let newList:FormFingertipShape[];
    setDispList((prevList)=>{
      newList = prevList;
      if(newList.length===1){
        newList[0] = {
          mountPattern: "",
          thicknessInner: "",
          thicknessOuter: "",
        }
      }else{
        newList = prevList.filter((val,idx) => idx !== index);
      }
      handleInputChangeNameVal<FormFingertipAttr>("fingertipShape", newList , props.inputs, props.setInputs);
      return newList;
    });
  }

  return (
    <>
      {dispList.map((value,index) => (
        <Box key={`row_${index}`}>
          <Grid container spacing={2} alignItems="start">
            <Grid width="120px">
              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>取り付け方向{index+1}</InputLabel>
            </Grid>
            <Grid>
              <Pjdb1RadioGroup
                name={`mountPattern_${index}`} value={value.mountPattern}
                onChange={(e)=>{handleChange(e,"mountPattern",index)}}
                isClear={props.isClear}
                radios={HAND_MOUNT_PATTERN.map((row)=>({btnKey:row.key,btnLbl:row.label}))}
                disabledRadios={dispList.filter((val,idx) => idx !== index).map(val => val.mountPattern)}
                required
                toolTip={
                  <Grid container alignItems="start" direction="column">
                    <Grid>
                      <img src="/images/handdb/tooltip/mountPattern.jpg" style={{width:"654px"}} alt="マウントパターン"/>
                    </Grid>
                    <Grid>
                      <span>ハンドと爪の取り付け方向を上記パターンから選択。</span>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
            <Grid>
              <IconButton color="error" sx={{mt:1,ml:1}} onClick={()=>{delList(index)}} >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>

            { index === 0 &&
              <Grid>
                <Pjdb1ToolTip toolTip={
                  <span>
                    <AddCircleOutlineIcon sx={{fontSize:"1rem",mt:"3px",mr:"3px",verticalAlign:"text-bottom"}} />ボタンで取り付けパターンを追加、<br />
                    <RemoveCircleOutlineIcon sx={{fontSize:"1rem",mt:"3px",mr:"3px",verticalAlign:"text-bottom"}} />ボタンで取り付けパターンを削除
                  </span>
                } />
              </Grid>
            }

          </Grid>
          <Grid container spacing={2} alignItems="start">
            <Grid width="120px">&nbsp;</Grid>
            <Grid width="270px">
              <Pjdb1TextField
                label="外径把持方向の厚さ"
                name={`thicknessInner_${index}`} value={value.thicknessInner}
                onChange={(e)=>{handleChange(e,"thicknessInner",index)}}
                isClear={props.isClear}
                inputProps={{pattern: "-?[0-9]*\\.?[0-9]*" }} unit="mm"
                toolTip={makeToolTip(value.mountPattern,"thicknessInner")}
                customErrorCheck={props.customErrorCheck}
                customErrorMsg={props.customErrorMsg?props.customErrorMsg[index]:""}
              />
            </Grid>
            <Grid width="270px" sx={{ml:1}}>
              <Pjdb1TextField
                label="内径把持方向の厚さ"
                name={`thicknessOuter_${index}`} value={value.thicknessOuter}
                onChange={(e)=>{handleChange(e,"thicknessOuter",index)}}
                isClear={props.isClear}
                inputProps={{pattern: "-?[0-9]*\\.?[0-9]*" }} unit="mm"
                toolTip={makeToolTip(value.mountPattern,"thicknessOuter")}
                customErrorCheck={props.customErrorCheck}
                customErrorMsg={props.customErrorMsg?props.customErrorMsg[index]:""}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="start">
            <Grid width="120px">&nbsp;</Grid>
            <Grid sx={{ml:2, flexGrow: 1}}>
              <FormHelperText>※外径把持方向、内径把持方向のいずれかは必須</FormHelperText>
            </Grid>
          </Grid>
        </Box>
      ))}
      { dispList.length < HAND_MOUNT_PATTERN.length &&
        <Box>
          <IconButton color="info" sx={{mt:0,mb:1}} onClick={addList} >
            <AddCircleOutlineIcon />
          </IconButton>
        </Box>
      }
    </>
  );
},areEqual);