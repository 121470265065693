import {useEffect} from "react";
import View3D, {Callbacks, ThreeState} from "./View3D";
import {calcInitialGravityInfo, FindModelResponse, GravityInfo} from "../../../../types/pj1dbApiHandling";
import {useNumberListParam} from "../../utils/stateUtil";

interface WorkCandidateModel3DProps {
  three: ThreeState;
  model: FindModelResponse;
  gravity: GravityInfo;
  onObjectSelected: Callbacks['onObjectSelected']
}

export default function WorkCandidateModel3D(props: WorkCandidateModel3DProps) {
  const gravityInfo = calcInitialGravityInfo(
    props.gravity,
    props.model.metaInfo?.commonModel.size
  );

  const center = useNumberListParam(
    ["x", "y", "z"],
    gravityInfo.centerDefault,
    ([x, y, z]) => {
    },
    gravityInfo.centerMinMax
  );
  const radius = useNumberListParam(
    ["r"],
    [gravityInfo.radiusDefault],
    ([r]) => {
    },
    [gravityInfo.radiusMinMax]
  );

  const reflectGravityInfoToThree = () => {
    console.log("reflectGravityInfoToThree");
    if (props.three.control) {
      const c = center.valueList;
      const r = radius.valueList[0];
      const direction = props.gravity.standingDirection;
      props.three.control.setControlPoint(c[0], c[1], c[2]);
      props.three.control.setControlPointRimRadius(r);
      if (direction) {
        const s = Math.PI / 180;
        console.log("direction", {
          theta_rad: direction.theta * s,
          phi_rad: direction.phi * s,
        });
        props.three.control.setStandingDirection(
          direction.theta * s,
          direction.phi * s
        );
      }
    }
  };

  useEffect(() => {
    if (props.three.loadCount > 0 && !props.three.loading) {
      reflectGravityInfoToThree();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gravity.standingDirection, props.three.loadCount, center.valueList, radius.valueList]);

  return (
    <View3D
      three={props.three}
      url={props.model?.url.glb.candidate?.[0]}
      options={{
        useOrthoCamera: true,
        displayGrid: true,
        keepSelectionWhenClickBlank: true,
        objectSelectable: true,
        exceptObjectSelection: ["default"],
      }}
      showPerspectiveControl={false}
      callbacks={{
        onObjectSelected: props.onObjectSelected,
      }}
    />
  );
}
