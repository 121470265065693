import React from "react";
import Pjdb1TextField from "../../../../components/inputs/Pjdb1TextField";
import {handleInputChange} from "../../../../utils/formUtil";
import WithLabel from "./WithLabel";

export interface SearchCriteriaInstanceGroupProps {
  isClear: boolean;
  inputs: SearchCriteriaInstanceGroupInputs,
  setInputs: React.Dispatch<React.SetStateAction<SearchCriteriaInstanceGroupInputs>>;
}

export interface SearchCriteriaInstanceGroupInputs {
  // "開時幅"
  rangeOpen?: string;
  // "閉時幅の値"
  rangeClose?: string;
  // "ストローク"
  stroke?: string;
  // "把持力下限"
  forceMin?: string;
  // "把持力上限"
  forceMax?: string;
  // "可搬重量"
  payload?: string;
}

export const initialSearchCriteriaGroupInputs = {
  rangeOpen: '',
  rangeClose: '',
  stroke: '',
  forceMin: '',
  forceMax: '',
  payload: '',
};

export default function SearchCriteriaInstanceGroup(props: SearchCriteriaInstanceGroupProps) {
  return (
    <>
      <WithLabel label='開時幅 [mm]'>
        <Pjdb1TextField
          label=''
          unit='mm'
          name='rangeOpen'
          value={props.inputs.rangeOpen}
          inputProps={{pattern: "[0-9.]*"}}
          toolTip={<>
            製品を把持する前の爪把持部間の幅<br/>
            デフォルト値は閉時幅×1.1
          </>}
          required
          onChange={(e) => (handleInputChange<SearchCriteriaInstanceGroupInputs>(e, props.inputs, props.setInputs))}
          disabled={false}
          isClear={props.isClear}
          xs={4}
        />
      </WithLabel>

      <WithLabel label='閉時幅 [mm]'>
        <Pjdb1TextField
          label=''
          unit='mm'
          name='rangeClose'
          value={props.inputs.rangeClose}
          inputProps={{pattern: "[0-9.]*"}}
          toolTip={<>
            製品を把持した時の爪把持部間の幅<br/>
            デフォルト値は必要把持幅-2mm
          </>}
          required
          onChange={(e) => (handleInputChange<SearchCriteriaInstanceGroupInputs>(e, props.inputs, props.setInputs))}
          disabled={false}
          isClear={props.isClear}
          xs={4}
        />
      </WithLabel>

      <WithLabel label='ストローク [mm]'>
        <Pjdb1TextField
          label=''
          unit='mm'
          name='stroke'
          value={props.inputs.stroke}
          inputProps={{pattern: "[0-9.]*"}}
          toolTip='デフォルト値は開時幅-閉時幅'
          required
          onChange={(e) => (handleInputChange<SearchCriteriaInstanceGroupInputs>(e, props.inputs, props.setInputs))}
          disabled={false}
          isClear={props.isClear}
          xs={4}
        />
      </WithLabel>

      <WithLabel label='把持力下限 [N]'>
        <Pjdb1TextField
          label=''
          unit='N'
          name='forceMin'
          value={props.inputs.forceMin}
          inputProps={{pattern: "[0-9.]*"}}
          toolTip='デフォルト値は必要把持力'
          required
          onChange={(e) => (handleInputChange<SearchCriteriaInstanceGroupInputs>(e, props.inputs, props.setInputs))}
          disabled={false}
          isClear={props.isClear}
          xs={4}
        />
      </WithLabel>

      <WithLabel label='把持力上限 [N]'>
        <Pjdb1TextField
          label=''
          unit='N'
          name='forceMax'
          value={props.inputs.forceMax}
          inputProps={{pattern: "[0-9.]*"}}
          toolTip='把持力の余力'
          required={false}
          onChange={(e) => (handleInputChange<SearchCriteriaInstanceGroupInputs>(e, props.inputs, props.setInputs))}
          disabled={false}
          isClear={props.isClear}
          xs={4}
        />
      </WithLabel>

      <WithLabel label='可搬重量 [kg]'>
        <Pjdb1TextField
          label=''
          unit='kg'
          name='payload'
          value={props.inputs.payload}
          inputProps={{pattern: "[0-9.]*"}}
          toolTip='商品/部品の重さ以上の値'
          required
          onChange={(e) => (handleInputChange<SearchCriteriaInstanceGroupInputs>(e, props.inputs, props.setInputs))}
          disabled={false}
          isClear={props.isClear}
          xs={4}
        />
      </WithLabel>
    </>
  );
}
