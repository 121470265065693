import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import {SearchCriteriaListItem} from "../../contexts/HandSelectionContext";
import {HandPowerSource} from "../../../../constants/constants";
import HandPowerSourceField from "../forms/HandPowerSourceField";

export interface SearchCriteriaTableProps {
  criteria: SearchCriteriaListItem[];
  handPowerSources: HandPowerSource[];
}

export default function SearchCriteriaTable(props: SearchCriteriaTableProps) {
  return (
    <Stack gap={2}>
      <HandPowerSourceField
        inputs={{handPowerSource: props.handPowerSources}}
        isClear={false}
        disabled
        disabledTooltip
      />

      <Typography variant='subtitle1' component='h3'>その他条件</Typography>
      <TableContainer component={Paper} sx={{maxWidth: "100%"}}>
        <Table size="small">
          <TableHead sx={{bgcolor: "#EEE"}}>
            <TableRow>
              <TableCell align='center' sx={{px: 1, py: 0.5}}>No</TableCell>
              <TableCell align='center' sx={{px: 1, py: 0.5}}>開時幅</TableCell>
              <TableCell align='center' sx={{px: 1, py: 0.5}}>閉時幅</TableCell>
              <TableCell align='center' sx={{px: 1, py: 0.5}}>ストローク</TableCell>
              <TableCell align='center' sx={{px: 1, py: 0.5}}>把持力下限</TableCell>
              <TableCell align='center' sx={{px: 1, py: 0.5}}>把持力上限</TableCell>
              <TableCell align='center' sx={{px: 1, py: 0.5}}>可搬重量</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.criteria.map((criteria, index) => (
              <TRow key={JSON.stringify(criteria)} no={index + 1} criteria={criteria}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
    ;
}

function TRow({no, criteria}: { no: number, criteria: SearchCriteriaListItem }) {
  return (
    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
      <TableCell align='center' sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{no}</TableCell>
      <TableCell align='center' sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{`${criteria.rangeOpen} mm`}</TableCell>
      <TableCell align='center' sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{`${criteria.rangeClose} mm`}</TableCell>
      <TableCell align='center' sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{`${criteria.stroke} mm`}</TableCell>
      <TableCell align='center' sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{`${criteria.forceMin} N`}</TableCell>
      <TableCell align='center' sx={{
        px: 1,
        py: 0.5,
        textWrap: 'nowrap'
      }}>{criteria.forceMax ? `${criteria.forceMax} N` : null}</TableCell>
      <TableCell align='center' sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{`${criteria.payload} N`}</TableCell>
    </TableRow>
  )
}
