// --------------------------------------------------
export const EQUIPMENT_URL_PREFIX = "/EquipmentDB/";
export const EQUIPMENT_URLS = {
  HAND_LIST: {
    TAB_IDX: 0,
    URL: "/EquipmentDB/HandList"
  },
  HAND_DETAIL: {
    TAB_IDX: 1,
    URL: "/EquipmentDB/HandDetail",
    ARG: "handId"
  },
  FINGERTIP_LIST: {
    TAB_IDX: 2,
    URL: "/EquipmentDB/FingertipList"
  },
  FINGERTIP_DETAIL: {
    TAB_IDX: 3,
    URL: "/EquipmentDB/FingertipDetail",
    ARG: "fingertipId"
  },
  ARM_LIST: {
    TAB_IDX: 4,
    URL: "/EquipmentDB/ArmList"
  },
  ARM_DETAIL: {
    TAB_IDX: 5,
    URL: "/EquipmentDB/ArmDetail",
    ARG: "armId"
  },
  ATTACHMENT_LIST: {
    TAB_IDX: 6,
    URL: "/EquipmentDB/AttachmentList"
  },
  ATTACHMENT_DETAIL: {
    TAB_IDX: 7,
    URL: "/EquipmentDB/AttachmentDetail",
    ARG: "attachmentId"
  },
  REGISTE: {
    TAB_IDX: 8,
    URL: "/EquipmentDB/EquipmentRegiste",
    ARG_MODE: "mode",
    ARG_ID: "id"
  },
} as const;

export const HAND_POWER_SOURCES = [
  {key: "elePosition", label: "電動(任意位置)"},
  {key: "elePower", label: "電動(任意力)"},
  {key: "eleSpeed", label: "電動(任意速度)"},
  {key: "airSolo", label: "空圧(単動)"},
  {key: "airMulti", label: "空圧(複動)"},
] as const;
export type HandPowerSource = typeof HAND_POWER_SOURCES[number]["key"];
export const HAND_FINGER_TYPES = [
  {key: "2", label: "爪２本"},
  {key: "3", label: "爪３本"},
  {key: "k", label: "吸着"},
  {key: "s", label: "その他"},
] as const;
export const HAND_MOUNT_PATTERN = [
  {key: "x", label: "X"},
  {key: "yi", label: "YI"},
  {key: "yo", label: "YO"},
  {key: "z", label: "Z"},
] as const;

export const ARM_REACH_POINT = [
  {key: "wrist", label: "手首中心(手首基準点)"},
  {key: "flange", label: "フランジ面中心"},
] as const;

export const ARM_STRUCTURE = [
  {key: "vertical", label: "垂直多関節型"},
  {key: "horizontal", label: "水平多関節型"},
  {key: "parallellink", label: "パラレルリンク型"},
  {key: "other", label: "その他"},
] as const;

// --------------------------------------------------
export const WORK_URL_PREFIX = "/WorkDB/";
export const WORK_URLS = {
  WORK_LIST: {
    TAB_IDX: 0,
    URL: "/WorkDB/WorkList",
  },
  WORK_DETAIL: {
    TAB_IDX: 1,
    URL: "/WorkDB/WorkDetail",
    ARG_CLASS_ID: "classId",
  },
  WORK_REGISTER_LIST: {
    TAB_IDX: 2,
    URL: "/WorkDB/WorkRegisterList",
  },
  WORK_REGISTER: {
    TAB_IDX: 3,
    URL: "/WorkDB/WorkRegister",
    ARG_CLASS_ID: "classId",
    ARG_INSTANCE_ID: "instanceId",
  },
} as const;
export const WORK_MODEL_TYPES = {
  REGISTRATION: {key: "0", label: "作成済みのモデルを登録"},
  CREATION: {key: "1", label: "画像、動画からモデルを自動生成して登録"}, // "画像、動画からモデルを自動生成して登録"については、バックエンドAPI未実装のためダミーとする
} as const;
export type WorkModelType = typeof WORK_MODEL_TYPES[keyof typeof WORK_MODEL_TYPES]["key"];
export const WORK_INPUT_FILE_TYPES = [
  {key: "obj", label: "オブジェクト"},
  {key: "obj-texture", label: "オブジェクトテクスチャ"},
] as const;
export type WorkInputFileType = typeof WORK_INPUT_FILE_TYPES[number]["key"];
export const WORK_SORT_ORDER = {
  NAME: {value: "name", label: "商品/部品名"},
  CODE: {value: "code", label: "JANコード/部品コード"},
  LATEST: {value: "latest", label: "最新"},
} as const;
export type WorkSortOrderValue = typeof WORK_SORT_ORDER[keyof typeof WORK_SORT_ORDER]['value'];

// --------------------------------------------------
export const SYSTEM_URL_PREFIX = "/SystemDB/";
export const SYSTEM_URLS = {
  SYSTEM_LIST: {
    TAB_IDX: 0,
    URL: "/SystemDB/SystemList"
  },
  SYSTEM_DETAIL: {
    TAB_IDX: 1,
    URL: "/SystemDB/SystemDetail",
    ARG: "systemId"
  },
  REGISTE: {
    TAB_IDX: 2,
    URL: "/SystemDB/SystemRegiste",
    ARG_MODE: "mode",
    ARG_ID: "id"
  },
} as const;

export const SYSTEM_INDUSTRY = [
  {key: "automobiles", label: "自動車"},
  {key: "electronics", label: "電機・電子"},
  {key: "logistics", label: "物流"},
  {key: "construction", label: "建設"},
  {key: "food", label: "食品"},
  {key: "medical", label: "医薬・医療"},
  {key: "machinery", label: "機械・金属加工"},
  {key: "other", label: "その他"},
] as const;
export const SYSTEM_APPLICATION = [
  {key: "handling", label: "ハンドリング・搬送"},
  {key: "picking", label: "ピッキング"},
  {key: "loading", label: "ロード・アンロード"},
  {key: "palletizing", label: "パレタイズ"},
  {key: "spotWelding", label: "スポット溶接"},
  {key: "arcWelding", label: "アーク溶接"},
  {key: "assembly", label: "組立"},
  {key: "processing", label: "加工"},
  {key: "inspection", label: "検査"},
  {key: "polishing", label: "バリ取り・研磨"},
  {key: "sealing", label: "シーリング"},
  {key: "painting", label: "塗装"},
  {key: "other", label: "その他"},
] as const;

// --------------------------------------------------
export const HEAD_FEATURE_NAME = [
  {prefix: "/", label: "利用者UI"},
  {prefix: EQUIPMENT_URL_PREFIX, label: "機器DB"},
  {prefix: WORK_URL_PREFIX, label: "ワークDB"},
  {prefix: SYSTEM_URL_PREFIX, label: "システム事例DB"},
] as const;

// --------------------------------------------------
export const HAND_SELECTION_URL_PREFIX_NAME = "HandSelection";
export const HAND_SELECTION_URL_PREFIX = `/${HAND_SELECTION_URL_PREFIX_NAME}/`;
export const HAND_SELECTION_URLS = {
  BEGINNING_WORK: {
    TAB_IDX: 0,
    URL: `/${HAND_SELECTION_URL_PREFIX_NAME}/Work`,
  },
  BEGINNING_HAND: {
    TAB_IDX: 1,
    URL: `/${HAND_SELECTION_URL_PREFIX_NAME}/Hand`,
  },
  DETAIL: {
    TAB_IDX: 2,
    URL: `/${HAND_SELECTION_URL_PREFIX_NAME}/HandDetail`,
  },
} as const;
