import React, {useState} from "react";
import {Box, Card, Container, InputLabel, TextField, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {handleInputChange} from "../../../../utils/formUtil";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import {WorkSortOrderValue} from "../../../../constants/constants";

export interface SearchCond {
  keyword: string,
  listCount: number,
  sortOrder: WorkSortOrderValue,
}

export interface SearchFormProps {
  isLoading: boolean
  searchCond: SearchCond
  setSearchCond: React.Dispatch<React.SetStateAction<SearchCond>>
  onSubmit: React.FormEventHandler<HTMLFormElement> | undefined
}

export function useSearchFormProps(): SearchFormProps & {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
} {
  const [isLoading, setIsLoading] = useState(false);
  const [searchCond, setSearchCond] = useState<SearchCond>({
    keyword: "",
    listCount: 0,
    sortOrder: "name",
  });

  return {
    isLoading,
    setIsLoading,
    searchCond,
    setSearchCond,
    onSubmit: undefined,
  }
}

export default function SearchForm(props: SearchFormProps) {
  return (
    <Container sx={{mt: 2}} maxWidth={false}>
      <Card sx={{p: 3}}>
        <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>
          検索条件
        </Typography>
        <Box sx={{ml: 2}}>
          <form onSubmit={props.onSubmit}>
            <InputLabel
              variant="standard"
              sx={{mt: 2, color: "text.primary"}}
            >
              検索キーワード
            </InputLabel>

            <Grid container>
              <Grid size={{xs: 5}}>
                <TextField
                  label="商品/部品名、JAN/部品コード、メーカーなどを入力"
                  name="keyword"
                  value={props.searchCond.keyword}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  onChange={(e) => handleInputChange<SearchCond>(e, props.searchCond, props.setSearchCond)}
                />
              </Grid>
            </Grid>
            <Box sx={{mt: 1}}>
              <Pjdb1Button
                label="検索"
                variant="contained"
                color="primary"
                isLoading={props.isLoading}
                type='submit'
              />
            </Box>
          </form>
        </Box>
      </Card>
    </Container>
  )
}
