import {WorkClassDocument, WorkInstanceDocument} from "../types/pj1dbApiWork";
import {WORK_ATTRS} from "../constants/work";
import {Dict} from "../types/pj1dbApi";

export function extractDomain(email: string): string | null {
  const arr = email.split('@');
  return arr.length < 2 ? null : arr[1];
}

export function extractDomainFromWorkClass(work: WorkClassDocument): string | null {
  return extractDomain(work.attr.creator)
}

export interface PickSameDomainParams {
  workClasses: WorkClassDocument[],
  domain: string
}

export function pickSameDomain({workClasses, domain}: PickSameDomainParams): WorkClassDocument[] {
  return workClasses.filter((work) => extractDomainFromWorkClass(work) === domain);
}

export function excludeClassesWithChildren(classes: WorkClassDocument[]): WorkClassDocument[] {
  return classes.filter((work) => work.attr[WORK_ATTRS.children.key]?.length === 0);
}

export const DEFAULT_IMAGE_BLACK_URL = '/images/workdb/no_img_black.png';

export const DEFAULT_IMAGE_WHITE_URL = '/images/workdb/no_img_white.png';

export interface PickImageUrlWorkable {
  url?: Dict<string[]>;
}

export default function pickImageUrl(work: PickImageUrlWorkable, options: {
  defaultUrl?: string;
  sortOrder?: 'latest' | 'oldest';
} = {}): string {
  return Object
      .entries(work.url ?? {})
      .sort(([aKey], [bKey]) => {
        // IDが時系列順になっていることを前提としている
        if (options.sortOrder === 'oldest') {
          return aKey.localeCompare(bKey);
        }

        return bKey.localeCompare(aKey);
      })
      .map(([_, value]) => {
        // 古い順に配列が並んでいることを前提としている
        if (options.sortOrder === 'oldest') {
          return value[0];
        }

        return value[value.length - 1];
      })
      ?.find(() => true)
    ?? options.defaultUrl
    ?? DEFAULT_IMAGE_BLACK_URL
}
