/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-void */

import { useState, useContext, useEffect } from 'react';
import {
  Typography,
  Container,
  Box,
  Card,
  CardContent,
  CardMedia,
  InputLabel,
  FormControl,
  Select,
  SelectChangeEvent,
  TextField,
  MenuItem,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Link } from "react-router-dom"

import useNotification from "../../../utils/notificationUtil";
import { findSystem } from '../../../utils/awsAmplifyUtil'
import { SystemDocument } from '../types/pj1dbApiSystem'
import { FormSystemSearchCond } from '../types/systemForm'
import { DataStoreContext, CONTEXT_ACTION_TYPE } from "../contexts/SystemContext";
import { SYSTEM_URLS, SYSTEM_INDUSTRY, SYSTEM_APPLICATION } from '../../../constants/constants'
import {handleInputChange, handleInputChangeSelect, handleInputChangeNameVal} from "../../../utils/formUtil"

import Pjdb1SelectCheck from '../../../components/inputs/Pjdb1SelectCheck';
import Pjdb1Button from '../../../components/inputs/Pjdb1Button';

export default function SystemList() {
  const { infoMsg , errorMsg} = useNotification();
  const { state, dispatch } = useContext(DataStoreContext);

  const [isLoading, setIsLoading] = useState(false);

  const [ searchCond, setSearchCond] = useState<FormSystemSearchCond>({
    keyword:"",
    industry: [],
    application: [],
    listCount:0,
    sortOrder:"title",
  });

  const [systemList, setSystemList] = useState<SystemDocument[]>([]);
  const [notFoundMsg, setNotFoundMsg] = useState("");

  const sortList = (systemListData:SystemDocument[],sortOrder:string):SystemDocument[] => {
    let list = systemListData;
    if(sortOrder==="title"){
      list = list.sort((a,b) => a.attr.basicInfo.title.localeCompare(b.attr.basicInfo.title));
    }else if(sortOrder==="sier"){
      list = list.sort((a,b) => a.attr.basicInfo.sier.localeCompare(b.attr.basicInfo.sier));
    }else if(sortOrder==="latest"){
      list = list.sort((a,b) => b.attr.registrationDate - a.attr.registrationDate );
    }
    return list;
  }

  const findSystemProc = async () => {
    setIsLoading(true);
    try {
      let cond = {};
      if (searchCond.keyword !== "" || searchCond.industry.length > 0 || searchCond.application.length > 0) {
        cond = {
          "query":{
            ...(searchCond.keyword ? {
              "$or": [
                {"basicInfo.title":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"basicInfo.overview":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"basicInfo.industry":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"basicInfo.application":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"basicInfo.sier":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"works.workInfos.nameJp":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"parts.armInfos.description":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"parts.armInfos.armInfo.name":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"parts.armInfos.armInfo.manufacturer":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"parts.armInfos.armInfo.structure":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"parts.armInfos.armInfo.cobot":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"parts.handInfos.description":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"parts.handInfos.handInfo.name":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"parts.handInfos.handInfo.producer":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"description.background":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"description.result":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"description.problem":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"description.solution":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"description.feature":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"description.carryIn":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"description.carryOut":{ "$regex" : searchCond.keyword, "$options":"i" }},
                {"description.workDetection":{ "$regex" : searchCond.keyword, "$options":"i" }},
              ],
            } : {}),
            ...(searchCond.industry.length > 0 ? {"basicInfo.industry": {"$in" : searchCond.industry}} : {}),
            ...(searchCond.application.length > 0 ? {"basicInfo.application": {"$in" : searchCond.application}} : {}),
          }
        };
      }
      const result = await findSystem(cond);
      if(!result.isSuccess){
        errorMsg("検索処理が失敗しました。");
        return;
      }
      // console.log(result);
      if(result.data && Object.entries(result.data).length > 0){
        const systemListData:SystemDocument[] = Object.entries(result.data).map(([,systemDoc])=>(systemDoc));
        setSystemList(sortList(systemListData,searchCond.sortOrder));
        setSearchCond({...searchCond,listCount:systemListData.length});
        dispatch({ type: CONTEXT_ACTION_TYPE.SYSTEM_LIST, payload: {
          searchCond:{...searchCond,listCount:systemListData.length},
          systemList:systemListData,
        }});
      }else{
        setSystemList([]);
        setNotFoundMsg("該当データがありません。");
      }
      infoMsg("検索しました。");
    } finally {
      setIsLoading(false);
    }
  }

  const changeSortOrder = (event:SelectChangeEvent<string>) => {
    handleInputChangeSelect<FormSystemSearchCond>(event, "sortOrder",searchCond, setSearchCond);
    setSystemList(sortList(systemList,event.target.value));
    dispatch({ type: CONTEXT_ACTION_TYPE.SYSTEM_LIST, payload: {
      searchCond:{...searchCond,sortOrder:event.target.value},
      systemList,
    }});
  }

  // 検索済みデータの復元
  useEffect(()=>{
    if(state.systemListPage.systemList.length>0){
      // console.log("SystemList","restore from context.",state);
      setSearchCond(state.systemListPage.searchCond);
      setSystemList(state.systemListPage.systemList);
    }
    setNotFoundMsg("検索条件を入力し[検索]ボタンを押下してください。");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
      <Container sx={{mt:2}} maxWidth={false}>
        <Card sx={{p:3}}>
          <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>検索条件</Typography>
          <Box sx={{ml:2}}>
            <form onSubmit={(e)=>{
              e.preventDefault();
              void findSystemProc();
            }}>
              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>検索キーワード</InputLabel>
              <Grid container>
                <Grid size={{xs:5}}>
                  <TextField
                    label="事例特徴、製品名、機器名などを入力"
                    name="keyword" value={searchCond.keyword}
                    variant="outlined" size="small"
                    margin="dense" fullWidth
                    onChange={(e)=>(handleInputChange<FormSystemSearchCond>(e, searchCond, setSearchCond))}
                  />
                </Grid>
              </Grid>

              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>産業分野</InputLabel>
              <Pjdb1SelectCheck
                name="industry" value={searchCond.industry}
                onChange={(name,value)=>(handleInputChangeNameVal<FormSystemSearchCond>(name, value, searchCond, setSearchCond))}
                checks={SYSTEM_INDUSTRY.map((row)=>({chkKey:row.key,chkLbl:row.label}))}
                sxOpt={{py:0,pr:0.5}}
              />

              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>用途</InputLabel>
              <Pjdb1SelectCheck
                name="application" value={searchCond.application}
                onChange={(name,value)=>(handleInputChangeNameVal<FormSystemSearchCond>(name, value, searchCond, setSearchCond))}
                checks={SYSTEM_APPLICATION.map((row)=>({chkKey:row.key,chkLbl:row.label}))}
                sxOpt={{py:0,pr:0.5}}
              />

              <Box sx={{mt:1}}>
                <Pjdb1Button
                  label="検索"
                  variant="contained"
                  color="primary"
                  isLoading={isLoading}
                  onClick={()=>{void findSystemProc();}}
                />
              </Box>
            </form>
          </Box>
        </Card>
      </Container>

      <Container sx={{p:0, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} maxWidth={false}>
        <Grid container alignItems="end" sx={{mt:2,flexWrap:"nowrap"}} size={{xs:12}}>
          <Grid sx={{flexGrow:1}}>
            <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>検索結果一覧</Typography>
          </Grid>
          <Grid>
            <Grid container alignItems="center" spacing={2} sx={{flexWrap:"nowrap"}}>
              <Grid>
                <Box component="span">
                  {searchCond.listCount > 0 ? `${searchCond.listCount.toLocaleString()}件` : ""}
                </Box>
              </Grid>
              <Grid>
                <Box component="span">
                  <FormControl sx={{minWidth:120}} size="small">
                    <InputLabel id="sort-order-label">並び順</InputLabel>
                    <Select
                      labelId="sort-order-label"
                      name="sortOrder"
                      value={searchCond.sortOrder}
                      label="並び順"
                      onChange={changeSortOrder}
                      sx={{bgcolor:"white"}}
                    >
                      <MenuItem value="title">事例名</MenuItem>
                      <MenuItem value="sier">製作者</MenuItem>
                      <MenuItem value="latest">最新</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="start" spacing={2} sx={{mt:0,pt:2}} size={{xs:12}}>
        {systemList.map(row =>(
          <Grid key={row.attr.systemId}>
            <Link to={`${SYSTEM_URLS.SYSTEM_DETAIL.URL}/${row.attr.systemId}`} style={{ textDecoration:'none' }}
            onClick={()=>{
              dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: SYSTEM_URLS.SYSTEM_DETAIL.TAB_IDX });
              dispatch({ type: CONTEXT_ACTION_TYPE.SYSTEM_DETAIL , payload: {info:row} });
            }}>
              <Card sx={{ width:240, height:200 }}>
                <CardMedia
                  component="img"
                  height="145"
                  image={row.url?.image[0]}
                  alt={row.attr.basicInfo.title}
                />
                <CardContent sx={{py:0.5,px:1,m:0}}>
                  <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                    事例名：{row.attr.basicInfo.title}
                  </Typography>
                  <Typography variant="subtitle2" noWrap component='p' sx={{color:"#888"}}>
                    製作者：{row.attr.basicInfo.sier}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
        { (systemList.length===0) &&
          <Box sx={{my:3,width:"100%",textAlign:"center"}}>
            <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>{notFoundMsg}</Typography>
          </Box>
        }
        </Grid>

      </Container>
    </>
  )
}