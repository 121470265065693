import {useContext, useEffect, useState} from "react";
import Grid from '@mui/material/Grid2';
import {Box, Typography} from "@mui/material";
import {handleInputChangeSelect} from "../../../../utils/formUtil";
import useNotification from "../../../../utils/notificationUtil";
import {WorkSortOrderValue} from "../../../../constants/constants";
import {SearchCond, useSearchFormProps} from "../forms/SearchForm";
import {SortOrderProps, useSortOrder} from "../forms/SortOrder";
import {findWork} from "../../../../utils/awsAmplifyUtil";
import {
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  SearchWorkClassPayload,
  STEPS,
  WorkClassSelectionStepPayload
} from "../../contexts/HandSelectionContext";
import WorkClassListItem from "../lists/WorkClassListItem";
import {WorkClassDocument} from "../../../../types/pj1dbApiWork";
import {WORK_ATTRS} from "../../../../constants/work";
import {excludeClassesWithChildren} from "../../../../utils/workUtil";

export interface WorkClassSelectionStepProps {
  notFoundMsg: string;
  workClasses: WorkClassDocument[];
  onSelectClass: (work: WorkClassDocument) => void;
}

export default function WorkClassSelectionStep(props: WorkClassSelectionStepProps) {
  if (props.workClasses.length <= 0 && props.notFoundMsg !== "") {
    return (
      <Box sx={{my:3,width:"100%",textAlign:"center"}}>
        <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>{props.notFoundMsg}</Typography>
      </Box>
    );
  }

  return (
    <Grid container alignItems="stretch" spacing={2} sx={{mt: 2}}>
      {props.workClasses.map(workClass => (
        <Grid
          key={workClass.attr[WORK_ATTRS.classId.key]}
          onClick={() => props.onSelectClass(workClass)}
        >
          <WorkClassListItem work={workClass}/>
        </Grid>
      ))}
    </Grid>
  );
}

export function useWorkClassSelectionStepHelpers() {
  const {errorMsg} = useNotification();
  const {state, dispatch} = useContext(DataStoreContext);
  const [workClassList, setWorkClassList] = useState<WorkClassDocument[]>([]);
  const [notFoundMsg, setNotFoundMsg] = useState("");
  const {isLoading, setIsLoading, searchCond, setSearchCond} = useSearchFormProps();
  const {sortWorkList} = useSortOrder();


  const findWorkClasses = async () => {
    setIsLoading(true);
    try {
      const result = await findWork('class', {
        version: "work-user",
        queryAttr: {
          ...(searchCond.keyword === "" ? {} : {
            $or: [
              {[WORK_ATTRS.nameJp.key]: {"$regex": searchCond.keyword, "$options": "i"}},
              {[WORK_ATTRS.nameEn.key]: {"$regex": searchCond.keyword, "$options": "i"}},
              {[WORK_ATTRS.code.key]: {"$regex": searchCond.keyword, "$options": "i"}},
            ]
          }),
        },
      });

      if (!result.isSuccess) {
        errorMsg("検索処理が失敗しました。");
        return;
      }

      const data = sortWorkList(
        excludeClassesWithChildren(result.data ? Object.values(result.data.items) : []),
        searchCond.sortOrder
      );
      if (data.length === 0) {
        setNotFoundMsg("該当データがありません。");
      }
      setWorkClassList(data);
      setSearchCond({...searchCond, listCount: data.length});
      dispatch({
        type: CONTEXT_ACTION_TYPE.SEARCH_WORK_CLASS, payload: {
          searchCond: {...searchCond, listCount: data.length},
          workClassList: data,
        } satisfies SearchWorkClassPayload
      });
    } finally {
      setIsLoading(false);
    }
  };

  const changeSortOrder: SortOrderProps['onChange'] = (event) => {
    handleInputChangeSelect<SearchCond>(event, "sortOrder", searchCond, setSearchCond);
    setWorkClassList(sortWorkList(workClassList, event.target.value as WorkSortOrderValue));
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_CLASS_SELECTION_STEP, payload: {
        ...state.workClassSelectionStep,
        searchCond: {...searchCond, sortOrder: event.target.value as WorkSortOrderValue},
        workClassList,
      } satisfies WorkClassSelectionStepPayload
    });
  }

  const selectClass = (work: WorkClassDocument) => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_CLASS_SELECTION_STEP, payload: {
        ...state.workClassSelectionStep,
        selectedWorkClass: work,
        step: STEPS.WORK_INSTANCE_SELECTION,
      } satisfies WorkClassSelectionStepPayload
    });
  }

  // 検索済みデータの復元
  useEffect(() => {
    setSearchCond(state.workClassSelectionStep.searchCond);
    setWorkClassList(state.workClassSelectionStep.workClassList);
    setNotFoundMsg("検索キーワードを入力し、検索ボタンを押してください。");
    if (state.workClassSelectionStep.searchCond.keyword !== "") {
      setNotFoundMsg("該当データがありません。");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    workClassList,
    notFoundMsg,
    isLoading,
    searchCond,
    setSearchCond,
    findWorkClasses,
    changeSortOrder,
    selectClass,
  }
}
