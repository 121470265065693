import {HandDocument} from "../features/equipmentdb/types/pj1dbApiHand";
import {FingertipDocument} from "../features/equipmentdb/types/pj1dbApiFingertip";

// eslint-disable-next-line import/prefer-default-export
export function pickCompositeImage({images, fingertipId}: {
  images: Required<HandDocument>['url']['image'];
  fingertipId: string
}) {
  return images.find((imageUrl) => {
    const tempUrl: string[] = imageUrl.split("/");
    if (tempUrl.length > 7) {
      if (tempUrl[6] === "image" && tempUrl[7] === fingertipId) {
        return true;
      }
    }
    return false;
  });
}

export interface ImageListRowType {
  key: string;
  type: string;
  url: string;
}

export function convertToFingertipImage(fingertip: FingertipDocument): ImageListRowType {
  let sType = "";
  if (fingertip.attr.fingertipInfo.setFingertip) {
    sType = "maker";
  } else if (fingertip.attr.public) {
    sType = "public";
  } else {
    sType = "private";
  }
  return {
    key: fingertip.attr.fingertipId,
    type: sType,
    url: String(fingertip.url?.image[0])
  }
}
