import React from "react";
import WithLabel from "./WithLabel";
import Pjdb1SelectCheck from "../../../../components/inputs/Pjdb1SelectCheck";
import {handleInputChangeNameVal} from "../../../../utils/formUtil";
import {HAND_POWER_SOURCES, HandPowerSource} from "../../../../constants/constants";

export interface HandPowerSourceFieldProps {
  isClear: boolean;
  inputs: HandPowerSourceFieldInputs;
  // eslint-disable-next-line react/require-default-props
  setInputs?: React.Dispatch<React.SetStateAction<HandPowerSourceFieldInputs>>;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  disabledTooltip?: boolean;
}

export interface HandPowerSourceFieldInputs {
  handPowerSource: HandPowerSource[]
}

export default function HandPowerSourceField(props: HandPowerSourceFieldProps) {
  return (
    <WithLabel label='ハンド動作原理'>
      <Pjdb1SelectCheck
        name='handPowerSource'
        value={props.inputs.handPowerSource}
        checks={HAND_POWER_SOURCES.map((row) => ({chkKey: row.key, chkLbl: row.label}))}
        onChange={
          props.setInputs
            ? (name, value) => (handleInputChangeNameVal<HandPowerSourceFieldInputs>(name, value, props.inputs, props.setInputs!))
            : undefined
        }
        toolTip={props.disabledTooltip ? undefined : '複数選択可'}
        isClear={props.isClear}
        disabled={props.disabled ?? false}
      />
    </WithLabel>
  );
}
