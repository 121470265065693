import React, {useCallback, useEffect, useState} from "react";
import * as THREE from "three";
import {Flex} from "@aws-amplify/ui-react";
import {CameraType, LookAtType, MaterialType, OptionsView3D, ThreeControl} from "../../utils/view3dUtil";
import AxisIconBox from "../AxisIconBox";

export interface View3DProps {
  three: ThreeState;
  url: string | undefined;
  // eslint-disable-next-line react/require-default-props
  showPerspectiveControl?: boolean;
  // eslint-disable-next-line react/require-default-props
  showLookAtControl?: boolean;
  // eslint-disable-next-line react/require-default-props
  showGridSizeControl?: boolean;
  // eslint-disable-next-line react/require-default-props
  showCameraInfoView?: boolean;
  // eslint-disable-next-line react/require-default-props
  showObjectInfoView?: boolean;
  // eslint-disable-next-line react/require-default-props
  options?: OptionsView3D;
  // eslint-disable-next-line react/require-default-props
  padding?: number;
  // eslint-disable-next-line react/require-default-props
  onThreeModelLoaded?: (three: ThreeControl) => void;
}

export default function View3D(props: View3DProps) {
  console.log("View3D render", props.url);
  const [cameraPos, setCameraPos] = useState<number[]>([0, 0, 0]);
  const [cameraZoom, setCameraZoom] = useState<number | undefined>();
  const [hoverObjectList, setHoverObjectList] = useState<THREE.Object3D[]>([]);
  const [selectedObject, setSelectedObject] = useState<
    THREE.Object3D | undefined
  >(undefined);

  const onObjectHover = useCallback((objectList: THREE.Object3D[]) => {
    if (objectList.length !== hoverObjectList.length) {
      console.log(
        `View3D hover object changed ${hoverObjectList.length} -> ${objectList.length}`,
      );
      setHoverObjectList(objectList);
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const obj of objectList) {
        if (!hoverObjectList.includes(obj)) {
          console.log(`View3D hover object changed ${obj.name}`);
          setHoverObjectList(objectList);
          break;
        }
      }
    }
  }, [hoverObjectList]);

  useEffect(() => {
    const control = new ThreeControl(VIEW_SIZE, "preview", props.options, {
      onOrbitChange: (camPos: number[], zoom: number | undefined) => {
        console.log("View3D onOrbitChange");
        setCameraPos(camPos);
        setCameraZoom(zoom);
      },
      onObjectSelected: (object: THREE.Object3D | undefined) => {
        console.log("View3D onObjectSelected");
        setSelectedObject(object);
      },
      onObjectHover: (objectList: THREE.Object3D[]) => {
        onObjectHover(objectList);
      },
    });
    props.three.setControl(control);
    return control.getUnmountFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 最新のhoverObjectListがonObjectHoverで使われるようにハンドラを更新します（＝クロージャーが最新の値を参照する）
    const cont = props.three.control;
    if (cont) cont.callbacks.onObjectHover = onObjectHover;
  }, [hoverObjectList, onObjectHover, props.three.control]);

  useEffect(() => {
    if (props.url && props.three.control) {
      console.log("View3D call loadModel");
      props.three.control.unloadModels();
      props.three.control.loadModel(props.url, () => {
        const func = props.onThreeModelLoaded;
        if (func) func(props.three.control!);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.three.control, props.url]);

  return (
    <Flex direction="column" placeholder={undefined} onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}>
      <div
        id="preview"
        style={{
          position: "relative",
          width: VIEW_SIZE,
          height: VIEW_SIZE,
          backgroundColor: "gray",
        }}
      >
        {props.three.control ? (
          <div>
            <AxisIconBox
              onAxisSelected={(viewpointType) => {
                props.three.control?.setCameraPosition(viewpointType);
              }}
            />
            <span
              style={{
                color: "white",
                position: "absolute",
                left: "5px",
                bottom: "5px",
                overflowWrap: "anywhere",
                lineHeight: 1,
              }}
            >
              {
                // eslint-disable-next-line no-nested-ternary
                !selectedObject
                  ? ""
                  : selectedObject.name
                    ? selectedObject.name
                    : "(no name)"
              }
            </span>
          </div>
        ) : undefined}
      </div>
      <Flex direction="row" overflow="auto" style={{width: VIEW_SIZE}} placeholder={undefined}
            onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        {!props.showCameraInfoView ? undefined : (
          <Flex direction="column" padding={props.padding} placeholder={undefined} onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}>
            <div>CameraPos</div>
            <div>x={Number.parseFloat(cameraPos[0].toPrecision(2))}</div>
            <div>y={Number.parseFloat(cameraPos[1].toPrecision(2))}</div>
            <div>z={Number.parseFloat(cameraPos[2].toPrecision(2))}</div>
            <div>CameraZoom</div>
            <div>
              {cameraZoom ? Number.parseFloat(cameraZoom.toPrecision(2)) : "-"}
            </div>
          </Flex>
        )}
        {!props.showObjectInfoView ? undefined : (
          <Flex direction="column" padding={props.padding} placeholder={undefined} onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}>
            <ul style={{listStyleType: "none", paddingLeft: 0}}>
              <li>Selected</li>
              <li>
                <ul>
                  <li>
                    {selectedObject
                      ? `${selectedObject.constructor.name}: name="${selectedObject.name}" id=${selectedObject.id}`
                      : "(not selected)"}
                  </li>
                </ul>
              </li>
              <li>Hover</li>
              <li>
                <ul>
                  {hoverObjectList.map((obj) => (
                    <li
                      key={obj.id}
                    >{`${obj.constructor.name}: name="${obj.name}" id=${obj.id}`}</li>
                  ))}
                </ul>
              </li>
            </ul>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

const VIEW_SIZE = 512;

export type ThreeState = {
  control: ThreeControl | undefined;
  setControl: React.Dispatch<React.SetStateAction<ThreeControl | undefined>>;
};

export const useThree = () => {
  const [control, setControl] = useState<ThreeControl | undefined>();
  return {
    control,
    setControl,
  };
};

export interface ControlState {
  materialType: MaterialType;
  perspective: CameraType;
  lookAtType: LookAtType;
  gridSize: string;
}

export const initialControlState: ControlState = {
  materialType: "original",
  perspective: 'perspective',
  lookAtType: "modelCenter",
  gridSize: '0.01',
};

export function useControlState() {
  const [controlState, setControlState] = useState<ControlState>(initialControlState);

  return {
    controlState,
    setControlState,
    clearControlState: () => {
      setControlState(initialControlState);
    }
  };
}
